
.floatButton.text-center.show{
    display: none !important;
}

.logo_cars {
    padding: 10px;
    background-color: #fff;
    height: 70.34px !important;
}

.logo_in_cars {
    height: 60px;
    width: 174px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    margin: auto;
}

.owl-carousel .owl-item img {
    width: auto !important;
    height: auto !important;
}

.owl-nav {
    display: none !important;
}
 

/*********** New footer start *************/

.footer li,
.footer ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.footer {
    background-image: url("../../assets/images/footer-bg-n.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 45px 0 0;
    color: #fff;
}

.ft_logo {
    margin-bottom: 20px;
    background: #fff;
    padding: 15px 20px;
    border-radius: 10px;
    display: inline-block;
}

.ft_fst {
    margin-bottom: 25px;
}

.ft_secnd {
    margin-bottom: 20px;
}

.ft_fst p,
.fts_left p,
.fts_right p {
    font-size: 15px;
}

.fts_left a {
    font-size: 18px;
    color: #fff;
}

.fts_right {
    padding: 0 20px;
    border-left: 1px solid #818181;
}
.fts_right form {
    position: relative;
    width: 90%;
    margin: 0 auto;
}
.fts_right form input[type=email] {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #6c6c6c;
    background-color: rgb(255 255 255 / 6%);
    font-size: 14px;
    color: #fff;
    height: 56px !important;
}
.fts_right form button[type=submit] {
    position: absolute;
    right: 7px;
    padding: 10px 25px;
    transition: all 0.5s;
    top: 50%;
    transform: translateY(-50%);
}
.fts_right form input[type=email]::placeholder {
    color: #fff;
}
.ft_logo_sec {
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
}

.ft_logo_sec_cntn {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.ft_logo_sec .ft_logo_inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8.333%;
}

.ft_btm p {
    color: #DAD6D6;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0;
}

.ftb_right li {
    padding: 0 10px;
    display: inline-block;
}

.ftb_right li a {
    color: #cac4c4;
}
.ftb_right li a:hover {
    color: #00e0b9;
}
.logo_cars {
    padding: 10px;
    background-color: #fff;
}
.footer .img-fluid {
    width: 120px !important;
}
#emailhover:hover {
    color: #00e1ba;
    transition: 0.3s all;
}

@media only screen and (max-width: 991px) {
    .fts_right {
        border-left: 0px;
    }

    .fts_left {
        margin-bottom: 20px;
    }

    .ft_btm {
        text-align: center;
    }

    .ftb_right {
        text-align: center !important;
        margin-top: 8px;
    }

    .ft_logo_sec .ft_logo_inner {
        width: 20%;
        padding: 10px 5px;
    }
}

@media only screen and (max-width: 575px) {
    .ft_logo_sec .ft_logo_inner {
        width: 100%;
    }

    .fts_right form button[type=submit] {
        position: relative;
        margin-top: 10px;
        width: 100%;
    }

    .fts_right form input[type=email] {
        height: 45px;
    }
}

.fnav {
    width: 100%;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: nowrap;
    align-items: center;
}

.fnav h3 {
    color: #fff;
    padding: 0 45px;
}

.title-sep-container {
    position: relative;
    height: 6px;
    flex-grow: 1;
}


.title-sep-container .title-sep {
    border-color: #00bcd4;
    border-bottom-width: 1px;

    position: relative;
    display: block;
    width: 100%;
    border-style: solid;
    box-sizing: content-box;
}

/*********** New footer end *************/