@media (max-width:1200px) {

    

    ul.action li::after {
        left: 0;
        width: 100%;
    }

    .bannerText {
        width: auto;
    }

    .bannerText h1 {
        font-size: 78px;
        font-weight: 700;
        line-height: 70px;
        letter-spacing: -5px;
    }

    .bannerText a.defaultBtn {
        font-size: 18px;
        margin-top: 35px;
    }
    .searchFilter {
        background: #fff;
        box-shadow: 0px 0px 6px #ccc;
        padding: 15px 15px;
        border-radius: 15px;
        margin-right: 0;
    }



    /********Dip start*******/
    .aboutVideo div {max-width: 100% !important; margin: 0 auto; }
    
    section.footerBottom{
        padding:25px 0px 65px
    }

    .owl-prev {left: -18px}
    .owl-next {right: -18px}
    /********Dip end*******/






}

@media (max-width:1023px) {
    .blogContent {
        margin-bottom: 30px;
    }

    .bannerText h1 {
        font-size: 60px;
        font-weight: 700;
        line-height: 53px;
        letter-spacing: -1px;
    }

    .bannerText {
        margin-top: 20px;
        margin-left: 0;
        float: none;
    }

    .bannerText a.defaultBtn {
        font-size: 15px;
        margin-top: 25px;
    }

    .registrationContent {
        margin: 0px 16px;
    }

    .otherRegistration {
        background-position: center;
    }


    /********Dip start*******/
    .coachInfoWrap ul.coach_public_tabs li a {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        padding: 10px 5px;
    }
    .coachsProfileWrap .defaultBtn {max-width: 218px; font-size: 14px; text-align: center; }  
    .bookingCocachDetails.subscription_row ul li {flex: 0 0 33.33%}  
    /********Dip end*******/

}


@media (max-width:991px) {

    /********Dip started*******/
    .coachingWrap .CoachingItem {
        margin-bottom: 0;
        width: 100%;
    }

    .flip-container:hover .flipper .back,
    .flip-container.hover .flipper .back {
        z-index: 3;
        border-radius: 25px;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
    }
    .coachsProfileWrap .defaultBtn {max-width: 218px; font-size: 14px; padding: 10px 15px}  
    .coachInfoWrap ul.coach_public_tabs li {flex:0 0 36%}  
    .bookingCocachDetails.subscription_row ul li {flex: 0 0 57%}
    
    /********Dip end*******/
    .nav_wrap .navbar-nav .nav-link {
        font-size: .9rem;
    }
    .nav_wrap .navbar-nav .nav-item + .nav-item {
        margin-left: 0.6rem;
    }

}

@media (max-width:980px) {
    .searchWrap button.defaultBtn {
        width: 100%;
        font-size: 13px;
        height: 49px;
        padding: 10px 20px;
    }

    .testimonialWrap .aboutText p {
        font-size: 15px;
        line-height: 22px;
    }

    section.testimonialWrap {
        padding-bottom: 15px;
    }

    .chooseWrap .col {
        width: 50%;
        flex: 48% 0;
        margin-bottom: 7px;
    }


    /********Dip start*******/    
    .userBookingList.trans_his ul li {flex:0 0 30% !important; padding: 0 5px !important; margin-bottom: 15px; }    
    .bookingCocachDetails.booking_list ul li {flex:0 0 30% !important; padding: 0 5px !important; margin-bottom: 15px; }    
    .bookingCocachDetails.booking_list ul li.userCoachMore, .bookingCocachDetails.booking_list ul li:nth-last-child(1) {flex: 0 0 100% !important}
    .userBookingList.trans_his ul li.user-transaction-refNo {
        order: -1;
        flex: 0 0 100% !important;
    }
    .payment_details_tab ul li.user-transaction-refNo {
        order: -1;
        flex: 0 0 100% !important;
    }
    /********Dip end*******/
    
}

@media (max-width:767px) {
    .bannerWrap {
        padding: 30px 0px;
    }
    .navbar-toggler.navbar-toggler-right {
        box-shadow: none;
        margin-left: auto;
        display: block;
        background-color: #edf1f3;
        border: 0;
        width: 40px;
        height: 40px;
        padding: 0;
        border-radius: 0.6rem;
    }
    .nav_wrap .navbar-brand {
        padding: 0;
    }
    .headerWrap {
        height: auto;
    }
    .navbar.navbar-light {
        padding: 0.5rem 0;
    }
    .nav_wrap .navbar-brand img {
        max-width: 100%;
        height: auto;
        width: 90px;
    }

    li.nav-item a {
        font-size: 14px;
        padding: 13px 10px !important;
        text-align: center;
    }

    .navbar-expand-md .navbar-nav .nav-item.active::before,
    .navbar-expand-md .navbar-nav .nav-item:hover::before {
        display: none;
    }

    .closeMenu {
        display: block !important;
        clear: both;
        text-align: right;
        position: absolute;
        right: 0px;
        top: -5px;
        z-index: 9;
        padding: 10px;
    }

    .closeMenu svg {
        width: 15px;
    }
    #navbarResponsive {
        position: absolute;
        right: 0;
        top: 0;
        background: #ffffff;
        width: 246px;
        z-index: 9;
        padding: 10px;
        border-radius: 5px;
        box-shadow: 0px 0px 20px rgb(112 112 112 / 14%);
    }
    .nav_wrap .navbar-nav .nav-link {
        font-size: .9rem;
        line-height: 1;
        text-align: left;
    }
    .nav_wrap .navbar-nav .nav-item + .nav-item {
        margin-left: 0;
    }
    .nav_wrap .navbar-nav .nav-item .nav-link::before {
        display: none;
    }
    li.nav-item.myAccount a,
    li.nav-item.buyNow a {
        padding: 9px 15px !important;
        width: 150px;
        margin: 0 auto;
        margin-bottom: 5px;
        font-size: 16px;
        font-family: 'Open Sans', sans-serif;
    }

    ul.action li::after {
        left: 0;
        width: 100%;
    }

    .newsLetter {
        margin: 25px 0px;
    }

    .footerTop .row .col-md-4:nth-child(1) {
        order: 1;
    }
    .animate {
        animation-duration: 0.3s;
        -webkit-animation-duration: 0.3s;
        animation-fill-mode: both;
        -webkit-animation-fill-mode: both;
    }

    .bannerText {
        text-align: center;
    }

    .bannerText a.defaultBtn {
        font-size: 15px;
        margin-top: 7px;
        margin-bottom: 15px;
    }

    .searchWrap button.defaultBtn {
        width: 100% !important;
        font-size: 16px;
        height: 49px;
        padding: 10px 20px;
        margin: 15px 0px;
    }

    section.aboutWrap img {
        width: 100%;
        margin-bottom: 15px;
    }

    .webinnerContent .heading p {
        padding: 0;
    }

    .webinnerContent {
        padding: 40px 25px;
    }

    .new,
    .old,
    .current {
        font-size: 60px;
    }

    #js-days:after,
    #js-hours:after,
    #js-minutes:after,
    #js-seconds:after {
        bottom: -40px;
        font-size: 14px;
    }

    .number {
        margin-bottom: 50px;
    }

    .podcastsSearch {
        width: 310px;
        float: none;
        margin: 0 auto 30px;
        display: block;
    }

    .heading p br {
        display: none;
    }

    section.testimonialWrap .col-md-5 img {
        position: relative;
        margin-top: 0;
        margin-bottom: -13px;
    }

    header.nav_wrap.sticky {
        position: unset;
    }

    section.searchWrap {
        background: #0d0d0d;
        margin: 50px 0px;
        padding: 35px 0px 0;
    }

    .searchFilter {
        margin-bottom: 15px;
    }

    .benefitsContent img.img-fluid {
        position: unset;
        transform: none;
        box-shadow: 0px 0px 8px #000000d9;
    }

    .benefitsContent {
        background: #fff;
        color: #262626;
        padding: 21px 17px;
        border-radius: 15px;
        margin-bottom: 40px;
        min-height: 340px;
    }

    .benefitsContent h3 {
        font-size: 25px;
        margin-top: 15px;
    }

    section.contactWrap iframe {
        width: 100%;
        margin-bottom: -6px;
    }

    section.contactWrap {
        margin: 50px 0px;
        padding: 0px 15px;
    }

    .logreg_or {
        left: 50%;
        right: auto;
        top: 100%;
        transform: translate(-50%, -50%);
    }

    .form-recovery {
        text-align: center;
        float: none !important;
        margin: 0px auto 15px;
        display: inline-block
    }

    /********Dip started*******/
    .bannerWrap .aboutVideo iframe {
        max-height: 365px;
    }

    .bannerWrap .aboutVideo div {
        max-width: 100%;
    }

    .aboutVideo {
        margin-bottom: 30px;
    }

    .coachingWrap {
        padding: 30px 0px 30px
    }

    .coachingWrap .CoachingItem {
        margin-bottom: 0;
    }

    .testimonialWrap .owl-theme .owl-nav {
        margin: 17px 0 5px;
        min-height: 50px
    }

    .testimonialWrap .owl-prev {
        top: auto;
        left: 0
    }

    .testimonialWrap .owl-next {
        top: auto;
        right: 0
    }

    .blogContent {
        height: auto
    }

    .blogWrap .blogContent img.img-fluid {
        width: 100% !important;
        object-fit: cover !important
    }

    .ant-drawer-content-wrapper {
        height: 250px !important;
    }

    .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-body {
        font-size: 14px;
    }

    .form-group.text-right.mt-0 {
        text-align: center !important
    }

    section.questionsWrap {
        padding: 75px 15px;
    }

    .yearly_monthly {
        align-items: center;
        font-size: 25px;
    }
    .pricing_container .priceHolder.p-5 {
        padding-left: 0 !important;
        padding-right: 0 !important
    }
    .coachsProfileWrap .defaultBtn {max-width: 218px; font-size: 14px;}  
    .coachInfoWrap ul li a {font-size: 12px}  

    .coachInfoWrap ul.coach_public_tabs li {flex:0 0 36%}
    span.addAvailabilityTime {float: right;}
    
    .btn_set_left { text-align: center;}

    .delete_ac.float-right {float: none !important;}    

    .badge.badge-pill.freetrial-badge {white-space: inherit}

    .heading h2 {font-size: 30px;}

    .flip-container .flipper .back {
        font-size: 13px;
    }

    .packageInfo h3 {font-size: 18px}
    .packageInfo h5 {font-size: 16px}

    .textEffect p{font-size: 20px; letter-spacing: 5px;}
    .slick-list {max-width: 90%; margin: 0 auto !important;}
    .slick-prev {left:-10px !important;}
    .slick-next {right:-10px !important;}

    section.aboutBanner p, .aboutText p {text-align: left;}

    .modal-dialog { margin: 1.75rem auto; }
    .siq_bR {
        bottom: 145px;
        right: 10px;
    }


    /********Dip end*******/
    .chart_box_container{
        right: 6px !important;
        z-index: 9999 !important;
    }
    .buttonSetManageSubscription {
        justify-content: center;
    }
    .change_sub{
        order:-1;
    }

    .small-device-show {
        display: block;
        margin: 5px 0 10px;
    }
    .large-device-show{
        display: none;
        text-align: center;
    }

    .zsiq_floatmain.zsiq_theme1.siq_bL {
        bottom:70px !important
    }


}
@media (max-width:575px) {
    .bannerText h2 {
        font-size: 1.5rem;
    }
    .bannerText h3 {
        font-size: 2.2rem;
        margin-top: 1rem;
    }
    .ant-picker-datetime-panel{
        display: flex;
        flex-direction: column;
    }

    a.logoHolder {
        top: 0px;
        width: 175px;
        margin: 10px 0;
        display: block;
    }

    ul.socialIcon {
        text-align: center;
        margin-bottom: 0.2rem;
    }

    .topbarAction.text-right {
        text-align: center !important;
        margin-bottom: 0.7rem;
        margin-top:  1rem;
    }
    .topbarAction.text-right.margin-logout {
        text-align: center !important;
        margin-bottom: 0.7rem;
        margin-top:  0.3rem;
    }
    .topbarAction a {
        font-size: 14px;
        margin: 0px 5px;
    }

    .searchList {
        width: 100%;
        position: relative;
        margin-top: 15px;
    }

    .searchPart .searchList input {
        border-radius: 25px !important;
        min-height: 50px;
    }

    .searchPart::before {
        display: none;
    }

    .webinnerContent .heading h3 {
        font-size: 30px;
    }

    .new,
    .old,
    .current {
        font-size: 45px;
    }

    #js-days:after,
    #js-hours:after,
    #js-minutes:after,
    #js-seconds:after {
        bottom: -37px;
        font-size: 12px;
    }

    .defaultBtn {
        padding: 10px 30px;
        font-size: 15px !important;
    }

    .registrationForm {
        padding: 35px 10px 25px 10px;
        color: #000;
    }

    .registrationForm .defaultBtn {
        padding: 10px 23px;
        font-size: 15px !important;
        line-height: 28px;
    }

    .otherRegistrationFilds {
        padding: 50px 20px;
    }

    .registrationForm.loginForm {
        padding: 44px 10px 25px 10px;
    }

    .chooseVideo img,
    .chooseVideo {
        height: 250px;
    }

    section.chooseWrap::after {
        bottom: 190px;
    }

    .chooseWrap .col {
        width: 100%;
        flex: 100% 0;
        margin-bottom: 7px;
    }

    .featuresWrap .col-md-7.pl-5 {
        padding-left: 16px !important;
    }

    .priceWrap {
        margin: 40px 0px 35px;
    }

    .priceHeading {
        margin: 0px 7px;
        font-size: 12px;
        padding: 15px 3px;
        line-height: 15px;
    }

    .priceItem {
        margin: 0px 15px;
        font-size: 13px;
        padding: 13px 0px;
    }

    form.questionsForm .w-75,
    form.questionsForm .w-50 {
        width: 100% !important;
    }

    .questionsForm fieldset {
        width: 100%;
        margin: 0;
    }
    .questionsForm .defaultBtn {
        margin-bottom: 10px;
    }

    #questionsProgressbar li:before {
        width: 30px;
        line-height: 30px;
        font-size: 16px;
        border-radius: 8px;
    }

    #questionsProgressbar li:after {
        top: 14px;
    }

    .areas.d-flex {
        flex-wrap: wrap;
    }

    .areas .custom-control.custom-checkbox {
        margin: 4px 0px;
    }
    /********Dip started*******/
    .bannerText h1 span {
        line-height: 68px
    }

    .bannerWrap .aboutVideo iframe {
        max-height: 223px;
    }

    .aboutVideo iframe {
        max-height: 223px;
    }

    .flip-container .flipper .back {
        font-size: 13px
    }

    .CoachingItem h3 {
        font-size: 20px;
    }

    .coachImg>img {
        margin: 0 auto;
        display: block;
        border-radius: 31px;
        -webkit-border-radius: 31px;
        -moz-border-radius: 31px;
        -ms-border-radius: 31px;
        -o-border-radius: 31px;
    }

    .coachsProfileWrap .defaultBtn {margin-bottom: 30px; margin: 0 auto 30px; display:block; max-width: 218px}

    .paymentrecieved_coach ul li {flex: 0 0 48% !important; padding: 0 5px !important; margin-bottom: 15px;}

    .userBookingList.trans_his ul li {flex:0 0 49% !important; padding: 0 5px !important; margin-bottom: 15px; }
    
    .bookingCocachDetails.booking_list ul li {flex:0 0 48% !important; padding: 0 5px !important; margin-bottom: 15px; }
    .bookingCocachDetails.booking_list ul li.userCoachMore, .bookingCocachDetails.booking_list ul li:nth-last-child(1) {flex: 0 0 100% !important}

    .bookingCocachDetails.booking_list ul li:nth-child(1){
        display: flex;
        flex: 0 0 100% !important;
        justify-content: center;
    }

    .bookingCocachDetails.personalTransaction ul li{
        flex: 0 0 45% !important; padding: 0 5px !important; margin-bottom: 15px;   
    }

    .bookingCocachDetails.personalTransaction ul li:nth-child(1){
        display: flex;
        flex: 0 0 100% !important;
        justify-content: center;
    }



    .searchHead {
        justify-content: center; 
        flex-direction: column
    }
    .searchShortBy {margin: 0 15px;}

    
    .userBookingList .nav-item {flex: 0 0 50%;}
    .userBookingList .nav-item .nav-link {  font-size: 12px; text-align: center }



    .packageList {flex-direction: column;}
    .subPackage .packageInfo {flex:0 0 100%; width: 100%; }
    .packageList .packageActon {order:-1; width: 100%; border:none; padding: 30px 15px 15px; text-align: left;}
    .container_pk_item .packageInfo {padding-top: 36px;}
    .container_pk_item .packageInfo .packagedropdownbutton {margin-right: 15px; text-align: right}

    .walletBalance { flex-direction: column; width: 100%; }
    .walletBalance h5 {margin: 15px 0 !important;}
    .searchHead button {margin-right: auto; margin-bottom: 15px;}


    .start_end_row {flex-direction: column;}
    .start_end{flex:0 0 100%; margin-bottom: 1em; margin-top: 1em;}
    .start_end .row{margin-right:-15px !important}
    .start_end_button.pr-2 {padding-right: 0 !important;}

    .rating__opt_main{
        flex-direction: column;
    }
    .opt__radio{
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .top_rating span{
        flex: 0 0 120px;
    }
    .subscription_card{
        min-width: 100%;
    }
    .buttonSetManageSubscription{
        flex-direction: column;
        gap: 1rem;
        min-width: 100%;
        max-width: 100%;
    }
    .buttonSetManageSubscription button {
        width: 100%;
    }

    .subscription_card {background-size: 100% 100%;}

    .acsub {text-align: left;}

    /********Dip end*******/


    /* Harsh started */
    .manageSubscriptionModalButtonContainer {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .manageSubscriptionModalButtonContainer button {
        width: 100%;
    }
    .coaching_area{
        flex-direction: column;
    }
    /* Harsh End */
    .cardBody {
        flex-direction: column;
    }

    .coacActionhWrap .cardBody .cardDItem {
        width: 100%;
        flex: 0 0 100%;
        margin: 10px 0 !important;
    }
    .bannerVideo video {
        height: 280px !important;
        box-shadow: 0px 20px 50px rgb(9 46 40 / 35%);
        border-radius: 10px;
    }

}



@media (max-width:450px) {
    .bannerText h2 {
        font-size: 1.2rem;
    }
    .bannerText h3 {
        font-size: 2rem;
        margin-bottom: 1rem;
    }
    .tui-full-calendar-month-dayname .tui-full-calendar-month-dayname-item {padding-left:0 !important}
    .subscription_card{
        min-width: 100%;
    }
    .slots{
        display: grid;
    }
    .slotsDate{
        font-size: smaller;
    }
    .coachProfileFormEdit label {
        font-size: 13px;
    }
}


@media (max-width:320px) {
    :root {--unavailable-ad: 0px; }
    .coachProfileFormEdit label {
        font-size: 11px;
    }
    .slots{
        display: grid;
    }
    .slotsDate{
        font-size: smaller;
    }
}

@media (max-width:300px) {
    .coachProfileFormEdit label {
        font-size: 10px;
    }
  
}

:root {--unavailable-ad: 120px; }