#staticBackdrop .modal-body{

    background-color: black;
}


#staticBackdrop .modal-dialog{
    max-width : 680px !important;
}

#staticBackdrop h1{
    font-size: 2rem;
    font-weight: bold;
}


#staticBackdrop .intro-1{

    font-size: 16px;
}

#staticBackdrop .close{

    color: #fff;
}


#staticBackdrop .close:hover{

    color: #fff;
}


#staticBackdrop .intro-2{

    font-size: 13px;
}