.reg {
    padding: 4rem 0;
    background-color: #f3f8fd;
}
.reg_card.card {    
    border-radius: 0.6rem;
    overflow: hidden;
}
.reg_card.card .card-body {
    padding: 2rem 1.5rem;
}
.signup_success h4 {
    font-size: 1.8rem;
}
.reg_head>h2 {
    color: #05264E;
    font-weight: 700;
    font-size: 2.3rem;
}
.reg_image {
    padding: 4rem;
    color: #fff;
}
.reg_image ul li {
    font-size: 1.2rem;
    position: relative;
    padding-left: 1.4rem;
}
.reg_image ul li:after {
    content: "";
    position: absolute;
    left: 0;
    width: 1.2rem;
    height: 1.2rem;
    background: url(./../images/tick.png) center no-repeat;
    top: 0.4rem;
}

.reg_image ul li + li {
    margin-top: 1.2rem;
}
.regf_title {
    color: #05264e;
    font-weight: 400;
    text-transform: uppercase;
    font-size: .9rem;
}
.reg_form {
    padding: 4rem;
    min-height: 450px;
}
.reg_form .form-group {
    margin-bottom: 0.5rem;
}

.reg_form .form-group .form-label {
    line-height: 1;
    margin-bottom: 0;
    font-weight: 600;
    font-size: .9rem;
    color: #3f454a;
}

.confirmpassword {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.confirmpassword i {
    position: absolute;
    left: calc(100% - 15%);
}

.confirmpassword i.fa.fa-check {
    color: #00e1ba;
}

.confirmpassword i.fa.fa-times {
    color: #e10000eb;
}
.reg_head>h3 {
    font-size: 1.2rem;
    color: #05264e;
}
.reg_head>h3 > span {
    background-color: #e8edf3;
    padding: 0.2rem 1rem;
    display: inline-block;
    border-radius: 1rem;
}
.reg_head>h3 > span i {
    font-size: 1rem;
}
