.session_item {
    background-color: rgb(255 255 255 / 60%);
    border-radius: 0.8rem;
    padding: 1rem;
    margin-bottom: 1rem;
    transition: all .3s ease;
    box-shadow: 0px 11px 20px 0px rgb(24 30 29 / 8%);
    position: relative;
}
.session_item:hover {
    box-shadow: none;
}
/* .session_item:after {
    content: "";
    position: absolute;
    right: 1.5rem;
    top: 50%;
    width: 0.6rem;
    height: 0.6rem;
    border-style: solid;
    border-color: #97ada9;
    border-width: 2px 2px 0 0;
    border-top-right-radius: 2px;
    transform: rotate(45deg) translateY(-50%);
    transform-origin: center;
} */
.page-title {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.2;
    color: #181B15;
    margin-bottom: 2rem;
}
.upcoming .session_item {
    background-color: #fff;
    border: 1px solid #00e1ba;
}
.session_user_image {
    flex: 0 0 3.438rem;
    max-width: 3.438rem;
}
.session_user {
    flex: 0 0 18%;
    max-width: 18%;
}
.session_name {
    flex: 1 1;
}
.session_date {
    flex: 0 0 14%;
    max-width: 14%;
}
.session_time {
    flex: 0 0 14%;
    max-width: 14%;
}
.session_type {
    flex: 0 0 15%;
    max-width: 15%;
}
.session_text {
    font-size: 1rem;
    font-weight: 600;
}
.session_label {
    font-size: .9rem;
}
.session_wrap a {
    color: unset;
}
.session_user,
.session_name,
.session_date,
.session_time,
.session_type {
    padding-left: .5rem;
    padding-right: .5rem;
}




@media screen and (max-width: 991px) {
    .session_text {
        font-size: .9rem;
    }
}

@media screen and (max-width: 850px) and (min-width: 768px) {
    .session_user,
    .session_name,
    .session_date,
    .session_time,
    .session_type {
        flex: 0 0 50%;
        max-width: 50%;
        padding-top: .5rem;
        padding-bottom: .5rem;
    }
}

@media screen and (max-width: 640px) {
    .session_user,
    .session_name,
    .session_date,
    .session_time,
    .session_type {
        flex: 0 0 50%;
        max-width: 50%;
        padding-top: .5rem;
        padding-bottom: .5rem;
    }
}