/* Header */
.content_main {
    background-color: #f5f7fa;
    min-height: 100vh;
    padding-left: 16.25rem;
    padding-top: 4.3rem;
}
.user_dropdown .btn {
    background-color: #00e1ba;
    padding: 0.8rem 1.4rem;
}

.notification_dropdown .btn {
    padding: 1rem 2rem;
    line-height: 1;
    position: relative;
}

.notification_dropdown .btn,
.user_dropdown .btn {
    box-shadow: none !important;
    height: 4.25rem;
}

.header_logo {
    padding: 0.5rem 0;
}

.coach_header {
    padding-left: 0.96rem;
    box-shadow: 0px 10px 20px rgb(20 34 32 / 6%);
}

.notification_dropdown .btn:after {
    display: none;
}

.notification_count {
    font-size: .7rem;
    width: 1.3rem;
    display: block;
    position: absolute;
    height: 1.3rem;
    top: 1rem;
    right: 1.2rem;
    background-color: #FF6E6E;
    text-align: center;
    line-height: 1.5rem;
    color: #fff;
    font-weight: 600;
    box-shadow: 0 0 0 0.3rem rgb(255 110 110 / 32%);
}

.toggleSidebar.btn {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -1.3rem;
    height: 2.6rem;
    display: none;
}

/* Header end */


/* Sidebar */
.cm_sidebar {
    position: fixed;
    left: 0;
    bottom: 0;
    top: 4.3rem;
    width: 16.25rem;
    padding: 1rem 0.5rem;
    overflow-y: auto;
    transition: transform .3s ease;
    border-right: 1px solid #dee5e4;
    background-color: #fff;
}
.cm_sidebar::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: rgb(240 245 247);
}

.cm_sidebar::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}
.cm_sidebar::-webkit-scrollbar-corner {
    background-color: transparent;
}
.cm_sidebar::-webkit-scrollbar-thumb {
    background-color: rgb(207 217 221);
    border-radius: 5px;
}
.sidebar_top {
    background-color: #CCF9F1;
    padding: 2.5rem;
    overflow: hidden;
    position: relative;
}

.sidebar_top>svg {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
}

.sidebar_top_content {
    position: relative;
    z-index: 1;
}

.cm_username {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.2;
    text-align: center;
    color: #290C3C;
    margin-top: 2rem;
    margin-bottom: 0;
}
.cm_nav>.navbar-nav>.nav-item>a {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    background-color: #fff;
    border-radius: 0.4rem;
    margin: 0.2rem 0;
    color: #2f3c3c;
    transition: all .3s ease;
    font-weight: 400;
}
.cm_nav>.navbar-nav>.nav-item>a:hover {
    background-color: #00e1ba;
    color: #172703;
}

.cm_nav>.navbar-nav>.nav-item>a img {
    margin-right: 0.4rem;
    opacity: .6;
    transition: opacity .3s ease;
}

.cm_nav>.navbar-nav>.nav-item>a:hover img {
    opacity: 1;
}

.logout_link {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    background-color: #FFD2D2;
    border-radius: 0.4rem;
    margin: 0.2rem 0;
    color: #FF1010;
    transition: all .3s ease;
    font-weight: 600;
}

.logout_link:hover {
    color: #FF1010;
}

.logout_link>img {
    margin-right: 0.4rem;
}

/* Sidebar end */

/* Main Content */

.content_wrap {
    padding: 1.2rem;
}

body .bookingCalendar .react-calendar .react-calendar__tile--active {
    background: #00e1ba !important;
}

.card_style_1 {
    background-color: #fff;
    border-radius: 0.6rem;
    border-bottom: 0.3rem solid #00e1ba;
    margin-bottom: 1.6rem;
}

.webiner_loader .css-df17o1 {
    position: fixed;
    z-index: 10000;
}

.sidebar_top_content img {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.user_dropdown img {
    width: 40px;
    height: 40px;
    object-fit: cover;
}

/* Main Content end*/


@media screen and (max-width: 991px) {
    .card-tool {
        padding-left: 0;
        margin-top: 1rem;
    }
}

@media screen and (max-width: 767px) {
    .cm_sidebar {
        transform: translateX(-16.25rem);
        background-color: #fff;
    }

    .active .cm_sidebar {
        transform: translateX(0);
    }

    .content_main {
        padding-left: 0.9rem;
        padding-right: 0.9rem;
    }

    .content_wrap {
        padding-left: 0;
        padding-right: 0;
    }

    .coach_header {
        padding-left: 2.96rem;
    }

    .toggleSidebar.btn {
        display: block;
    }
}

.webiner_tab .tab-pane {
    background-color: transparent !important;
    text-align: left !important;
}

.webiner_tab .nav-tabs .nav-link {
    color: #4a4949;
    background-color: transparent !important;
    border: 0 !important;
    position: relative;
}

.webiner_tab .nav-tabs .nav-link::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background-color: #00e1ba;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.webiner_tab .nav-tabs a[aria-selected="true"]::before {
    transform: scaleX(1);
}

.webiner_tab .nav-tabs a[aria-selected="true"] {
    color: #00e1ba;
}
