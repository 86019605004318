.organization_name {
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
}
.organization_name > span {
    display: inline-block;
    padding: 0.4rem 1.5rem;
    background-color: #f6f9f9;
    border-radius: 2rem;
    color: #3f4c4a;
    font-size: 1rem;
}

.headerTopWrap.corporate {
    background-color: #141C1C;
    border-bottom: 1px solid #1f2a2a;
}
.corporate .nav_wrap {
  background-color: #0D1212;
}
.corporate .nav_wrap .navbar-nav .nav-link,
.corporate .topbarAction a {
    color: #fff;
}
.corporate .topbarAction a:hover {
    color: #00e1ba;
}
.coach_header.corporate {
    background-color: #0D1212;
}
.corporate .organization_name > span {
    background-color: #1d2424;
    color: #fff;
}