/* user dashboard */

.chooseItem p i {
    font-size: 13px;
}
.coachingWrap .row a > p {
    color: #fff;
    text-decoration: none !important;
    margin-bottom: 30px;
}
.coachingWrap .row a{
    text-decoration: none !important;
}
.userInfoWrap .coachImg > img {
    height: 240px;
}
section.userDashboardWrap {
    background: #f5f5f5;
}
.userDashboardWrap .searchPeopleList {
    padding: 20px 15px;
}
.userDashboardWrap .searchShortBy select {
    background: #f5f5f5;
}
.filterApply a {
    color: #505050;
    display: flex;
    text-decoration: none
}
.filterApply a:hover {
    color: #734d9d;
}
.filtersModal .searchFilter label {
    color: #ffffff;
}
.filtersModal .searchFilter h3 {
    color: #ffffff;
}
.filtersModal.modal .form-control {
    color: #000;
    background: #ffffff;
}
.filtersModal .searchFilter button.btn.dropdown-toggle {
    background: #ffffff;
    box-shadow: none;
    border: 1.5px solid #dedede !important;
    color: #000000 !important;
}
.userDashboardWrap .defaultBtn.w-100 {
    padding: 13px 30px 12px;
}
.coachsProfileWrap.userProfileWrap{
    padding: 35px 0px 35px;
}
.searchPart {
    box-shadow: 0px 0px 6px rgb(119 119 119 / 34%);
    border-radius: 25px;
    background: #fff;
    height: 100%;
}
.coachInfoWrap.userDashboardLeftPart ul {
    display: block;
}
.coachInfoWrap.userDashboardLeftPart ul li {
    width: 100%;
    text-align: left;
}
.coachInfoWrap.userDashboardLeftPart ul li a {
    text-transform: capitalize;
    font-size: 15px;
    padding: 15px 15px 15px;
    border-radius: 5px;
    margin-bottom: 5px;
    overflow: hidden;
}
.coachInfoWrap.userDashboardLeftPart ul li a:after{
    border-radius: 15px 3px 15px 3px;
}
.coachInfoWrap.userDashboardLeftPart ul li.active a::before, 
.coachInfoWrap.userDashboardLeftPart ul li a:hover::before{
    display: none;
}

.coachInfoWrap ul li a:hover::after, 
.coachInfoWrap ul li a.active::after {
    transform: scaleY(1);
}


.userDashboardWrap .heading{
    margin: 0;
    color: #9b59b6;
}
.userDashboardWrap span.R_divider {
    background: #9b59b6;
}
.userDashboardWrap .plan {
    background: #fff;
    border-radius:8px;
    color: #000;
    box-shadow: 0px 0px 8px #9a9a9a66;
}
.userDashboardWrap .plan:hover {
    box-shadow: 0px 0px 15px #9a9a9a66;
}
.plan h3 {
    background: #3498db;
    text-align: center;
    padding:50px 0px;
    border-radius: 8px 8px 0px 0px;
    margin-bottom: 25px;
    color: #fff;
    position: relative;
    overflow: hidden;
}
.plan h3::after {
    position: absolute;
    left: 110px;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #ffffff4a;
    content: "";
    transform: rotate(45deg);
    border-radius: 22px;
}
.plan h3 img {
    margin: 0 auto 10px;
    display: block;
}
.plan h3 span {
    font-size: 14px;
    background: #fff;
    color: #616161;
    padding: 10px 18px;
    box-shadow: 0px 0px 6px #69696947;
    border-radius: 18px;
    display: block;
    width: 84%;
    margin: 0 auto;
    margin-top: 7px;
    position: absolute;
    bottom: 10px;
    left: 8%;
}
.plan ul li {
    list-style-type: decimal;
    color: #444;
    margin-bottom: 5px;
}
.plan ul {
    padding-bottom: 15px !important;
}
.plan.premium h3 {
    background: #99d865;
}
.userBookingSearch .form-control {
    border: none;
    height: 49px;
    padding: 0px 25px;
    font-size: 16px;
    line-height: 27px;
    text-transform: capitalize;
    box-shadow: 0px 0px 6px rgb(119 119 119 / 34%);
    border-radius: 25px;
}
.bookingCocachDetails ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: #fff;
    color: #2a3839;
    padding: 15px;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 0;
    border: 1px solid #efefef;
    align-items: flex-start;

}

.bookingCocachDetails ul li {
    word-break: initial;
}

img.userBookingCocachImg {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 21px;
}
.bookingCocachDetails li b {
    display: block;
    font-size: 14px;
}
.bookingCocachDetails li span {
    font-size: 16px;
    color: #616161;
}
.payment_details_coach li b {
    display: block;
    font-size: 11px;
    color: #734d9d;
}
.payment_details_coach li span {
    font-size: 13px;
    color: #616161;
}
li.userCoachMore a {
    background: #f5f5f5;
    color: #82bd51;
    font-size: 15px;
    padding: 7px 10px;
    width: 35px;
    height: 35px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}
li.userCoachMore a i {
    position: relative;
    top: 1px;
    left: 1px;
}
.BCDView {
    background: #fff;
    padding: 15px;
    border-radius: 8px;
    border: 2px solid #f5f5f5;
    color: #000;
}
.BCDView p {
    font-size: 14px;
    margin-bottom: 5px;
    color: #4c4c4c;
}
.coachInfoWrap.userDashboardLeftPart::after{
    display:none;
}
.bookingCocachDetails span.badge {
    font-size: 13px;
    font-weight: normal;
}
i#calendarTypeIcon {
    margin-top: -6px;
}
a.defaultBtn.cancel {
    background: #f74646;
}
a.defaultBtn.cancel:hover {
    background: #fff;
    color: #f74646 !important;
}
.matchWrap .sportsWrap {
    margin-bottom: 15px;
}
.tui-full-calendar-popup {
    color: #000;
}
.cardHeading {
    display: flex;
    justify-content: center;
    color: #000;
    align-items: center;
    flex-wrap: wrap;
    margin: 12px 0px;
}
.cardHeading h3 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}
.cardHeading p {
    margin: 0;
}
.cardHeading p {
    margin: 0;
    color: #6f6f6f;
}
.cardAction a {
    display: inline-block;
    border: 1px solid #82bd51;
    color: #82bd51;
    padding: 5px 16px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 4px;
    text-transform: uppercase;
    text-decoration: none;
    margin-left:5px
}
.cardBody div.cardDItem{
    width: 31%;
    margin: 10px 10px;
    border-bottom: 1px dashed #99d865;
    padding: 10px
}
.cardBody {
    border-radius: 5px;
    background: #fff;
    padding: 15px;
    color: #505050;
    display: flex;
    flex-wrap: wrap;
}
.cardBody p {
    font-size: 13px;
    margin: 0;
    word-break: break-word;
}
.cardDItem b {
    font-size: 14px;
    margin-bottom: 5px;
    display: block;
    color: #000;
    word-wrap: break-word;
}
#addPayment .custom-control-label {
    color: #fff;
}
.modal#addPayment .form-control::-webkit-input-placeholder { color:#fff  !important}
.myFavorite .icon-wishlist {
    top: 10px;
    right: 25px;
}
.thead-green
{
    background: #9b59b6;
    color: #fff;
    
}
.session-book
{
    margin: 15px 0;
}
.session-book tbody tr:hover{
    background: rgb(231, 231, 231);
}

.tui-full-calendar-popup-detail .tui-full-calendar-schedule-title { word-break: initial;}