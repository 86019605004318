@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,600;1,300;1,400;1,500;1,600&display=swap');

@font-face {
    font-family: 'ImpactMTStd';
    src: url('../fonts/ImpactMTStd.eot');
    src: url('../fonts/ImpactMTStd.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ImpactMTStd.woff2') format('woff2'),
        url('../fonts/ImpactMTStd.woff') format('woff'),
        url('../fonts/ImpactMTStd.ttf') format('truetype'),
        url('../fonts/ImpactMTStd.svg#ImpactMTStd') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

*:focus {
    outline: none;
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}
html body {
    font-family: 'Poppins', sans-serif !important;
    overflow-x: hidden;
    font-weight: 300;
    font-size: 1rem;
}

body > iframe[style*="position: fixed"] {
    display: none;
}

[tabindex="-1"]:focus {
    outline: 0 !important;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}
a {
    text-decoration: none !important;    
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}
b, strong {
    font-weight: 600 !important;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}
.template{
   display: flex;
   flex-direction: column;
   justify-content: center;
    align-items: center;
}
.template-child{
    margin: 40px;
    align-self: center;
    text-align: center;
}
.container {
    max-width: 1240px;
}
.logoHolder {
    display: inline-block;
    position: relative;
    top: 16px;
}
.socialIcon {
    list-style: none;
    text-align: left;
    margin-bottom: 0.7rem;
    margin-top: 0.7rem;
    padding: 0;
}
.socialIcon li {
    display: inline-block;
    margin-left: 1.2rem;
}
.socialIcon li:first-child {
    margin-left: 0rem;
}
.socialIcon li a,
.topbarAction i {
    color: #9dadad;
}
.socialIcon li a:hover {
    color: #00e1ba;
    transition: 0.3s;
}
.defaultBtn:hover img {
    left: 4px;
    transition: 0.3s;
}

.topbarAction {
    margin-bottom: 0.7rem;
    margin-top: 0.7rem;
    color: #fff;
}
.topbarAction a,
.top_nav_link {
    display: inline-block;
    margin-left: 30px;
    transition: 0.3s all;
    color: #4c5656;
    line-height: 1.3;
    cursor: pointer;
}
.topbarAction a:hover,
.topbarAction a:hover i,
.top_nav_link:hover,
.top_nav_link:hover i {
    color: #00e1ba;
}

.topbarAction img {
    margin-right: 3px;
    position: relative;
    top: -3px;
}
.white_btn select{
    background: #fff;
}
.headerWrap,
.headerTopWrap {
    position: relative;
    z-index: 998;
}
.headerTopWrap {
    background-color: #fafdfd;
    border-bottom: 1px solid #e7f1f1;
}
.topbarAction select {
    color: #212529;
    border-color: #8a95a3;
}
.aboutVideo video {
    border-radius: 5px;
    display: block;
}
.navbar .container {display: block}
.navbar.navbar-light {
    padding: 10px 0;
}
.headerWrap {
    height: 76px;
}
.player-wrapper div {
    width: 100% !important;
    height: 100% !important;
}

.nav_wrap .navbar-nav .nav-link {
    font-size: 1.05rem;
    padding: 0;
    color: #2d2e2e;
    font-weight: 400;
    font-family: inherit;
    transition: 0.3s all;
    position: relative;
    text-transform: capitalize;
    text-decoration: none;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
    -ms-transition: 0.3s all;
    -o-transition: 0.3s all;
    line-height: 80px;
    cursor: pointer;
}
.nav_wrap .navbar-nav .nav-item .nav-link::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: -1px;
    height: 2px;
    background: #00e1ba;
    transition: transform 0.3s ease;
    transform: scaleX(0);
    transform-origin: center;
}
.nav_wrap .navbar-nav .nav-item + .nav-item {
    margin-left: 1.5rem;
}
.nav_wrap .navbar-nav .nav-item .nav-link:hover::before,
.nav_wrap .navbar-nav .nav-item .nav-link.active::before {
    transform: scaleX(1);
}
.nav_wrap .navbar-nav .nav-item .nav-link.active,
.nav_wrap .navbar-nav .nav-item .nav-link:hover {
    color: #00e1ba;
}
#banner .carousel-item img {
    width: 100%;
}

.footerBottom {
    background: #2a3839;
    color: #fff;
    padding: 25px 0px;
}

.flotingSpace {
    padding-bottom: 90px;
}

section.footerBottom p,
section.footerBottom a {
    font-size: 14px;
    margin: 0;
    text-decoration: none;
    color: #fff;
}

.footerTop {
    background: url(../images/footeBg.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px 0px;
    position: relative;
}

section.footerTop h3 {
    font-size: 23px;
    text-align: center;
    color: #fff;
    font-weight: 300;
    margin-bottom: 25px;
    text-transform: uppercase;
}

ul.action {
    text-align: center;
    padding: 0;
    text-decoration: none;
}

ul.action li {
    position: relative;
    padding: 15px 0px;
    list-style: none;
}

ul.action li::after {
    border-bottom: 1px solid #fff;
    content: "";
    position: absolute;
    top: 0;
    left: 10%;
    width: 280px;
}

ul.action li:first-child::after {
    display: none;
}

ul.action li a {
    color: #fff;
    font-size: 15px;
    padding: 0px 10px;
    text-transform: uppercase;
}
ul.nounderline li a{
    text-decoration: none !important;
}

.description a {
    display: block;
    text-align: left;
}

.description h5 {
    color: #fff;
    font-weight: 400;
    font-size: 17px;
    margin: 25px 0px 10px;
}

.description h6 {
    font-size: 14px;
    color: #fff;
    font-weight: 300;
    margin-bottom: 15px;
    line-height: 18px;
    text-align: justify;
}

.description p {
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.description p img {
    margin-right: 10px;
}

.newsLetter p {
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    margin: 40px 0px 15px;
}

.newsLetter input.form-control {
    text-align: left;
    font-size: 15px;
    background: #0000006b;
    border: none;
    padding: 15px 15px;
    height: 50px;
    border-radius: 0;
    outline: none !important;
    box-shadow: none !important;
    color: #fff;
}

button:focus {
    outline: none;
}

.newsLetter .form-group {
    text-align: center;
}

.newsLetter a.defaultBtn {
    margin-top: 5px;
    border-color: #fff;
    color: #fff;
}

.blogContent {
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    /* box-shadow: 0px 0px 10px rgb(0, 0, 0); */
}

.bInfo {
    padding: 15px;
}

.bInfo h3 a {
    font-size: 18px !important;
    line-height: 28px !important;
    color: #0b0b0b;
    display: inline-block;
}
.custom-image-loader {
    width: 70px !important;
    height: 70px !important;
    border-radius: 8px;
}
.bInfo p {
    margin: 0;
    color: #384b4c;
}

.bFooter {
    display: flex;
    justify-content: space-evenly;
    border-top: 1px solid #f3f3f3;
    align-items: center;
}

.bFooter p {
    margin: 0;
    width: 33%;
    padding: 10px 5px;
}

.bFooter p:nth-child(2) {
    border-left: 1px solid #f3f3f3;
    border-right: 1px solid #f3f3f3;
}

.bFooter p i {
    display: block;
    color: #00e1ba;
}

.bFooter p span {
    font-size: 12px;
    color: #384b4c;
    margin-top: 10px;
    display: inline-block;
}

span.R_divider {
    display: inline-block;
    width: 100px;
    background: #fff;
    height: 1px;
    margin: 20px 0px 30px;
    position: relative;
}

span.R_divider::after {
    content: "";
    left: 10px;
    top: 3px;
    width: 100%;
    background: inherit;
    height: 1px;
    position: absolute;
}

.btn.defaultBtn,
.defaultBtn {
    text-decoration: none !important;
    background: #00e1ba;
    display: inline-block;
    padding: 10px 30px;
    text-transform: uppercase;
    border-radius: 0.25rem;
    color: #fff !important;
    transition: 0.3s;
    border: 1px solid #00e1ba;
    /* box-shadow: 0px 0px 6px rgb(42 56 57 / 11%); */
    line-height: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.btn.defaultBtn.btn-sm {
    padding: 0.5rem 1.5rem;
}
.form-check .form-check-input:checked {
    background-color: #00e1ba;
    border-color: #00e1ba;
}
.btn.defaultBtndng, 
.defaultBtndng {
    text-decoration: none !important;
    background: #ec3737;
    display: inline-block;
    padding: 10px 27px;
    text-transform: uppercase;
    border-radius: 25px;
    color: #fff !important;
    border: none;
    transition: 0.3s;
    box-shadow: 0px 0px 6px rgb(42 56 57 / 11%);
    line-height: 1;
    border: 1px solid #ec3737;
}

.defaultBtn:not(:disabled):hover {
    color: #00e1ba !important;
    background: #fff;
    transition: 0.3s all;
}
.defaultBtn:disabled {
    opacity: .6;
}
.defaultBtn:disabled:hover {
    cursor: not-allowed;
}
.defaultBtndng:hover {
    color: #ec3737 !important;
    background: #fff;
    transition: 0.3s all;
}

.blogWrap {
    padding: 50px 0px;
    background-color: #f7f7f7;
}

.slideIn {
    -webkit-animation-name: slideIn;
    animation-name: slideIn;
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
}

@keyframes slideIn {
    0% {
        transform: translateY(1rem);
        opacity: 0;
    }

    100% {
        transform: translateY(0rem);
        opacity: 1;
    }

    0% {
        transform: translateY(1rem);
        opacity: 0;
    }
}

@-webkit-keyframes slideIn {
    0% {
        -webkit-transform: transform;
        -webkit-opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        -webkit-opacity: 1;
    }

    0% {
        -webkit-transform: translateY(1rem);
        -webkit-opacity: 0;
    }
}

/*  */
.dropdown-packgae {
    display: block;
    width: 150%;
    /* padding: .4rem 1rem; */
    padding: 4px 9px;
    color: "white";
    font-size: 14px;
    text-transform: capitalize;
    left: 0;
    height: 5px;
    position: relative;
    top: 1px;
    white-space: nowrap;

}


.dropdown-item {
    display: block;
    width: 100%;
    padding: .4rem 1rem;
    clear: both;
    font-weight: 400;
    color: #101010;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    text-transform: capitalize;
    font-size: 14px;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show>.btn-light.dropdown-toggle {
    outline: none !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
    outline: none;
}

.dropdown-menu {
    background-color: #ffffff;
}

.dropdown-toggle::after {
    border: none;
    background: url(../images/dArrow.png) no-repeat;
    content: "";
    width: 10px;
    height: 5px;
    position: relative;
    top: 1px;
}
.bannerText > h5 {
    font-size: 1.2rem;
    color: #4d625f;
}
.bannerText h2 {
    font-size: 2.2rem;
    font-family: 'ImpactMTStd';
}
.bannerText h2 > span {
    position: relative;
    display: inline-block;
    line-height: 1;
    margin-right: 10px;
    margin-left: 10px;
}
.bannerText h2 > span:after {
    content: "";
    position: absolute;
    right: 50%;
    top: 0;
    bottom: 0;
    width: 1px;
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(153,243,228,1) 20%, rgba(0,225,188,1) 50%, rgba(153,243,228,1) 80%, rgba(255,255,255,1) 100%);
}
.bannerText .defaultBtn {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
}
.bannerText p {
    font-size: 1.1rem;
    margin-bottom: 2rem;
}
.bannerWrap .aboutVideo iframe {
    border: none !important;
}
.bannerText h3 {
    font-size: 4rem;
    font-weight: 600;
    margin-bottom: 2rem;
    margin-top: 2rem;
    font-family: ImpactMTStd;
}
.bannerWrap {
    padding: 80px 0px;
    background: #fff url(../images/bannerBg.png) no-repeat;
    background-position: bottom left;
    position: relative;
    z-index: 1;
}
.bannerWrap:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    /* background: url(../images/banner_bg.png) 0 0 no-repeat; */
    background: url(./../images/bg_pattern.jpg) 0 0 no-repeat;
    /* bottom: -480px; */
    bottom: 0;
    z-index: -2;
    pointer-events: none;
    background-size: cover;
}
/* .bannerWrap:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 20rem;
    height: 20rem;
    background: url(../images/bannerBg.png) no-repeat;
    background-size: contain;
} */
.bannerVideo > div {
    width: auto !important;
    height: auto !important;
}


.searchWrap {
    background: #2a3839;
    padding: 70px 0px;
    color: #f7f7f7;
}

.searchWrap p {
    color: #f7f7f7;
}

.searchPart {
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.searchPart .dropdown-menu input {
    border: none;
    height: 20px;
    width: 20px;
    padding: 0px;
    font-size: 16px;
    box-shadow: none !important;
    line-height: 27px;
    text-transform: capitalize;
    background-color: rgb(235, 235, 235)
}

.searchList ::-webkit-input-placeholder { 
    font-weight:300; color:#212529; opacity: 1; font-size: 16px;
    
  }
  .searchList ::-moz-placeholder { 
    font-weight:300; color:#212529; opacity: 1; font-size: 16px;
  }
  .searchList :-ms-input-placeholder { 
    font-weight:300; color:#212529; opacity: 1; font-size: 16px;
  }
  .searchList :-moz-placeholder { 
    font-weight:300; color:#212529; opacity: 1; font-size: 16px;
  }

  .searchPart .dropdown-menu .form-check-input:checked {
    background-color: #00e1ba;
    border-color: #00e1ba;
}
.searchPart input {
    border: none;
    height: 100%;
    padding: 5px 15px;
    font-size: 16px;
    box-shadow: none !important;
    line-height: 27px;
    /* text-transform: capitalize; */
    width: 100%
}

.searchList .selectBox {
    height: 100%
}

.searchPart .dropdown {
    height: 100%
}

.searchPart .dropdown-toggle {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    background: #fff;
    height: 100%;
    display: block;
    padding: 0.44rem 0.75rem;
    border: none !important;
}

.searchPart .dropdown-toggle:after,
.searchPart .dropdown-toggle:before {
    content: none
}

.searchPart .dropdown-toggle:hover,
.searchPart .dropdown-toggle:focus {
    background: #fff;
    box-shadow: none;
    border: none !important;
    height: 100%;
    display: block;
    outline: none;
    padding: 0.44rem 0.75rem
}


.selectBox input {
    border-radius: 3px !important;
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    -ms-border-radius: 3px !important;
    -o-border-radius: 3px !important;
}

.selectBox label {
    line-height: 26px;
}

.selectBox .actions-btn {
    width: 100%;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}


.searchList {
    width: 50%;
    position: relative;
}

.searchPart::before {
    content: "";
    background: #00e1ba;
    position: absolute;
    width: 1px;
    height: 28px;
    right: 50%;
    top: 50%;
    z-index: 1;
    margin-top: -14px;
}

.searchPart .searchList:first-child input {
    border-radius: 25px 0px 0px 25px;
    min-width: 20px; min-height: 20px;
}

.searchPart .searchList:last-child input {
    border-radius: 0px 25px 25px 0px;
}

.searchWrap button.defaultBtn {
    width: 100%;
    font-size: 17px;
    transition: 0.4s;
    height: 50px;
}

.searchWrap button.defaultBtn svg {
    margin-left: 10px;
}

.searchWrap button.defaultBtn:hover {
    background: #fff;
    color: #00e1ba;
    transition: 0.4s;
}

.searchWrap button.defaultBtn:hover svg {
    fill: #00e1ba;
    transition: 0.4s;
}

.searchList img {
    position: absolute;
    top: 21px;
    right: 28px;
}

.aboutWrap .R_divider {
    margin: 20px 0px 18px;
}

.aboutWrap {
    background:#e1fffa url(../images/aboutBg.png) no-repeat;
    background-position: right 60px;
    background-size: 28%;
    overflow: hidden;
    padding: 60px 0;
}
.upcomingList>img {
    border-radius: 25px;
    margin-bottom: 15px;
    box-shadow: 0px 0px 6px #000;
    transition: 0.4s;
}

.upcomingList:hover>img {
    transition: 0.4s;
    -webkit-transform: scale(1.02);
    -moz-transform: scale(1.02);
    -ms-transform: scale(1.02);
    -o-transform: scale(1.02);
    transform: scale(1.02);
}

.upcomingText img {
    margin-right: 8px;
}
.upcomingText h4 {
    font-size: 22px;
    margin: 0;
    color: #000;
}
.webinarList {
    margin: 20px 0;
    position: relative;
}
.webinarList iframe {
    width: 100%;
    height: 280px;
    border-radius: 0.5rem;
}
.upcomingText p {
    font-size: 15px;
    color: #4c4c4c;
}
.webinnerWrap {
    padding: 12rem 0 5rem;
    overflow: hidden;
    position: relative;
    z-index: 1;
    color: #fff;
}
.webinnerWrap:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0%;
    top: 0;
    background: url(../images/second_bg.jpg) no-repeat center;
    background-size: cover;
    background-attachment: fixed;
    z-index: -1;
}
.webinnerWrap:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30%;
    top: 0;
    background-image: linear-gradient(to bottom, transparent, black);
    z-index: -1;
}
.upcomingWrap .webinnerContent .heading h3 {
    color: #2d2e2e;
}
.heading h2 {
    font-size: 40px;
    text-transform: uppercase;
    font-weight: 600;
}
.ne_date {
    font-size: 1rem;
    color: #384b4c;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 0.8rem;
}
.webinerStart {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 0.6rem;
    border-bottom: 0.3rem solid #00e1ba;
    box-shadow: 0px 15px 40px rgb(9 46 40 / 25%);
}
.ne_title {
    color: #00e1ba;
    font-size: 1.4rem;
    margin-bottom: 0.2rem;
    font-weight: 600;
}
.heading h3 span {
    color: #00e1ba;
}
.upcomingWrap .webinnerContent {
    background: none;
    padding: 0px;
}
.countdown-container {
    padding: 1.5rem 0;
}
.upcomingWrap {
    padding: 60px 0px;
    background-color: #f7fffe;
}
section.aboutWrap img {
    width: 100%;
}

.previousWrap {
    background: url(../images/master.png) no-repeat center;
    padding: 75px 0px;
    background-size: cover;
}

.pImgPart {
    position: relative;
}

.pImgPart>img {
    width: 100%;
    height: 280px;
    object-fit: cover;
    box-shadow: 0px 0px 6px #000;
    border-radius: 15px;
}

.pImgPart i {
    position: absolute;
    top: 15px;
    left: 15px;
}

.pImgPart span {
    position: absolute;
    bottom: 15px;
    left: 15px;
    color: #fff;
    font-size: 13px;
}

a.previousItem {
    display: block;
    position: relative;
    text-decoration: none !important;
}

a.previousItem h5 {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    margin: 12px 5px;
    transition: 0.3s all;
}

a.previousItem:hover h5 {
    color: #00e1ba;
    transition: 0.3s all;
}

a.previousItem p {
    color: #fff;
    font-size: 14px;
    line-height: 19px;
    margin: 0px 5px;
}

.pImgPart i img {
    width: 40px !important;
}

.owl-next {
    background: url(../images/sArrow.png) 50% 50% no-repeat !important;
    right: -33px;
    cursor: pointer;
    background-size: 100% !important;
    border-radius: 0 !important;
    top: 36%;
    height: 60px;
    width: 60px;
    position: absolute;
    z-index: 9;
    text-indent: 999999999px;
    transform: rotate(180deg);
}

.owl-prev {
    background: url(../images/sArrow.png) 50% 50% no-repeat !important;
    left: -33px;
    cursor: pointer;
    background-size: 100% !important;
    border-radius: 0 !important;
    top: 36%;
    height: 60px;
    width: 60px;
    position: absolute;
    z-index: 9;
    text-indent: 999999999px;
}

.owl-nav.disabled {
    display: block !important;
}

section.podcastsWrap {
    padding: 75px 0px;
    background: #0c0c0c;
}

.podcastsContent h4 {
    margin: 0;
}

.podcastsContent h4 a {
    display: inline-block;
    color: #734d9d;
    text-decoration: none !important;
    margin-top: 20px;
    margin-bottom: 0;
}

.podcastsContent h4 a:hover {
    color: #00e1ba;
}

.podcastsContent p {
    margin: 10px 0px 0px;
    font-size: 15px;
    line-height: 22px;
}

.podcastsContent span {
    font-size: 14px;
    color: #adadad;
}

.podcastsContent p a {
    color: #00e1ba;
}

.podcastsContent img {
    border-radius: 25px 25px 5px 5px;
}

.podcastsSearch {
    width: 310px;
    float: right;
    clear: both;
    margin-bottom: 30px;
    margin-top: 15px;
    position: relative;
}

.podcastsSearch .form-control {
    height: 45px;
    border-radius: 25px;
    border: none;
    padding: 0px 15px 0px 60px;
    font-size: 15px;
    /* color: #000; */
    box-shadow: none !important;
}

.podcastsSearch img {
    position: absolute;
    top: 10px;
    left: 13px;
}

.podcastsSearch img {
    position: absolute;
    top: 11px;
    left: 20px;
}


.tc_banner {
    position: relative;
    padding: 6rem 0;
    color: #fff;
}
.tcb_back {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}
.tcb_img {
    height: 100%;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top center !important;
}
.coachingWrap {
    padding: 50px 0px 30px;
}
.tcb_text {
    padding: 0 4rem;
}

/* For Business */
.sfy {
    padding: 4rem 0;
    background: url(../images/banner_bg.png) center center no-repeat;
    background-size: cover;
}
.hehb {
    position: relative;
}

.hehb_back {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
}
.hehb_back_left,
.hehb_back_right {
    height: 100%;    
}
.hehb_back_left {
    width: 41.666667%;
}
.hehb_back_right {
    width: 58.333333%;
    background-color: #f0f9f8;
}
.hehb_right {
    padding: 3rem 0 3rem 2rem;
}
.Hehb_icon {
    width: 70px;
    height: 70px;
    background-color: #f0f9f8;
    text-align: center;
    line-height: 70px;
    border-radius: 0.8rem;
    border: 2px solid #fff;
    box-shadow: 0px 10px 20px rgb(20 34 32 / 13%);
    margin-bottom: 0.8rem;
}
.bbi {
    padding: 4rem 0;
}
.behbCard h4 {
    font-size: 1.4rem;
    font-weight: 600;
}
.bbi_card {
    border-radius: 0.6rem;    
    padding: 1rem;
    box-shadow: 1px 20px 20px rgb(20 34 32 / 6%);
    margin-bottom: 1.8rem;
}
.bbi_card h2 {
    font-size: 2.5rem;
    font-weight: 600;
    margin: 0;
}
.bbi_card h2 sub {
    font-size: 1rem;
    position: relative;
    top: -2px;
}
.hs {
    background-color: #e1fffa;
    padding-top: 2rem;
}
.hs h4 {
    font-size: 1.4rem;
    line-height: 2;
}

.tot {
    background: url(./../images/tot_bg.jpg) center center no-repeat;
    background-size: cover;    
    padding: 4rem 0;
}
.b_partners {
    background-color: #F6F6F6;
    padding: 3rem 0;
}
.bp_card {
    text-align: center;
}
.b_partners .owl-carousel .owl-item img {
    text-align: center;
    display: inline-block;
    width: 158px !important;
    height: 158px !important;
    border-radius: 0.5rem;
    object-fit: contain;
    background-color: #fff;
    object-position: center;
}
/* For Business */



.CoachingItem {
    background: #232a2a;
    overflow: hidden;
    /* margin-bottom: 30px; */
    position: relative;
    text-align: center;
    height: 320px;
}

.CoachingItem h3 {
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 300;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.CoachingItem img {
    height: 320px;
    width: 100%;
    object-fit: cover;
    opacity: 0.5;
    transition: 0.4s;
    object-position: center;
}

.CoachingItem:hover img {
    opacity: 0.3;
    transition: 0.4s;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
}

.testimonialWrap {
    background: #2a3839 url(../images/tbg.png) no-repeat;
    background-position: left bottom;
    padding: 40px 0 70px;
    color: #f7f7f7;
}
.testimonialWrap .heading {
    color: #fff;
}
/* .aboutText {
    color: #f7f7f7;
} */
section.testimonialWrap .col-md-5 img {
    position: relative;
    margin-top: -40px;
}

.carousel-control-prev {
    left: 0;
    width: 40px;
    height: 40px;
    top: 50%;
    margin-top: -20px;
    z-index: 9;
    opacity: 1;
}

.carousel-control-next {
    right: 0;
    width: 40px;
    height: 40px;
    top: 50%;
    margin-top: -20px;
    z-index: 9;
    opacity: 1;
    transform: rotate(180deg);
}

.carousel-inner {
    overflow: inherit;
}

.newsletterWrap {
    background: url(../images/news.png) no-repeat center;
    padding: 75px 0px;
    background-size: cover;
    background-attachment: fixed;
    color: #f7f7f7;
}

.newsletterForm .form-control {
    background: #00000073;
    height: 54px;
    border-radius: 50px;
    padding: 0px 27px;
    text-align: center;
    color: #fff;
    box-shadow: none !important;
    outline: none !important;
    border: 1.5px solid #fff;
}

section.newsletterWrap button {
    padding: 10px 40px;
    margin-top: 10px;
}

.faqWrap {
    padding: 50px 0px;
    background: #e1fffa;
}

section.faqWrap h4 {
    font-size: 20px;
    margin: 20px 0;
}
.faqWrap .card {
    background: #ffffff;
    border-radius: 5px !important;
    margin-bottom: 15px;
    border: 0;
    box-shadow: 0 1px 2px rgb(31 46 44 / 11%);
    overflow: hidden;
}
.faqWrap .faq_head.card-header {
    background-color: unset;
    border-color: #f7f7f7;
}
.faqWrap button.btn.btn-link {
    width: 100%;
    text-align: left;
    color: #2a3839;
    font-weight: 300;
    padding: 16px 16px;
    border-radius: 5px !important;
    text-decoration: none;
}

section.faqWrap button.btn.btn-link i {
    float: right;
    font-size: 22px;
}
section.faqWrap button.btn.btn-link:focus{
    box-shadow: none;
}
.faqWrap .card p {
    margin: 0;
    line-height: 1.6;
}

.defaultBtn span {
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 30px;
    height: 18px;
}

.defaultBtn span svg {
    position: absolute;
    width: 20px;
    height: 18px;
    left: 0;
    top: 0;
}

.defaultBtn span svg:last-child {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0;
}

.defaultBtn:hover span svg:first-child {
    opacity: 0;
    -webkit-transform: translateX(150%);
    transform: translateX(150%);
}

.defaultBtn:hover span svg:last-child {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
}
.nav_wrap {
    background: #fff;
    transition: all .7s ease;
    top: -50px;
    box-shadow: 0px 10px 20px rgb(20 34 32 / 6%);
}
.nav_wrap.sticky {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;    
}
.nav_wrap .navbar-brand img {
    max-width: 100%;
    height: auto;
    width: 120px;
}

#button {
    display: inline-block;
    background-color: #ffffff;
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 12px;
    position: fixed;
    bottom: 20px;
    right: 30px;
    transition: background-color .3s, opacity .5s, visibility .5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
    box-shadow: 0px 0px 6px #29292961;
}

#button::after {
    content: "\f106";
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    font-size: 2em;
    line-height: 50px;
    color: #00e1ba;
}

#button:hover {
    cursor: pointer;
    background-color: #fff;
}

#button:active {
    background-color: #00e1ba;
}

#button.show {
    opacity: 1;
    visibility: visible;
}
.textEffect .text-wrapper {
    position: relative;
    display: inline-block;
    padding-top: 0.1em;
    padding-right: 0.05em;
    padding-bottom: 0.15em;
}


.SearchListWrap {
    background: #f7f7f7;
    padding: 50px 0px;
}

.searchFilter {
    background: #fff;
    padding: 15px 25px;
    border-radius: 15px;
}

.searchFilter h3 {
    color: #505050;
    font-size: 20px;
    margin: 5px 0px 15px 0px;
    border-bottom: 1px solid #d8d8d880;
    padding: 0px 0px 15px;
}

.searchFilter h3 img {
    position: relative;
    top: -6px;
    margin-right: 5px;
}

.dropdown.bootstrap-select {
    max-width: 100% !important;
    width: 100% !important;
    position: relative;
}

.searchFilter button.btn.dropdown-toggle {
    background: transparent;
    box-shadow: none;
    border: 1px solid #ccc !important;
    border-radius: 28px;
    height: 45px !important;
    line-height: 28px !important;
    font-size: 15px !important;
    padding: 0px 45px 0 15px !important;
    text-transform: capitalize !important;
    color: #727272 !important;
}

.searchFilter .dropdown-toggle::before,
.searchFilter .dropdown-toggle::after {
    display: none;
}

.searchFilter .dropdown-menu {
    width: 100%;
    max-width: 100% !important;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show>.btn-light.dropdown-toggle {
    color: #212529;
    background-color: transparent !important;
    border-color: transparent !important;
    outline: none !important;
}

.searchFilter .filter-option-inner-inner {
    display: block;
    line-height: 42px;
    text-transform: capitalize !important;
    padding: 0 !IMPORTANT;
}

.searchFilter .ratingWrap .star-ratings {display: flex !important; justify-content: center}

.filter-option {
    outline: none !important;
}

/* Rating Star Widgets Style */
.rating-stars {
    position: relative;
}

.rating-stars ul {
    list-style-type: none;
    padding: 0;
    position: relative;
    display: flex;
    justify-content: center;
    -moz-user-select: none;
    -webkit-user-select: none;

}

.rating-stars ul>li.star {
    display: inline-block;
    margin: 0px 5px;
    font-size: 14px;
    text-align: center;
    width: 75px;
    cursor: pointer;

}

.rating-stars ul>li.star>i.fa {
    font-size: 1.7em;
    color: #dfdfdf;
}

.rating-stars ul>li.star.hover>i.fa {
    color: #ffd616;
}

.rating-stars ul>li.star.selected>i.fa {
    color: #ffd616;
}

.selectBox {
    position: relative;
}

.searchFilter label {
    color: #878787;
    font-size: 15px;
    display: block;
    margin-bottom: 10px;
    font-weight: 500;
}
/* 
.selectBox>.form-control {
    height: 45px;
    border-radius: 30px;
    color: #727272;
    padding: 0px 45px 0 15px;
    font-size: 14px;
    text-transform: capitalize;
    box-shadow: none !important;
    border: 1px solid #ced4da !important;
    -webkit-appearance: none;
} */

.bootstrap-select .dropdown-toggle .filter-option-inner {
    padding-right: 0;
}

.closeMenu {
    display: none;
}

.selectBox>span {
    position: absolute;
    top: 9px;
    right: 20px;
}

.rating-stars {
    position: relative;
}

.text-message {
    position: absolute;
    right: 80px;
    top: 0;
    font-size: 18px;
    color: #00e1ba;
    font-weight: 600;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #00e1ba;
    background-color: #00e1ba !important;
}
.legend_box {
    display: inline-block;
    width: 0.8rem;
    height: 0.8rem;
    background-color: #dbe3e1;
    margin-bottom: -0.05rem;
    border-radius: 0.2rem;
}
.legend_box.available {
    background-color: #24dfbe;
}
.custom-control-input:not(:disabled):active~.custom-control-label::before {
    color: #fff;
    background-color: #00e1ba;
    border-color: #00e1ba;
    box-shadow: none !important;
}

.searchFilter .defaultBtn span {
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 40px;
    height: 17px;
}

.bs-searchbox .form-control {
    outline: none !important;
    box-shadow: none !important;
    border: none;
    border-radius: 25px;
    box-shadow: 0px 0px 3px #ccc !important;
}
.searchHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.searchHeading h3 {
    font-size: 20px;
    margin: 0;
    vertical-align: middle;
    color: #505050;
}

.searchHeading h3 img {
    position: relative;
    top: -4px;
    margin-right: 5px;
}
.searchShortBy select {
    color: #424242;
    padding: 5px;
    border-radius: 0.2rem;
    border-color: #e7e7e7;
    max-width: 250px;
}
.searchFilter .defaultBtn:hover {
    color: #65ad2a;
    background: #fff;
    transition: 0.3s all;
    box-shadow: 0px 0px 8px #ccc;
}

.searchPeopleList {
    background: #fff;
    /* margin-top: 30px; */
    padding: 25px 20px;
    border-radius: 25px;
    /* box-shadow: 0px 0px 5px #ccc; */
    text-align: center;
    position: relative;
    transition: 0.3s all;
    height: 100%;
}

.searchPeopleList:hover {
    box-shadow: 0px 0px 20px rgb(42 56 57 / 13%);
    transition: 0.3s all;
}

.sImgHolder {
    width: 140px;
    height: 140px;
    margin: 0 auto;
    position: relative;
    margin-bottom: 15px;
}

.sImgHolder>img.sUserImg {
    width: 140px;
    height: 140px;
    object-fit: cover;
    border-radius: 50%;
}

.sImgHolder>img.sUserImgPrivate {
    width: 127px;
    height: 135px;
    object-fit: cover;
    border-radius: 50%;
}

.sImgHolder img.sbi {
    position: absolute;
    bottom: -5px;
    right: 10px;
}

.searchPeopleList h4 {
    font-size: 22px;
    color: #00e1ba;
    margin-bottom: 0;
}

.searchPeopleList h5 {
    font-size: 15px;
    color: #a3a3a3;
    margin-bottom: 0;
    min-height: 18px;
}

.searchPeopleList h6 {
    font-size: 16px;
    margin: 0;
    line-height: 24px;
    font-weight: 400;
    color: #000;
}

.searchPeopleList p {
    font-size: 15px;
    line-height: 21px;
    min-height: 65px;
    max-height: 65px;
    overflow: hidden;
    margin-bottom: 60px;
}

.ratingView {
    color: #dfdfdf;
    font-size: 21px;
    margin: 5px 0px;
}

.ratingView .rActive {
    color: #ffd616;
}

.searchPeopleList .defaultBtn {
    /* box-shadow: 0px 0px 8px #ccc; */
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    width: 196px;
    bottom: 19px;
    border: 1px solid #00e1ba;
}

.searchPeopleList .defaultBtn:hover {
    text-decoration: none !important;
    background: #ffffff;
}

.defaultBtn.orange{
    background-color: orange !important;
    border-color : orange !important;
}

.defaultBtn.orange:hover{
    background-color: #fff !important;
    color : orange !important;
}

.searchPeopleList .defaultBtn img {
    position: relative;
    top: -2px;
    left: 0;
}
img.verified {
    position: absolute;
    left: 20px;
    top: 20px;
}

li.page-item a {
    border: none !important;
    background: transparent !important;
    padding: 10px 20px;
    color: #776969 !important;
    border-bottom: 1.5px solid #cbcbcb !important;
    border-radius: 0 !important;
    position: relative;
}

a.page-link.active {
    color: #00e1ba !important;
    font-size: bolder;

}

a.page-link.active,
li.page-item a:hover {
    color: #00e1ba !important;
    border-bottom-color: #00e1ba !important;
}

.floatButton {
    position: fixed;
    left: 0;
    bottom: -90px;
    width: 100%;
    background: rgb(11 11 11 / 96%);
    padding: 20px 20px;
    transition: 0.7s;
    z-index: 9;
}

.floatButton p {
    margin: 0;
}

.floatButton.show {
    bottom: 0px;
    transition: 0.7s;
}


/* registration css*/

.registrationWrap {
    padding: 100px 0px;
    background: #f5f5f5 url(../images/searchBg.png) no-repeat;
    background-size: contain;
    background-position: left top;
    ;
}

.registrationWrapNew.registrationWrap {
    padding: 100px 0px;
    padding : 68px 120px 100px 135px;
    background: #f5f5f5 url(../images/registrationBackgroundImage.jpg) no-repeat;
    background-size: auto;
    background-attachment: fixed;
}
.hoverClass a:hover{
    color : #00e1ba !important;
}
.hoverClass a{
    color : #000 !important;
}

.registrationContent {
    background: rgb(255 255 255 / 0.95);
    border-radius: 15px;
    box-shadow: 0px 0px 14px rgb(42 56 57 / 12%);
    overflow: hidden;
}
.registrationWrapNew .registrationContent {
    border: 4px solid #fff !important;
}

.registrationForm {
    padding: 35px 20px 25px 20px;
    color: #000;
}

.otherRegistration {
    background: url(../images/Highway-1920x1280.jpg) no-repeat;
    background-size: cover;
    padding: 0px 0px;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.registrationWrapNew .otherRegistration {
    background: url(../images/laptop-girl.jpg) no-repeat;
    background-size: cover;
    padding: 0px 0px;
    background-position: left;
    display: flex;
    align-items: center;
    justify-content: center;
}
.otherRegistrationFilds {
    padding: 40px;
}
.registrationForm label.custom-control-label a,
.loginLink a {
    color: #00e1ba;
}

.registrationForm h3 {
    font-size: 25px;
    text-align: center;
    text-transform: uppercase;
}
.registrationWrapNew .registrationForm h3 {
    font-size: 25px;
    text-align: left;
    text-transform: capitalize;
}

.custom_input_box {
    background-color: #00e1ba;
    border-radius: 10px;
    border: 1px solid #000;
    margin-top: 0.5rem;
}

.custom_captcha iframe {
    border-radius: 14px;
    border: 1px solid #2f2f2f;
}


.custom_google_btn button {
    display: inline-block !important;
    border-radius: 10px !important;
    color: rgb(47, 47, 47) !important;
    font-size: 13px !important;
    margin: 0px !important;
    width: calc(100% - 2px) !important;
    padding: 0px 6px !important;
    height: 40px !important;
    border: 1px solid #000 !important;
    box-shadow: none !important;
    margin-left : 1rem !important;
}


.custom_input_box::placeholder {
    color: #000;
    opacity: 1; /* Firefox */
  }

.otherRegistrationFilds h3 {
    font-size: 25px;
    text-align: center;
    text-transform: uppercase;
}
.otherRegistrationFilds span.R_divider {
    display: block;
    width: 100px;
    background: #000;
    height: 1px;
    margin: 20px auto 30px;
    position: relative;
}
.sc_input {
    position: relative;
}
.registrationForm .form-group .form-label {
    line-height: 1;
    margin-bottom: 0;
    font-weight: 600;
    font-size: .9rem;
    color: #3f454a;
}
.custom-control {
    z-index: 0;
}
.sci_remove {
    position: absolute;
    right: 1px;
    top: 1px;
    height: calc(1.5em + 0.75rem );
    line-height: calc(1.5em + 0.75rem );
    padding: 0 0.5rem;
    cursor: pointer;
    background-color: #fff;
    border-radius: 0.5rem;
}
.registrationForm select.cCode {
    -webkit-appearance: none;
    width: 75px;
    height: 45px;
    border-left: 0;
    border-right: 0;
    border-color: #e0e0e0;
    padding: 0px 19px 0px 8px;
    color: #9c9c9c;
    font-size: 15px;
    box-shadow: none;
    outline: none;
}
.registrationForm .selectBox>span {
    position: absolute;
    top: 9px;
    right: 4px;
}
.registrationForm span.input-group-text {
    background: transparent;
    width: 45px;
    color: #798a97;
    text-align: center;
    font-size: 16px;
    display: inline-block;
    line-height: 40px;
    padding: 0;
}
.otherRegistrationFilds a {
    display: block;
    text-align: center;
    width: 260px;
    margin: 0 auto;
    background: #fff;
    margin-bottom: 12px;
    padding: 10px 0px;
    border-radius: 25px;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    box-shadow: 0px 0px 8px rgb(82 82 82 / 72%);
    transition: 0.3s all;
}

.otherRegistrationFilds a:hover {
    transition: 0.3s all;
    -webkit-transform: scale(1.08);
    -moz-transform: scale(1.08);
    -ms-transform: scale(1.08);
    -o-transform: scale(1.08);
    transform: scale(1.08);
}

.otherRegistrationFilds a.gcn {
    background: #9C7E39 !important;
}

.otherRegistrationFilds a.facebook {
    background: #3b5998 !important;
}

.otherRegistrationFilds a.twitter {
    background: #0e76a8 !important;
}

.otherRegistrationFilds a.google {
    background: #db3236 !important;
}
.registrationForm.loginForm {
    padding: 80px 40px;
}

/*for coaches css*/
.chooseWrap {
    padding: 60px 0px;
    background-color: #ccf9f1;
    background: url(../images/banner_bg.png) center center no-repeat;
    background-size: cover;
}
.chooseVideo {
    border-radius: 10px;
    object-fit: cover;
    box-shadow: 0px 19px 50px rgb(9 46 40 / 35%);
    background-color: #fff;
    overflow: hidden;
}
.chooseVideo iframe {
    display: block;
}
.featuresWrap {
    background: #ccf9f1 url(../images/bBg.png) no-repeat;
    background-position: left;
    background-size: contain;
    padding: 50px 0px;
}

.benefitsContent {
    background: #fff;
    color: #262626;
    padding: 0px 0px;
    border-radius: 15px;    
    min-height: 340px;
}
.benefitsContent:not(:last-child) {
    margin-bottom: 75px;
}

.benefitsContent img.img-fluid {
    position: relative;
    transform: scale(1.1);
    border-radius: 25px;
    box-shadow: 0px 0px 8px #000000d9;
}

.benefitsContent.benefitsContentLeft img {
    left: -10px;
    top: 45px;
    transition: 0.3s all;
}

.benefitsContent.benefitsContentRight img {
    right: -10px;
    top: 45px;
    transition: 0.3s all;
}

.benefitsContent.benefitsContentLeft:hover img {
    left: -20px;
    top: 60px;
    transition: 0.3s all;
}

.benefitsContent.benefitsContentRight:hover img {
    right: -20px;
    top: 60px;
    transition: 0.3s all;
}

.benefitsContent h3 {
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 400;
    margin-top: 50px;
}

.benefitsContent span.R_divider {
    margin: 15px 0px 20px;
}

.benefitsContent ul {
    padding: 0;
    list-style: none;
    margin-bottom: 25px;
    padding-right: 20px;
}

.benefitsContent ul li img {
    margin-right: 8px;
    margin-top: 7px;
}

.benefitsContent ul li {
    display: flex;
    align-items: flex-start;
    font-size: 16px;
    line-height: 28px;
}

.benefitsContent ul li span {
    font-size: 14px;
    line-height: 20px;
    color: #6d6d6d;
    display: inline-block;
}

.contactWrap {
    padding: 80px 0px;
    background-color: #f7fffe;
    background: url(../images/banner_bg.png) center center no-repeat;
    background-size: cover;
}
.contact_card {
    background-color: #fff;
    box-shadow: 0px 19px 50px rgb(9 46 40 / 7%);
    border-radius: 10px;
    overflow: hidden;
}
.contactWrap iframe {
    width: 100%;
    display: block;
}
.contactWrap .card {
    box-shadow: 20px 19px 50px rgb(9 46 40 / 9%);
}
.cn_form {
    padding: 3rem;
}
.cnci_item {
    padding: 1rem;
    background-color: #f6f9f8;
    border-radius: 0.6rem;
}
.row.cnc_info {
    margin-bottom: 2rem;
}
.cnci_item > h4 {
    font-size: 1rem;
}
.contactWrap .form-control:not(textarea),
.contactWrap .react-tel-input .form-control {
    height: 45px;
}
.contactWrap .react-tel-input .form-control {
    width: 100%;
}
.contactWrap .defaultBtn,
.priceWrap .defaultBtn {
    padding: 10px 45px;
}
.priceWrap {
    margin: 150px 0px 75px;
}
.priceHolder span.R_divider {
    margin: 15px 0px 20px;
}

.priceHeading {
    list-style: none;
    background: #00e1ba;
    margin: 0px 20px;
    display: flex;
    font-size: 18px;
    padding: 15px 30px;
    border-radius: 36px;
    text-align: center;
    align-items: center;
}

.priceHeading li,
.priceItem li {
    width: 16%;
    flex: 16% 0;
}

ul.priceItem li:nth-child(1),
ul.priceHeading li:nth-child(1) {
    width: 68%;
    flex: 68% 0;
}

.priceItem {
    margin: 0px 20px;
    display: flex;
    font-size: 15px;
    color: #000000;
    padding: 13px 30px;
    list-style: none;
    border-bottom: 1px solid #cccccc69;
    align-items: center;
}

.priceItem li img {
    display: block;
    margin: 0 auto;
}

/* ADD CURSOR */
.txt-type>.txt {
    border-right: 0.08rem solid #fff;
    padding-right: 2px;
    /* Animating the cursor */
    animation: blink 0.6s infinite;
}

/* ANIMATION */
@keyframes blink {
    0% {
        border-right: 0.08rem solid rgba(255, 255, 255, 1);
    }

    100% {
        border-right: 0.08rem solid rgba(255, 255, 255, 0.2);
    }
}


/*match my coach css*/

section.questionsWrap {
    padding: 75px 175px;
    background: #151515 url(../images/searchBg.png) center center;
    background-size: cover;
    color: #f7f7f7;
}

/*form styles*/
.questionsForm {
    margin: 10px auto;
    text-align: center;
    position: relative;
    max-width: 750px;
    min-height: 65px;
}

.questionsForm fieldset {
    background: white;
    border: 0 none;
    border-radius: 15px;
    padding: 25px 30px;
    box-sizing: border-box;
    width: 80%;
    position: relative;
    margin: 0 10%;
    box-shadow: 0px 0px 6px #000;

}

/*Hide all except first fieldset*/
.questionsForm fieldset:not(:first-of-type) {
    display: none;
}

/*inputs*/
.questionsForm input,
.questionsForm textarea {
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
    color: #2C3E50;
    font-size: 14px;
    outline: none !important;
}

/*buttons*/
.questionsForm .action-button {
    width: 100px;
    background: #316805;
    color: white;
    border: 0 none;
    border-radius: 1px;
    cursor: pointer;
    padding: 10px 5px;
    margin: 10px 5px;
    border-radius: 25px;
    outline: none !important;
    text-transform: uppercase;
    box-shadow: 0px 0px 8px #ccc;
    transition: 0.3s all;
}

.questionsForm input.submit.action-button {
    width: 155px;
}

.questionsForm .action-button:hover,
.questionsForm .action-button:focus {
    box-shadow: 0 0 0 2px white, 0 0 0 3px #00e1ba;
    transition: 0.3s all;
}

/*progressbar*/
#questionsProgressbar {
    overflow: hidden;
    counter-reset: step;
    padding: 0;
    text-align: center;
    margin: 0 auto 40px;
}

#questionsProgressbar li {
    list-style-type: none;
    color: white;
    text-transform: uppercase;
    font-size: 11px;
    width: 11%;
    float: left;
    position: relative;
}

#questionsProgressbar li:before {
    content: counter(step);
    counter-increment: step;
    width: 40px;
    line-height: 40px;
    display: block;
    font-size: 23px;
    color: #00e1ba;
    background: white;
    border-radius: 14px;
    margin: 0 auto 5px auto;
    position: relative;
    z-index: 1;
}

/*progressbar connectors*/
#questionsProgressbar li:after {
    content: '';
    width: 100%;
    height: 2px;
    background: white;
    position: absolute;
    left: -50%;
    top: 20px;
    z-index: 0;
}

#questionsProgressbar li:first-child:after {
    content: none;
}

/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#questionsProgressbar li.active:before,
#questionsProgressbar li.active:after {
    background: #00e1ba;
    color: white;
}

.questionsForm fieldset .fs-subtitle {
    color: #212529;
    text-transform: none;
    font-size: 20px;
}

.questionsForm fieldset .R_divider {
    background: #ccc;
}

.questionsWrap .heading p {
    font-weight: 300;
}

.info {
    font-size: 13px;
    color: #fd5353;
}

.questionsWrap .form-control {
    min-height: 50px;
    border-left: 0;
    border: 1px solid #e0e0e0;
    border-radius: 25px;
    font-size: 15px;
    color: #616161;
    outline: none;
    box-shadow: none !important;
    padding: 10px 20px;
}

.areas {
    color: #717171;
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: none;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #00e1ba;
}

.requiredSkills.searchFilter {
    background: no-repeat;
    box-shadow: none;
    padding: 0;
    margin-bottom: 30px;
    margin-right: 0;
}

.questionsForm .text-message {
    position: absolute;
    right: 15px;
}


/* coach profile section */

.coachImg {
    position: relative;
}
.coachImg>img {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 50%;
    object-position: center;
}
.coachsProfileWrap {
    padding: 60px 0;
    background-color: #f5f7f7;
}
.userDashboardLeftPart {
    margin-top: 2.5rem;
}

.coachInfoWrap ul {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.coachImg .picEdit {
    position: absolute;
    right: 15px;
    bottom: 15px;
    transition: 0.3s all;
}
.cProfileEdit a {
    position: absolute;
    top: 0;
    right: 20px;
    opacity: 0;
    transition: 0.3s all;
}

.coachInfoWrap:hover a {
    visibility: visible;
    opacity: 1;
    transition: 0.3s all;
}

.cProfileEdit a:hover {
    transform: scale(1.08);
    transition: 0.3s all;
    display: inline-block;
}

.coachInfo {
    display: flex;
    flex-wrap: wrap;
}

.cDetails {
    flex: 70% 0;
    width: 70%;
}

.coachBook {
    flex: 30% 0;
}

.coachInfo p {
    flex: 100%;
}

.cDetails h3 {
    font-size: 25px;
    margin: 0;
    margin-bottom: 10px;
    font-weight: 600;
}
.cDetails h3 img {
    position: relative;
    top: -6px;
}

.cDetails h4 {
    font-size: 18px;
    margin: 5px 0px 15px;
    color: #757575;
}

.cDetails h5 span {
    font-size: 14px;
    background: #fff;
    display: inline-block;
    color: #384b4c;
    padding: 5px 10px;
    border-radius: 3px;
}

.coachInfo p {
    color: #5a5a5a;
    line-height: 19px;
    padding: 0;
    font-size: 15px;
    border-radius: 5px;
    margin-top: 5px;
}

.coachInfoWrap ul li {
    width: 20%;
    text-align: center;
}

.coachInfoWrap ul.coach_public_tabs li {
    width: 20%;
    text-align: center;
}

.coachInfoWrap ul li a {
    color: #fff;
    font-size: 14px;
    background: #000;
    display: block;
    padding: 15px 0px 15px;
    text-decoration: none;
    transition: 0.3s all;
    position: relative;
}
.coachInfoWrap ul li.active a,
.coachInfoWrap ul li a:hover {
    background: #fff;
    color: #fff;
}

.coachInfoWrap ul li a i {
    display: inline-block;
    margin-right: 5px;
}

.coachProfileVideo>img {
    height: 400px;
    width: 100%;
    object-fit: cover;
    border-radius: 25px;
    position: relative;
    z-index: 1;
}

.coachProfileVideo a {
    position: absolute;
    top: 20px;
    right: 80px;
    z-index: 1;
    transition: 0.3s all;
}

.coachProfileVideo a:hover {
    transform: scale(1.08);
    transition: 0.3s all;
    display: inline-block;
}

.packagesWrap {
    background: #f7f7f7;
    padding: 50px 0 0;
}

.coachTag h3 {
    font-size: 28px;
    align-items: center;
    margin-top: 20px;
    text-transform: capitalize;
}

.coachTag h3 a {
    display: block;
    background: #fff;
    padding: 8px 12px;
    border-radius: 20px 20px 5px 20px;
    transition: 0.3s all;
    position: absolute;
    right: 0;
    top: 55px;
    visibility: hidden;
    opacity: 0;
}
.coachTag:hover h3 a {
    transition: 0.2s all;
    visibility: visible;
    opacity: 1;
}
.coachTag a img {
    transition: 0.2s all;
}
.coachTag a:hover img {
    transform: scale(1.09);
    transition: 0.2s all;
    display: inline-block;
}
.coachTag h3 a img {
    position: relative;
    top: -1px;
}
.coachTagItem span {
    display: inline-block;
    color: #00e1ba;
    font-size: 12px;
    border-radius: 24px;
    margin: 0 3px;
    transition: 0.2s all;
}
.coachTagItem span:hover {
    transform: scale(1.09);
    transition: 0.2s all;
    display: inline-block;
}
.coachTag {
    padding: 80px 0px 20px;
    
}
.coachTag .coachTagItem {
    position: relative;
}
.coachTag .coachTagItem .cProfileEdit {
    position: absolute;
    top: -50px;
    width: 100%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
}
.coachTagItem .addTag {
    display: block;
    background: #fff;
    padding: 8px 12px;
    border-radius: 20px 20px 5px 20px;
    transition: 0.3s all;
} 
.coachProfileVideo {
    border-radius: 25px;
    overflow: hidden;
    box-shadow: 0px 0px 8px #000000d9;
    margin: 0px 50px;
    background: #000;
    position: relative;
}

section.coachWrap {
    position: relative;
    background: #f5f5f5;
}

section.coachWrap::after {
    content: "";
    position: absolute;
    left: 0;
    top: 100px;
    height: 1px;
    width: 100%;
    z-index: 0;
}

.packageList {
    display: flex;
    background: #fff;
    border-radius: 10px;
    align-items: center;
    position: relative;
    transition: 0.2s all;
}

.packageInfo {
    flex: 75% 0;
    position: relative;
    z-index: 1;
    padding: 10px 15px;
    overflow: hidden;
    border-top-left-radius: 11px;
    border-bottom-left-radius: 11px
}

.subPackage .packageInfo {
    flex: 70% 0;
    position: relative;
    z-index: 1;
    padding: 15px;
    overflow: hidden;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.packageInfo h3 span {
    color: #734d9d;
    font-weight: 400;
    font-size: 20px;
}
.packageInfo p {
    color: #384b4c;
}

.packageDetails a {
    color: #00e1ba;
    display: inline-block;
    font-size: 15px;
    border-radius: 28px;
}

.packageDetails .collapse {
    font-size: 15px;
    color: #464646;
}

.packageActon h4 {
    font-weight: bold;
    /* color: #734d9d; */
    font-size: 26px;
    margin-bottom: 10px;
}

.packageInfo h3 {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 600;
}

.packageActon {
    flex: 30% 0;
    text-align: center;
    border-left: 1px solid #dfeced;
}

.coachInfoWrap ul li.active a::before,
.coachInfoWrap ul li a:hover::before {
    content: "";
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    position: absolute;
    top: auto;
    left: 50%;
    margin-left: -10px;
    opacity: 1;
    transition: all .3s ease;
    border-color: inherit transparent transparent transparent;
    bottom: -10px;
}
.packageList .packageInfo::after {
    content: "";
    background: #ccf9f1;
    width: 500px;
    height: 400px;
    position: absolute;
    left: -70px;
    bottom: -210px;
    z-index: -1;
    transform: rotate(404deg);
    border-radius: 175px;
}

.packageList:hover {
    box-shadow: 0px 0px 19px rgb(42 56 57 / 12%);
    transition: 0.2s all;
}

.feedbackContent {
    background: #fff;
    text-align: center;
    padding: 20px 30px;
    border-radius: 25px;
    /* box-shadow: 0px 0px 8px #00000017; */
}

.fRating {
    font-size: 22px;
    color: #ccc;
}

.fRating i.active {
    color: #ffd616;
}

.feedbackContent p {
    font-size: 15px;
    color: #5a5a5a;
    min-height: 80px;
}

.feedbackContent a {
    color: #81c14e;
}

.fUser {
    display: flex;
    align-items: center;
    justify-content: center;
}

.fUser img {
    width: 50px !important;
    height: 50px;
    border-radius: 13px;
    margin-right: 10px;
    align-items: center;

}

.fUser h4 {
    text-align: left;
    font-size: 18px;
    border-left: 1px solid #ccc;
    padding-left: 10px;
    margin: 0;
    color: #5a5a5a;
}

.fUser h4 span {
    display: block;
    font-size: 13px;
}

section.feedbackWrap {
    padding-bottom: 70px;
    background: #f5f5f5;
}
button.close {
    position: relative;
    z-index: 9;
    opacity: 1
}

.modal .form-control,
.modal .custom-file-label {
    border: 1.5px solid #dedede;
}
.modal .custom-file-label::after,
.modal .custom-file-label,
.modal .custom-file-input,
.modal .form-control {
    height: 45px;
    
}
.modal .custom-file-label::after,
.modal .custom-file-label  {
    line-height: 2;
}
/* Chrome, Safari, Edge, Opera */
.modal .form-control::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
/* .modal .form-control[type=number] {
    -moz-appearance: textfield;
} */

.cModal .modal-content,
.cModal .modal-header,
.cModal .modal-footer {
    border: 0;
}



/* CAUTION: IE hackery ahead */
.modal select::-ms-expand {
    display: none;
    /* remove default arrow on ie10 and ie11 */
}

/* target Internet Explorer 9 to undo the custom arrow */
@media screen and (min-width:0\0) {
    .modal select {
        background: none\9;
    }
}
.modal .searchFilter {
    background: transparent;
    box-shadow: none;
    padding: 0;
    border-radius: 0;
    margin-right: 0;
}

.modal .searchFilter button.btn.dropdown-toggle {
    background: #ffffff61;
    box-shadow: none;
    border: 1.5px solid #dedede !important;
    color: #ffffff !important;
}

span.input-group-text i {
    position: relative;
    top: 2px;
}

.feedback .item {
    padding: 15px;
}
.feedbackWrap .heading p,
.packagesWrap .heading p {
    color: #5a5a5a;
}
.contactDetails p {
    display: flex;
    align-items: center;
    vertical-align: middle;
    margin-top: 0;
    margin-bottom: 10px;
}

.contactDetails p img {
    margin-right: 7px;
}



.coachInfoWrap ul div li:nth-child(1) a {
    background-color: #1bbc9b;
}

.coachInfoWrap ul div li:nth-child(1) a:before {
    border-color: #18a588 transparent transparent transparent;
}

.coachInfoWrap ul div li:nth-child(2) a {
    background-color: #3498db;
}

.coachInfoWrap ul div li:nth-child(2) a:before {
    border-color: #2e86c0 transparent transparent transparent;
}

.coachInfoWrap ul div li:nth-child(3) a {
    background-color: #9b59b6;
}

.coachInfoWrap ul div li:nth-child(3) a:before {
    border-color: #213C53 transparent transparent transparent;
}

.coachInfoWrap ul li:nth-child(9n+1) a {
    background-color: #213C53;
}

.coachInfoWrap ul li:nth-child(9n+1) a:before {
    border-color: #213C53 transparent transparent transparent;
}

.coachInfoWrap ul li:nth-child(9n+2) a {
    background-color: #00E1BA;
}

.coachInfoWrap ul li:nth-child(9n+2) a:before {
    border-color: #00E1BA transparent transparent transparent;
}

.tour-profile{
    background : #00E1BA
}
.coachInfoWrap ul div li a {
    background: none !important;
    border-bottom: 1px solid;
}

.ant-drawer-title {
    font-size: 15px !important;
}
.ant-drawer-close {
    padding: 10px !important;
}
.ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-body {
    font-size: 12px !important;
}
.coachInfoWrap ul div li a:hover {
    background: #213C53 !important;
}
a.tour-mySchedule:hover {
    background: #213C53 !important;
}


.coachInfoWrap ul li:nth-child(9n+3) a {
    background-color: #e74c3c;
}

.coachInfoWrap ul li:nth-child(9n+3) a:before {
    border-color: #cb4335 transparent transparent transparent;
}

.coachInfoWrap ul li:nth-child(9n+4) a {
    background-color: #d9a81d;
}

.coachInfoWrap ul li:nth-child(9n+4) a:before {
    border-color: #bf9419 transparent transparent transparent;
}


.coachInfoWrap ul li:nth-child(9n+5) a {
    background-color: #99d865;
}

.coachInfoWrap ul li:nth-child(9n+5) a:before {
    border-color: #6eaa3c transparent transparent transparent;
}

.coachInfoWrap ul li:nth-child(9n+6) a {
    background-color: #ffad16;
}

.coachInfoWrap ul li:nth-child(9n+6) a:before {
    border-color: #c28007 transparent transparent transparent;
}

.coachInfoWrap ul li:nth-child(9n+7) a {
    background-color: #ff068fde;
}
.coachInfoWrap ul li:nth-child(9n+7) a:before {
    border-color: #c90872de transparent transparent transparent;
}

.coachInfoWrap ul li:nth-child(9n+8) a {
    background-color: #2dc5c5;
}
.coachInfoWrap ul li:nth-child(9n+8) a:before {
    border-color: #119999 transparent transparent transparent;
}

.coachInfoWrap ul li:nth-child(9n+9) a {
    background-color: #06c6ffc7;
}
.coachInfoWrap ul li:nth-child(9n+9) a:before {
    border-color: #059ac7c7 transparent transparent transparent;
}



.coachInfoWrap ul li a:before {
    content: "";
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    opacity: 0;
    transition: all .3s ease;
}

.coachInfoWrap ul li a:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.12);
    z-index: 0;
    transform: scaleY(0);
    transition: all .3s ease;
    transform-origin: top;
}

.coachInfoWrap ul li a:hover::after,
.coachInfoWrap ul li a.active::after {
    transform: scaleY(1);
}




.coachTagItem span:nth-child(1n - 1) {
    font-size: 15px;
    color: #cb4335
}

.coachTagItem span:nth-child(2n - 1) {
    font-size: 18px;
    color: #1bbc9b;
}

.coachTagItem span:nth-child(4n - 1) {
    font-size: 20px;
    color: #00E1BA;
}

.coachTagItem span:nth-child(4n - 1) {
    font-size: 23px;
    color: #00E1BA;
}

.coachTagItem span:nth-child(8n - 1) {
    font-size: 24px;
    color: #213C53;
}

.coachTagItem span:nth-child(6n - 1) {
    color: #d9a81d;
    font-size: 22px;
}

/* dropdown design*/
#nname {
    padding: 10px;
    color: white;
    font-size: 16px;
    /* background: transparent; */
    -webkit-appearance: none;

}

#nname option {
    background-color: silver;
}

.my-linkedin-button-class {
    width: 190px;
    height: 42px;
    padding-left: "10px";
    padding-right: "10px";
    /* border: none; */
    border-radius: 2px;
    color: rgb(255, 255, 255);
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    transition: 0.2s ease;
    cursor: pointer;
    background-color: rgb(21, 122, 168);
}

.my-facebook-button-class {
    /* width: 190px;
    height: 42px;
    padding-left: "10px";
    padding-right: "10px";
    border: none;
    border-radius: 25px;
    color: rgb(255, 255, 255);
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    transition: 0.2s ease;
    cursor: pointer;
    background-color: rgb(21, 68, 168); */
    width: 260px;
    background: #3b5998;
    margin-bottom: 12px;
    padding: 10px 0px;
    border-radius: 25px;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    transition: all .2s ease-in-out;
    color: #fff;
    cursor: pointer;
    border: none;
    font-size: 14px;
}

.packagedropdownbutton {
    line-height: 9px;
    width: 40px;
    font-size: 8pt;
    font-family: tahoma;
    margin-top: 6px;
    margin-right: 20px;
    position: absolute;
    top: 0;
    right: 0;
}

/* modal pakage */
.modal__cs .modal-content {
    border: 15px solid #eee;
    border-radius: 0;
    padding: 15px;
}

.modal-close .close {
    width: 44px;
    height: 44px;
    position: absolute;
    right: 0px;
    top: 0px;
    text-decoration: none;
    text-align: center;
    padding: 10px;
    font-style: normal;
    font-size: 28px;
    outline: none;
    background-color: #eee;
    opacity: 1;
    line-height: 20px;
    color: #333333;
    -webkit-appearance: none;
    font-weight: normal;
}

.confirmation .modal-content {
    border-radius: 0;
    border: 0;
    padding: 30px 30px 30px 30px;
}

.alert__icon svg {
    width: 70px;
    margin: auto;
    display: block;
}

.confirmation .modal-content ul {
    padding: 20px;
    list-style: none;
    margin: 0 -2px;
    display: flex;
}

.confirmation .modal-content ul li {
    flex: 0 0 70%;
    max-width: 100%;
}

.confirmation .modal-content ul li a {
    text-align: center;
    display: block;
    padding: 10px;
    transition: all 0.3s ease;
    text-decoration: none;
    text-transform: uppercase;
    background-color: #f5f5f5;
}

.confirmation .modal-content ul li.alert__cancel a {
    color: #f37878;
}

.confirmation .modal-content ul li.alert__confirm a {
    color: #63bb44;
}

.confirmation .modal-content ul li.alert__confirm a:hover {
    background-color: #82d266;
    color: #fff;
}

.confirmation .modal-content ul li.alert__cancel a:hover {
    background-color: #f37878;
    color: #fff;
}

.alert__icon {
    margin-bottom: 1em;
}

.confirmation .modal-content p {
    text-align: center;
    font-size: 20px;
    margin-bottom: 3em;
    line-height: 1.4;
}

.freeTrial {
    /* height: 400px;
    width: 800px; */
    /* background: red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.freeTrialDays {
    /* height: 400px;
    width: 800px; */
    /* background: red; */
    /* display: flex; */
    flex-direction: column;
    justify-content: right;
    text-align: left;
    z-index: 1;
    position: absolute;
    padding-bottom: 235px;
    top: 10px;
    left: 10px
}



.btn-circle.btn-xl {
    width: 100px;
    height: 100px;
    padding: 10px 16px;
    border-radius: 50px;
    font-size: 12px;
    text-align: center;
}

/* bio and resources */
section.biokWrap h3 {
    color: #734d9d;
}

/* blog css */
section.blogWrap.coachBlog {
    background: #f5f5f5;
    padding: 0px 0px 70px;
}

section.blogWrap.coachBlog h3,
section.biokWrap h3 {
    color: #734d9d;
}

section.blogWrap.coachBlog span.R_divider,
section.biokWrap span.R_divider {
    background: #ccc;
}

section.blogWrap.coachBlog .blogContent {
    box-shadow: 0px 0px 10px rgb(0 0 0 / 13%);
}

section.biokWrap {
    background: #f5f5f5;
    padding-bottom: 50px;
}

.bioDetails {
    background: #fff;
    color: #868686;
    text-align: left;
    border-radius: 10px;
    padding: 35px;
    margin-top: 6rem;
    position: relative;
    box-shadow: 0px 0px 7px rgb(179 179 179 / 8%);
}

.tooltip {
    font-family: inherit !important;
    border-radius: 0 !important;
}

.bioDetails p {
    margin: 0;
}

.cEditBio a {
    position: absolute;
    top: -10px;
    right: -10px;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s all;
}

.biokWrap:hover a {
    visibility: visible;
    opacity: 1;
    transition: 0.3s all;
}

.cEditBio a:hover {
    transform: scale(1.08);
    transition: 0.3s all;
    display: inline-block;
}

.ekko-lightbox-nav-overlay a {
    color: goldenrod;
}

.lightBox img {
    border-radius: 5px;
    max-height: 200px;
    width: 100%;
    object-fit: contain;
    transition: 0.3s all;
}

.ekko-lightbox button.close {
    position: absolute;
    right: 25px;
    top: 25px;
}

.ekko-lightbox h4.modal-title {
    display: none;
}

.ekko-lightbox-item img {
    border-radius: 10px;
}

a.removeLightbox img {
    width: 20px;
    height: 20px;
}

a.removeLightbox {
    position: absolute;
    top: 10px;
    left: 25px;
    background: #ffffffad;
    padding: 7px;
    border-radius: 3px;
    display: flex;
    text-decoration: none;
    z-index: 91;
}

.addCertificates {
    display: block;
    background: #fff;
    padding: 8px 12px;
    border-radius: 20px 20px 5px 20px;
    transition: 0.3s all;
    position: absolute;
    right: 15px;
    top: 50px;
    visibility: hidden;
    opacity: 0;
}

.biokWrap:hover .addCertificates {
    transition: 0.2s all;
    visibility: visible;
    opacity: 1;
}

.addCertificates a img {
    transition: 0.2s all;
}

.addCertificates a:hover img {
    transform: scale(1.09);
    transition: 0.2s all;
    display: inline-block;
}

.container {
    position: relative;
}

.lightBox a.boxWrap>img {
    transition: 0.3s all;
}

.lightBox a.boxWrap:hover>img {
    transition: 0.3s all;
    box-shadow: 0px 0px 8px #5a5a5a7a;
}

section.contactWrap.profileContact {
    margin: 0 0 0px;
    background: #f5f5f5;
    padding-bottom: 60px;
}

.avatar {
    width: 15em;
    height: 12em;
    margin: .5em auto;
    position: relative;
    padding: 15px;
    border: 1.5px dashed #c1c1c1;
    border-radius: 23px;
    padding-top: 25px;
    margin-bottom: 15px;
}

.avatar input {
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    top: 0;
    left: 0;
    z-index: 1;
    position: absolute;
}

.avatar p {
    font-size: 18px;
    margin-top: 10px;
}

img.contact-icon {
    width: 65%;
    margin: 0 auto;
    display: block;
    border-right: 1px solid #00e1ba;
    padding-right: 70px;
}

section.resourceWrap {
    background: #f5f5f5;
    padding-bottom: 35px;
}

.resourceWrap .avatar {
    width: 100%;
    height: 14em;
    margin: .5em auto;
    position: relative;
    padding: 15px;
    border: 1.5px dashed #734d9d;
    border-radius: 23px;
    padding-top: 31px;
    margin-bottom: 15px;
    background: #fff;
}

section.resourceWrap .avatar p {
    font-size: 20px;
    margin-top: 10px;
    color: #9b59b6;
}

section.resourceWrap .avatar p span {
    display: block;
    font-size: 13px;
    color: #1d1d1dcc;
}

.lightBox .dropdown-toggle img {
    width: 25px;
    height: 25px;
    transition: 0.2s all;
}

.lightBox .dropdown-toggle:hover img {
    transition: 0.2s all;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
}

.lightBox .col-md-4 {
    position: relative;
}

.lightBox .dropdown {
    position: absolute;
    top: 10px;
    right: 25px;
}

.lightBox .dropdown-toggle::after {
    display: none;
}

.lightBox .dropdown-menu {
    margin-top: 7px;
}

.cDetails h5 a {
    font-size: 16px;
    display: inline-block;
    color: #00e1ba;
    padding: 6px 8px 4px;
    background: #fff;
    line-height: 6px;
    border-radius: 2px;
    position: relative;
    top: 3px;
}

.cDetails h5 a:hover {
    background: #00e1ba;
    color: #fff;
}

.ekko-lightbox-nav-overlay a {
    color: #77c13b !important
}

.profilePhone select.cCode {
    -webkit-appearance: none;
    width: 80px;
    height: 45px;
    border-left: 1px solid;
    border-right: 0;
    border-color: #e0e0e0;
    padding: 0px 19px 0px 15px;
    color: #9c9c9c;
    font-size: 15px;
    box-shadow: none;
    outline: none;
    border-radius: 25px 0px 0px 25px;
}

.profilePhone input.form-control {
    border-radius: 0px 25px 25px 0px;
}

.profilePhone .selectBox>span {
    position: absolute;
    top: 9px;
    right: 10px;
}
.bannerVideo video {
    width: 100%;
    /* border-radius: 10px; */
    position: relative;
    height: 440px !important;
    object-fit: cover;
    box-shadow: 20px 19px 50px rgb(9 46 40 / 35%);
    /* border: 8px solid rgb(255 255 255 / 29%); */
}
.commingSoon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding-top: 130px;
}

.commingSoon h2 {
    font-size: 90px;
    font-family: 'Exo 2', sans-serif;
    font-weight: 900;
}

.commingSoon h3 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    margin: 15px 0px;
}

.commingSoon h3 img {
    margin: 0px 6px;
    position: relative;
    top: -3px;
}

.commingSoon p {
    font-size: 31px;
    font-weight: 100;
    margin-bottom: 50px;
}

.capcha img {
    width: 260px !important;
    margin-bottom: 25px;
}

ul.helpingList {
    list-style: none;
    padding: 0;
}

ul.helpingList li {
    /* display: flex; */
    margin-bottom: 10px;
    align-items: start;
}


ul.helpingList li img {
    margin-right: 10px;
}

.upcomingHelp .CoachingItem img {
    height: 165px;
}

.upcomingHelp .CoachingItem {
    border-radius: 10px;
}

.upcomingHelp .CoachingItem h3 {
    font-size: 16px;
}

.commingSoonForm .form-control::-webkit-input-placeholder {
    color: #fff !important
}

.packborder {
    /* border-style: solid; */
    border-radius: 8px;
    /* border-color: rgb(40, 212, 40); */    
}
.packborder + .packborder,
.packageListInner {
    margin-top: 20px;
}
.packageInfo .btn {
    border: 1px solid #00e1ba;
}
.packageListInner .packageList .packageInfo::after {
    display: none;
}
.packageListInner .packageList {
    border: 1px solid #dfeced;
}
.packstatus {
    /* height: 400px;
    width: 800px; */
    /* background: red; */
    /* display: flex; */

    flex-direction: column;
    justify-content: right;
    text-align: left;
    /* z-index: 1; */
    /* position: absolute; */
    padding-bottom: 235px;
}



.btn-circle {
    width: 50px;
    height: 50px;
    padding: 10px 0px;
    border-radius: 50px;
    font-size: 14px;
    text-align: center;
}

/*  */

.aboutBanner {
    background: url(../images/aboutBackground.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 70px 0px;
    position: relative;
    background-position: bottom center;
}

.aboutBanner .heading h3 {
    font-size: 40px;
    text-transform: uppercase;
    font-weight: 400;
    color: #00e1ba;
}

.aboutBanner .heading p {
    font-size: 24px;
}


.missionWrap {
    background: url(../images/missionBg.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 0px;
    position: relative;
    background-position: center;
    border-radius: 10px;
}

.missionWrap h3 {
    color: #67a833;
    font-size: 25px;
}

.missionWrap span {
    background: #67a833;
}

.missionWrap p {
    /* color: #000; */
    padding: 0px 50px;
}

.teamWrap {
    background: url(../images/team.png) no-repeat center;
    padding: 75px 0px;
    background-size: cover;
}

.teamWrap .heading h3 {
    color: #67a833;
    font-size: 25px;
}

.teamWrap .heading span {
    background: #67a833;
}

.teamWrap .heading p {
    color: #323232;
}

.memberContent {
    background: rgb(255 255 255 / 0.98);
    padding: 15px;
    border-radius: 15px;
    /* color: #000; */
    text-align: center;
    box-shadow: 0px 0px 6px #0000001a;
}

.teamMember {
    padding: 5px 10px;
}

.memberContent img {
    width: 135px !important;
    height: 135px;
    margin: 15px auto 25px;
    display: block;
    border-radius: 50%;
    object-fit: cover;
}

.memberContent h5 {
    font-size: 16px;
    color: #00e1ba;
    margin-bottom: 25px;
}

.memberContent p {
    font-size: 14px;
    margin-bottom: 20px;
    color: #656565;
}

.memberContent ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
}

.memberContent ul a {
    color: #00e1ba;
    font-size: 18px;
    margin: 0px 12px;
}

.teamWrap {
    margin-bottom: 75px;
}
.commingSoonForm.newsLetter input.form-control {
    background: #ffffff;
}

.helpingWrap .col-12.text-center p {
    margin: 0;
}

.chooseModalItem .custom-checkbox {
    position: absolute;
    left: 5px;
    top: 11px;
}

.chooseModalItem .custom-control-label::before {
    border: none;
    border-radius: 25px !important;
    width: 2rem;
    height: 2rem;
}

.chooseModalItem .custom-control-label::after {
    width: 2rem;
    height: 2rem;
}

.autocomplete-suggestion-box {
    background: #919191;
    color: #fff;
    border-radius: 10px;
    padding: 15px;
}

.location-search-input {
    color: #fff;
}

.modal-lg {
    max-width: 90% !important;
}

.centered {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* color: #000; */
}
.even-larger-badge {
    font-size: 1.1em;
    margin-right: 6px;
}
.modal-title h3 {
    font-size: 22px;
    margin: 0;
}


.textcentere {
    position: absolute;
    bottom: 10%;
    left: 30%;
    transform: translate(-50%, -50%);
    /* color: #000; */
}

.fileName {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid rgb(116, 112, 112);
    border-radius: 4px;
    box-sizing: border-box;
}

.resource-title {
    text-align: center;
    color: black;
    opacity: 1;
}

.resource-title-modal {
    text-align: center;
    color: white;
    opacity: 80%
}
.even-larger-badge {
    font-size: 1.1em;
    margin-right: 6px;
}

.freetrial-over {
    background: rgba(133, 202, 77, .06);
    border: 1px solid rgb(255, 168, 168);
    box-shadow: 0 3px 10px rgb(255, 168, 168);
    color: red;
    padding: 15px;
    border-radius: 5px;
    width: 100%;
    text-align: center;
}

.stripe-button-el span {
    background: #42992c !important;
    background-image: none !important;
    background-color: #42992c !important;
    width: 160px;
    text-align: center;
    float: "right"
}

.lightBox .deletIcon {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 7px;
    top: 5px;
}

#inputID::placeholder {
    color: #f7f3f3;
}

.stripe-button-el {
    background: #00e1ba !important;
    background-image: none !important;
    background: #00e1ba !important;
    width: 160px;
    text-align: center;
    float: "center";
    padding: 10px 30px;
    border-radius: 25px;
    transition: 0.9s;
    text-transform: uppercase;
}

section.coacActionhWrap {
    position: relative;
    background: #f5f5f5;
    padding-bottom: 60px;
}
.coacActionhWrap .searchShortBy select {
    background: #f5f5f5;
}

.coacActionhWrap .cardBody .cardDItem {
    width: 31%;
    margin: 10px 10px;
    border-bottom: 1px dashed #99d865;
    padding: 10px;
}

.cardHeading h3 span {
    color: #00e1ba;
    font-size: 11px;
    background: #ffffff;
    padding: 5px 6px;
    display: inline-block;
    margin-left: 5px;
    border-radius: 5px;
}

.dBank {
    position: absolute;
    top: 15px;
    right: 15px;
}

.earnPrice {
    background: #77c13b;
    text-align: center;
    padding: 15px 10px;
    border-radius: 50px;
    margin-bottom: 25px;
}

.earnPrice h3 {
    margin: 0;
    font-size: 21px;
    font-weight: 600;
}

.tab-pane {
    background: #fff;
    font-size: 14px;
    text-align: center;
}

.availabilityWrap {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    color: #424242;
    box-shadow: 0px 0px 7px #8c8c8c52;
}

.availabilityWrap.availiwidth {
    margin: 0px;
}

.availabilityWrap label {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.availabilityTime .card-header a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #85cf4a;
    font-weight: 600;
    font-size: 15px;
    text-decoration: none;
}

.availabilityTime .card-header {
    border: none;
    background: #232323;
    border-radius: 0;
}

.availabilityTimeList {
    padding: 25px;
    background: #f9f9f961;
    border-width: 0px 1px 1px 1px;
    border-style: dashed;
    border-color: #00e1ba;
}

.availabilityTime .card-header a i {
    font-size: 20px;
}

.availabilityTimeItem h5 {
    font-size: 16px;
    font-weight: 600;
    color: #6c4994;
    min-height: 30px;
    line-height: 30px;
}

.availabilityWrap input.form-control {
    height: 45px;
    border-radius: 5px;
    padding: 0px 15px 0 15px;
    font-size: 14px;
    text-transform: capitalize;
    box-shadow: none !important;
    border: 1.5px solid #dedede !important;
    -webkit-appearance: none;
    background: #ffffff61;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.availabilityWrap textarea.form-control {
    height: auto !important;
    border-radius: 10px;
    padding: 15px;
    font-size: 14px;
    text-transform: capitalize;
    box-shadow: none !important;
    border: 1.5px solid #dedede !important;
    -webkit-appearance: none;
    background: #ffffff61;
}

/* .areas label.custom-control-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
} */

.addAvailabilityTime {
    background: #00e1ba;
    color: #fff;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    text-decoration: none;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid #00e1ba;
    transition: all .3s ease;
}
.addAvailabilityTime:hover {
    color: #00e1ba;
    background-color: #fff;
}

.availabilityTimeItem .form-group {
    display: flex;
    align-items: center;
}

.availabilityTimeItem .form-group label {
    margin: 0;
    margin-right: 10px;
}
.session-carousel .areas {
    text-align: left !important;
}

.session-carousel .custom-control {
    border: 1px solid #ccc;
    padding: 5px 45px;
    border-radius: 11px;
    margin: 0px 0px 5px !important;
}

.session-carousel .memberContent img {
    width: 50px !important;
    height: 50px;
    margin: 5px auto 15px;
    display: block;
    border-radius: 0;
    object-fit: contain;
}

.session-carousel .memberContent h3 {
    color: #734d9d;
    font-size: 24px;
}

.session-carousel .memberContent hr {
    width: 75px;
    border-color: #00e1ba;
}

.aboutVideo iframe {
    width: 100%;
    border-radius: 25px;
}
.aboutVideo {
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 1px 2px rgb(27 50 47 / 9%);
}
.rating-stars ul>li.star span {
    display: block;
    color: #ccc;
}

.modal-availability {
    max-width: 65% !important;
}

.modal-bookSession {
    max-width: 90% !important;
}

/* new---- */
.priceItem .defaultBtn {
    box-shadow: 0px 0px 8px #ccc;
    padding: 10px 20px;
    font-size: 12px;
    margin: 0px;
}
.hac {
    padding-right: 4rem;
}
.priceItemBtn {
    border-bottom: 0;
}

.webinnerSearch .searchList:last-child input {
    border-radius: 25px;
}

.webinnerSearch.searchPart::before {
    display: none;
}
.webinarSearchWrap .webinnerContent .defaultBtn {
    font-size: 14px;
    font-weight: 600;
}
.newSearchListWrap .searchFilter h3 {
    color: #505050;
    font-size: 20px;
    margin: 5px 0px 0px 0px;
    border-bottom: none;
    padding: 0px 0px 0px;
}

a.viewAFilter {
    background: #fbfbfb;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
    transition: 0.3s all;
}

a.viewAFilter.active {
    transform: rotate(180deg);
    transition: 0.3s all;
}

.confirmBooking .form-control {
    height: 45px;
    border-radius: 5px;
    color: #565656;
    padding: 0px 45px 0 15px;
    font-size: 14px;
    text-transform: capitalize;
    box-shadow: none !important;
    border: 1.5px solid #dedede !important;
    -webkit-appearance: none;
    background: #ffffff61;
}

.coacBookingWrap .col-md-4 h3 {
    font-size: 20px;
    color: #777;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 12px;
    margin-bottom: 25px;
}

.coacBookingWrap p {
    /* color: #000; */
    font-size: 15px;
}

.coacBookingWrap p b {
    display: block;
}

.coacBookingWrap small {
    color: #3e3e3e;
    line-height: 18px !important;
    margin-top: 15px;
    display: inline-block;
    text-align: justify;
}

.filterApply {
    height: 30px;
    text-decoration: none !important;
}

.searchList .bootstrap-select>.dropdown-toggle {
    position: relative;
    width: 100%;
    z-index: 1;
    text-align: right;
    white-space: nowrap;
    height: 49px;
    border-radius: 26px 0px 1px 25px;
    line-height: 38px;
    background: #fff !important;
    border: none;
    padding-left: 25px;
    padding-right: 25px;
    box-shadow: none !important;
    outline: none !important;
    color: #495057 !important;
}

.searchList .btn-light:not(:disabled):not(.disabled).active,
.searchList .btn-light:not(:disabled):not(.disabled):active,
.searchList .show>.btn-light.dropdown-toggle {
    color: #81888f;
    background-color: #ffffff !important;
    border-color: transparent !important;
    outline: none !important;
}

.searchList .dropdown-toggle::after {
    background: url(../images/dArrowblack.png) no-repeat;
    right: -20px;
}

.searchList .bs-searchbox input {
    border-radius: 5px 5px 5px 5px !important;
}

.saveCardList ul {
    padding: 0;
    list-style: none;
}

.saveCardList label.custom-control-label img {
    width: 55px;
    margin-right: 10px;
}

.saveCardList label.custom-control-label p {
    margin: 0;
    line-height: 19px;
    cursor: pointer;
}

.saveCardList label.custom-control-label p span {
    display: block;
    font-weight: 600;
}

.saveCardList ul li {
    border: 1px solid #e6e6e6;
    margin-bottom: 10px;
    padding: 10px 5px;
    border-radius: 5px;
    cursor: pointer;
}

.saveCardList ul li label::before {
    top: 0.7rem;
}

.saveCardList ul li .custom-control-label::after {
    top: .7rem;
}


.flipper {
    position: relative;
    margin-bottom: 30px;
    box-shadow: 0 1px 2px rgb(32 44 42 / 22%);
    border-radius: 10px;
    overflow: hidden;
    height: 340px;
}
.front, .back {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all .5s;
}
.front {
    z-index: 1;
    background-size: cover !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.back {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
    background: #fff;
    border-radius: 10px;
    text-align: center;
    padding: 15px;
}

.flipper:hover .front {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
}
.flipper:hover .back {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    transform: rotateY(0deg);
}

.front:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgb(0 0 0 / 50%);
}
.front > h3 {
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 300;
    color: #fff;
    position: relative;
    z-index: 1;
    margin: 0;
}
.back p {
    font-size: .95rem;
}
.front1 {
    background: url(../images/c1.png) 0 0 no-repeat;
}

.front2 {
    background: url(../images/c2.png) 0 0 no-repeat;
}

.front3 {
    background: url(../images/c3.png) 0 0 no-repeat;
}

.front4 {
    background: url(../images/c4.png) 0 0 no-repeat;
}

.front5 {
    background: url(../images/c5.png) 0 0 no-repeat;
}

.front6 {
    background: url(../images/c6.png) 0 0 no-repeat;
}

.front .name {
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 300;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
}

.back .R_divider {
    background: #00e1ba;
    margin-bottom: 1rem;
}
.bookingListing .searchFilter {
    background: #fff;
    box-shadow: none;
    padding: 0;
}
.flipper .back > h4 {
    font-size: 1.5rem;
    margin: 0;
    text-transform: uppercase;
}
.availabilityViewWrap .searchFilter {
    box-shadow: none;
    padding: 0;
}

.availabilityViewWrap .col-md-12 hr {
    margin-top: 0px;
}

.testimonialWrap .aboutText p small {
    line-height: 20px !important;
    display: inline-block;
}

.uploadFile ul {
    padding: 0;
    margin-top: 10px;
    display: flex;
}

.uploadFile ul li {
    position: relative;
    margin: 4px;
}

.uploadFile ul li a {
    /* position: absolute;
    right: 0; */
    color: #ff6767;
    font-size: 23px;
    top: -13px;
}

.reviewModalWrap label {
    color: #878787;
    font-size: 15px;
    display: block;
    margin-bottom: 10px;
    font-weight: 500;
}

.editProfileForm p {
    color: #878787;
    font-size: 14px;
}

.availabilityViewWrap div[class^="col-"] {
    padding-left: 10px;
    padding-right: 12px;
}

.availabilityViewWrap .searchFilter button.btn.dropdown-toggle {
    border-radius: 6px;
    line-height: 28px !important;
}


.availabilityViewWrap input[type="date"] {
    text-transform: uppercase;
}



.cd-words-wrapper {
    display: inline-block;
    position: relative;
    text-align: left;
}

.cd-words-wrapper b {
    display: inline-block;
    position: absolute;
    white-space: nowrap;
    left: 0;
    top: 0;
}

.cd-words-wrapper b.is-visible {
    position: relative;
}

.no-js .cd-words-wrapper b {
    opacity: 0;
}

.no-js .cd-words-wrapper b.is-visible {
    opacity: 1;
}

/* -------------------------------- 
  
  xclip 
  
  -------------------------------- */

.cd-headline.clip span {
    display: inline-block;
    padding: .2em 0;
}

.cd-headline.clip .cd-words-wrapper {
    overflow: hidden;
    vertical-align: top;
}

.cd-headline.clip .cd-words-wrapper::after {
    /* line */
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 2px;
    height: 100%;
    background-color: #f7f7f7;
}

.cd-headline.clip b {
    opacity: 0;
}

.cd-headline.clip b.is-visible {
    opacity: 1;
}

.find-my-coach-form-control {
    appearance: none;
    outline: 0;
    background: white;
    /* background-image: none;  */
    width: 100%;
    height: 100%;
    color: rgb(99, 99, 99);
    cursor: pointer;
    border: 0px solid black;
    border-radius: 5px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    position: absolute;
    padding-left: 30px;

}

.coachEditWrap {
    padding: 100px 0px 60px;
    background: #f5f5f5;
}

.coachProfileFormEdit {
    background: #fff;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0px 0px 6px #ccc;
}

.coachProfileFormEdit h4 {
    font-size: 22px;
}

.coachProfileFormEdit h4 span {
    font-size: 18px
}

.coachProfileFormEdit label {
    font-size: 14px;
    color: #424242;
    font-weight: 600;
}

.coachProfileFormEdit .searchFilter {
    /* background: #fff !important; */
    box-shadow: none !important;
    padding: 0 !important;
    border-radius: 0 !important;
    margin-right: 0 !important;
}
/* 
.coachProfileFormEdit .form-control {
    background: transparent;
    box-shadow: none;
    border: 1px solid #ccc !important;
    border-radius: 28px;
    height: 45px !important;
    line-height: 24px !important;
    font-size: 15px !important;
    padding: 10px 20px;
} */

.coachProfileFormEdit textarea.form-control {
    height: auto !important;
}

.coachProfileFormEdit .coachImg.mb-4 {
    width: 200px;
    margin: 0 auto;
    margin-top: -90px;
}

.coachProfileFormEdit .coachImg>img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
    border: 5px solid #ffffff;
}

.bootstrap-select .dropdown-menu {
    min-width: 280px !important;
    overflow-x: hidden !important;
}


.my-modal-UpdatePersonal {
    width: 50vw;
    /* Occupy the 90% of the screen width */
    max-width: 50vw;
}

/* .modal-content { background:rgb(250, 246, 246) !important; } */

.onhoverText {
    position: relative;
    display: inline-block;
}

.onhoverText .onhoverTexttext {
    visibility: hidden;
    width: 180px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 2%;


}

.onhoverText:hover .onhoverTexttext {
    visibility: visible;
}
.searchList .form-control {
    padding: 0;
    border: none;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    height: 100%;
    width: 95%
}


.searchList .form-control input,
.searchList .form-control input:focus {
    border: none;
    box-shadow: none;
    outline: none;
}

.google-button {
    list-style: none;
}

.google-button button {
    width: 260px;
    justify-content: center;
    border-radius: 25px !important;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    text-transform: uppercase;
}

.accept_modal {
    color: #000 !important;
}

.areas.areasNew {
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
    margin-left: -0.2rem;
    margin-right: -0.2rem;
}
.slot_item .custom-control {
    background: #24dfbe;
    text-align: center;
    padding: 10px 0px;
    border-radius: 3px;
    margin-bottom: 8px;
}
.slot_item .custom-control-label {
    cursor: pointer;
    font-size: .95rem;
    font-weight: 600;
}
.slot_item .custom-control input:not(:disabled) ~ .custom-control-label {
    color: #243a36;
}
.slot_item .custom-control input:not(:disabled) ~ .custom-control-label:before {
    border-color: #243a36;
}
.slot_item .custom-control input:not(:disabled):checked ~ .custom-control-label:before {
    background-color: #243a36 !important;
}
.slot_item .custom-control .custom-control-label:before {
    background-color: transparent;
}
.slot_item {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 0 0.2rem;
}
.picEdit input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 50%;
    font-size: 0;
}
.picEdit input:hover{
    cursor: pointer;
}


.google-button button div {
    padding: 0 !important;
}
.f_sidebar {
    background-color: #fff;
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0px 10px 20px rgb(20 34 32 / 6%);
}
.fs_cont {
    padding: 0 1rem 1rem;
}
.pro_img_block .coachImg {
    text-align: center;
    padding: 2rem;
}
.pro_img_block .coachImg .freeTrialDays button {
    width: 86px;
    height: 86px;
}
.tour-approval {
    color: red;
    font-size: 11px;
    margin-top: 5px;
}
.dropdown-menu .dropdown-item {
    text-decoration: none;
}

.bookingCalendar .react-calendar {
    width: 100%;
    padding: 25px;
    border: none;
}

.bookingCalendar .react-calendar button {
    color: #848484;
    font-size: 13px;
    font-weight: 600;
    padding: 4px 5px;
    height: 40px
}

.bookingCalendar .react-calendar button.react-calendar__month-view__days__day--weekend {
    color: #D44;
}

.bookingCalendar .react-calendar .react-calendar__navigation button {
    color: #734d9d;
    font-size: 13px;
    font-weight: 600;
}

.bookingCalendar .react-calendar .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday {
    border-bottom: 1px solid #e7e7e7;
    color: #9b8079;
    font-size: 13px;
    text-transform: capitalize;
    text-decoration: none;
}


.bookingCalendar .react-calendar .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday abbr[title] {
    text-decoration: none
}

.bookingCalendar .react-calendar .react-calendar__tile--now {
    background-color: #734d9d;
    color: #ffffff !important;
    border-radius: 0;
}

.bookingCalendar .react-calendar .react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background-color: #7ac041;
    color: #fff !important;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
}

.bookingCalendar .react-calendar .react-calendar__tile--active:enabled:hover,
.bookingCalendar .react-calendar .react-calendar__tile--active:enabled:focus {
    background: #7ac041;
    color: #fff !important;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.bookingCalendar .react-calendar .react-calendar__tile--active {
    background: #7ac041;
    color: #fff !important;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

/* .areas.areasNew .custom-control.disabled {
    background: #c4c4c4
} */
.areas.areasNew .custom-control.disabled {
    background: #dbe3e1
}

.unavailable_block {
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    font-size: 16px;
    background: #fff;
    box-shadow: 0 5px 15px #c6c6c6;
}


.package_button {
    background: transparent;
    border: none;
}














.react-calendar__month-view__weekdays {
    color: black
}


/* .searchHead .searchHeading {flex:0 0 100%;} */
.searchHead button {
    margin-left: auto;
}

.bookingCocachDetails li span,
.bookingCocachDetails li b {
    font-size: 12px;
}

.R_divider.div_dark {
    background-color: #b6cfca;
}
.heading h3 {
    font-size: 36px;
    font-weight: 600;
}
.searchHead .searchHeading {
    display: flex;
    align-items: center;
}
.searchHead .searchHeading span i {
    vertical-align: text-bottom;
    margin-right: 5px;
}


.searchHead .searchHeading span {
    padding: 3px 10px;
    border-radius: 20px;
    background-color: rgb(232 235 238);
    margin-left: 5px;
    line-height: 19px;
    color: black;
    height: 28px;
    font-weight: bold;
    margin-top: 3px;

}

.searchHead .searchHeading span i img {
   max-width: 17px;
   max-height: 17px;;
}

.searchHead button {
    margin-left: auto;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}



.userBookingList .tab-pane {
    background: transparent;
    color: #505050;
    text-align: left
}


.userBookingList .nav-tabs .nav-item.show .nav-link,
.userBookingList .nav-tabs .nav-link.active {
    border: none;
    border-color: none;
    background: #00e1ba;
    color: #fff;
    text-decoration: none;
    text-decoration: none;
    position: relative
}

.userBookingList .nav-tabs .nav-item.show .nav-link::after,
.userBookingList .nav-tabs .nav-link.active::after {
    content: "";
    position: absolute;
    bottom: -22px;
    left: 50%;
    margin-left: -10px;
    border: 10px solid transparent;
    border-top-color: transparent;
    border-top-color: #00e1ba;
}

.userBookingList .nav-tabs {
    margin-bottom: 30px
}

.userBookingList .nav-tabs {
    border-bottom: 2px solid #00e1ba;
    /* background: #e8ebee;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px; */
}

.userBookingList .nav-tabs a {
    border: none;
    text-decoration: none;
    color: #505050;
}

/* .userBookingList .nav-tabs:hover a {
    border: none;
    background: #cecece;
    color: #111111;
} */

/* 
.css-2b097c-container .css-520vho-control {
    height: auto !important;
    border-radius: 15px !important;
    -webkit-border-radius: 15px !important;
    -moz-border-radius: 15px !important;
    -ms-border-radius: 15px !important;
    -o-border-radius: 15px !important;
}

.css-2b097c-container .css-1f1q7b2-control {
    height: auto !important;
    border-radius: 15px !important;
    -webkit-border-radius: 15px !important;
    -moz-border-radius: 15px !important;
    -ms-border-radius: 15px !important;
    -o-border-radius: 15px !important;
} */

.contentBody {
    background: #fff;
    color: #505050;
    padding: 35px 0px 35px
}

.feedbackform .form-control {
    /* color: #000; */
    background: #ffffff;
    padding: 0px 15px;
    font-size: 14px;
    text-transform: initial;
    box-shadow: none !important;
    border: 1.5px solid #dedede !important;
    -webkit-appearance: none;
    height: 45px;
    min-height: calc(1.5em + 0.75rem + 2px);
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}


.feedbackform label {
    font-size: 18px;
}

.feedbackform textarea.form-control {
    height: 150px;
    padding: 15px;
}

.feedbackform .dv-star-rating i {
    font-size: 30px;
}

.photo_item audio {
    width: 100%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}


.searchPart {
    background: #fff;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    height: 100%;
    min-height: 50px;
}

.searchPart .basic-multi-select {
    height: 100%;
}

.searchPart .basic-multi-select .select__control {
    border: none;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    height: 100%;
}

.searchPart .basic-multi-select .select__control .select__indicators {
    display: none;
}

.searchPart .basic-multi-select .select__control.select__control--is-focused {
    border: none;
    box-shadow: none
}

.booking_session_btn_group .defaultBtn {
    min-width: 176px;
}



.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before,
.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before,
.custom-control-input:disabled~.custom-control-label::before {
    border-color: #c6c6c6;
    background-color: transparent
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
    background-image: none
}

.packagesWrap .heading {
    position: relative;
}

.packagesWrap .cProfileEdit {
    position: absolute;
    top: -80px;
    width: 100%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
}

.packagesWrap .addTag {
    display: block;
    background: #fff;
    padding: 8px 12px;
    border-radius: 20px 20px 5px 20px;
    transition: 0.3s all;
}
.text-dark {
    color: #343a40 !important;
}
.fade .heading * {
    font-size: 1.2rem;
}
small,
.small {
    font-size: 80%;
}

hr {
    border: 1px !important;
    opacity: 1 !important;
    border-top: 1px solid rgba(0, 0, 0, .1) !important;
    background-color: transparent !important
}


.edit_bio {
    display: flex;
    justify-content: flex-end;
}

.addTag {
    display: inline-block;
    background: #fff;
    padding: 8px 12px;
    border-radius: 20px 20px 5px 20px;
    transition: 0.3s all;
    margin-bottom: 30px;
}

.addTag img {
    top: -4px !important
}










@media (max-width:1200px) {
    ul.action li::after {
        left: 0;
        width: 100%;
    }
    .searchFilter {
        background: #fff;
        box-shadow: 0px 0px 6px #ccc;
        padding: 15px 15px;
        border-radius: 15px;
        margin-right: 0;
    }

    .availabilityWrap {
        padding: 15px;
        margin: 0px;
    }


}

@media (max-width:1023px) {
    .blogContent {
        margin-bottom: 30px;
    }
    .registrationContent {
        margin: 0px 16px;
    }

    .otherRegistration {
        background-position: center;
    }

    .coachProfileVideo {
        margin: 0px;
        position: relative;
    }

    .coachProfileVideo a {
        right: 35px;
    }
    .intro-video-delete-icon {
        right: 35px !important;
    }

    .coachInfoWrap ul {
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        padding-bottom: 25px;
    }
    .coachInfoWrap::after {
        display: none;
    }

    .coachInfoWrap ul li {
        width: 50%;
        text-align: center;
    }

    .coachInfoWrap ul li a {
        padding: 15px 15px 15px;
    }

    .availabilityViewWrap .searchFilter button.btn.dropdown-toggle {
        font-size: 12px !important;
        padding: 0px 35px 0 10px !important;
    }

    #content1 .row {
        margin-bottom: 6px;
    }

    a.addAvailabilityTime {
        width: 100%;
        font-size: 15px;
        border-radius: 4px;
    }

}

@media (max-width:980px) {
    .searchWrap button.defaultBtn {
        width: 100%;
        font-size: 13px;
        height: 49px;
        padding: 10px 20px;
    }

    .testimonialWrap .aboutText p {
        font-size: 15px;
        line-height: 22px;
    }

    section.testimonialWrap {
        padding-bottom: 15px;
    }

    .chooseWrap .col {
        width: 50%;
        flex: 48% 0;
        margin-bottom: 7px;
    }

}

@media (max-width:767px) {

    li.nav-item a {
        font-size: 14px;
        padding: 13px 10px !important;
        text-align: center;
    }

    .nav_wrap .navbar-nav .nav-item.active::before,
    .nav_wrap .navbar-nav .nav-item:hover::before {
        display: none;
    }

    .closeMenu {
        display: block !important;
        clear: both;
        text-align: right;
        position: absolute;
        right: 0px;
        top: -5px;
        z-index: 9;
        padding: 10px;
    }

    .closeMenu svg {
        width: 15px;
    }

    /* #navbarResponsive {
        text-align: center;
        position: absolute;
        right: 15px;
        top: 0;
        background: #2d2e2e;
        width: 246px;
        z-index: 9;
        padding: 10px;
        border-radius: 5px;
        box-shadow: 0px 0px 6px rgb(112 112 112 / 23%);
    } */

    li.nav-item.myAccount a,
    li.nav-item.buyNow a {
        padding: 9px 15px !important;
        width: 150px;
        margin: 0 auto;
        margin-bottom: 5px;
        font-size: 16px;
        font-family: 'Open Sans', sans-serif;
    }

    ul.action li::after {
        left: 0;
        width: 100%;
    }

    .newsLetter {
        margin: 25px 0px;
    }

    .footerTop .row .col-md-4:nth-child(1) {
        order: 1;
    }
    .animate {
        animation-duration: 0.3s;
        -webkit-animation-duration: 0.3s;
        animation-fill-mode: both;
        -webkit-animation-fill-mode: both;
    }
    .searchWrap button.defaultBtn {
        width: 100% !important;
        font-size: 16px;
        height: 49px;
        padding: 10px 20px;
        margin: 15px 0px;
    }

    section.aboutWrap img {
        width: 100%;
        margin-bottom: 15px;
    }

    .webinnerContent .heading p {
        padding: 0;
    }

    .webinnerContent {
        padding: 40px 25px;
    }

    .new,
    .old,
    .current {
        font-size: 60px;
    }

    #js-days:after,
    #js-hours:after,
    #js-minutes:after,
    #js-seconds:after {
        bottom: -40px;
        font-size: 14px;
    }
    .podcastsSearch {
        width: 310px;
        float: none;
        margin: 0 auto 30px;
        display: block;
    }

    .heading p br {
        display: none;
    }

    section.testimonialWrap .col-md-5 img {
        position: relative;
        margin-top: 0;
        margin-bottom: -13px;
    }

    header.nav_wrap.sticky {
        position: unset;
    }

    .searchFilter {
        margin-bottom: 15px;
    }

    section.coachesBanner p {
        text-align: left;
    }

    .benefitsContent img.img-fluid {
        position: unset;
        transform: none;
        box-shadow: 0px 0px 8px #000000d9;
    }

    .benefitsContent {
        background: #fff;
        color: #262626;
        padding: 21px 17px;
        border-radius: 15px;
        margin-bottom: 40px;
        min-height: 340px;
    }

    .benefitsContent h3 {
        font-size: 25px;
        margin-top: 15px;
    }

    section.contactWrap iframe {
        width: 100%;
        margin-bottom: -6px;
        height: 350px;
    }

    section.contactWrap {
        margin: 50px 0px;
        padding: 0px 15px;
    }
    .commingSoon h2 {
        font-size: 30px;
    }

    .commingSoon {
        padding-top: 15px;
    }

    .commingSoon h3 {
        display: block;
    }

    .commingSoon p {
        font-size: 13px;
        font-weight: 100;
        margin-bottom: 0px;
    }

    .missionWrap p {
        /* color: #000; */
        padding: 0px 15px;
    }

    .contactWrap form.pr-4.mb-4 {
        padding-right: 0 !important;
    }

    section.userDashboardWrap {
        padding: 20px 0px;
    }

    .coachInfoWrap.userDashboardLeftPart ul {
        display: block;
        margin-bottom: 25px;
    }

    img.contact-icon {
        width: 65%;
        margin: 50px auto 0;
        display: block;
        border-right: 0;
        padding-right: 0;
        border: none;
    }
    .availabilityViewWrap .col-md-12 hr {
        margin-top: 15px;
    }

    .availabilityViewWrap div[class^="col-"] {
        padding-left: 10px;
        padding-right: 12px;
    }


    .coacActionhWrap .cardBody .cardDItem {
        width: 40%;
    }

    /* .cardHeading h3, .cardHeading p { flex:0 0 100%;} */
    .cardAction a {
        margin: 0 2px
    }



    /* .cardAction {flex: 0 0 auto; margin-left: auto} */

    .freetrial-over {
        max-width: 100%;
        margin: 15px 0;
    }

    .areas.areasNew .custom-control {
        flex: 47% 0;
    }


}

@media (max-width:568px) {

    a.logoHolder {
        top: 0px;
        width: 175px;
        margin: 10px 0;
        display: block;
    }

    ul.socialIcon {
        text-align: center;
        margin-bottom: 0.2rem;
    }

    .topbarAction.text-right {
        text-align: center !important;
        margin-bottom: 0.7rem;
        margin-top: 0rem;
    }
    .topbarAction a {
        font-size: 14px;
        margin: 0px 5px;
    }

    .searchList {
        width: 100%;
        position: relative;
        margin-top: 15px;
    }

    .searchPart .searchList input {
        border-radius: 25px !important;
    }

    .searchPart::before {
        display: none;
    }
    .new,
    .old,
    .current {
        font-size: 45px;
    }

    #js-days:after,
    #js-hours:after,
    #js-minutes:after,
    #js-seconds:after {
        bottom: -37px;
        font-size: 12px;
    }

    .defaultBtn {
        padding: 10px 30px;
        font-size: 15px !important;
    }

    .registrationForm {
        padding: 35px 10px 25px 10px;
        /* color: #000; */
    }

    .registrationForm .defaultBtn {
        padding: 10px 23px;
        font-size: 15px !important;
        line-height: 28px;
    }

    .otherRegistrationFilds {
        padding: 50px 20px;
    }

    .registrationForm.loginForm {
        padding: 44px 10px 25px 10px;
    }

    .chooseVideo img,
    .chooseVideo {
        height: 250px;
    }

    section.chooseWrap::after {
        bottom: 190px;
    }

    .chooseWrap .col {
        width: 100%;
        flex: 100% 0;
        margin-bottom: 7px;
    }

    .featuresWrap .col-md-7.pl-5 {
        padding-left: 16px !important;
    }

    .priceWrap {
        margin: 40px 0px 35px;
    }

    .priceHeading {
        margin: 0px 7px;
        font-size: 12px;
        padding: 15px 3px;
        line-height: 15px;
    }

    .priceItem {
        margin: 0px 15px;
        font-size: 13px;
        padding: 13px 0px;
    }

    form.questionsForm .w-75,
    form.questionsForm .w-50 {
        width: 100% !important;
    }

    .questionsForm fieldset {
        width: 100%;
        margin: 0;
    }

    #questionsProgressbar li:before {
        width: 30px;
        line-height: 30px;
        font-size: 16px;
        border-radius: 8px;
    }

    #questionsProgressbar li:after {
        top: 14px;
    }

    .areas.d-flex {
        flex-wrap: wrap;
    }

    .areas .custom-control.custom-checkbox {
        margin: 4px 0px;
    }
    .cardBody div.cardDItem {
        width: 100% !important;
    }

    /* .cardAction {
	    flex: 100% 0 0;
	    text-align: center;
	    margin-top: 15px;
	} */
    .bookingCocachDetails li span,
    .bookingCocachDetails li b {
        font-size: 12px;
    }

    .coachImg .picEdit {
        position: absolute;
        /* left: 0; */
        bottom: 15px;
        transition: 0.3s all;
        right: 15px;
    }

    .heading h3 {
        font-size: 28px;
    }
    .searchPart {
        background: transparent !important;
        box-shadow: none !important
    }

    .searchPart .dropdown-toggle,
    .searchPart .basic-multi-select .select__control {
        border-radius: 50px;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        -ms-border-radius: 50px;
        -o-border-radius: 50px;
        min-height: 50px;
        overflow: hidden;
    }

    .userDashboardWrap .defaultBtn {
        margin: 15px 0 0;

    }


}























* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.tags-content {
    /* margin: 20px; */
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    /* border: 1px solid #000; */
    height: fit-content;
}

.tag {
    padding: 5px;
    border: 1px solid #000;
    background: #e0e0e0;
    display: flex;
    align-items: center;
}

.tag .remove-tag {
    margin: 3px;
    color: #ff0000;
    cursor: pointer;
}

.tags-content input {
    border: 0 !important;
    padding: 10px;
    outline: none !important;
}

.skillField .tags-content input {
    border-radius: 50px !important;
    margin-bottom: 0px;
}

.skillField .tags-content {
    /* overflow: hidden; */
    border: 1px solid #ced4da !important;
    border-radius: 20px;
}


.tags-input {
    position: relative;
    flex: 1 1 100%;
    border: none !important;
}

.tags-suggestions {
    border: 1px solid;
    padding: 5px;
    position: absolute;
    top: 45px;
    bottom: 0px;
    /* min-height: 50px; */
    height: 300px;
    width: 100%;
    z-index: 99999;
    overflow-y: scroll;
    background: #fff;
    box-shadow: 0 10px 15px #c6c6c6;
}

.suggestion-item {
    cursor: pointer;
    margin: 5px 0;
    padding: 5px;
    background: #ececec;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border: 1px solid rgb(155, 155, 155);
    color: rgb(95, 95, 95);
}

.tags-content .tag {
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    line-height: 15px;
    padding: 5px 10px;
    border: 1px solid rgb(155, 155, 155);
    margin: 3px;
    color: rgb(95, 95, 95)
}

.feedbackContent span {
    color: #00e1ba;
    cursor: pointer;
}

.hideaction {
    display: none !important;
}


.ant-picker {
    height: 45px;
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
    width: 100%;
}

.heading .fa-info-circle {
    /* color: #000; */
    font-size: 18px;
}

.tags-content .tags-input input[type="text"] {
    /* color: #000; */
}


.blogWrap .row .col-lg-3.col-sm-6 a {
    display: block;
    height: 100%
}

.blogWrap .row .col-lg-3.col-sm-6 a .blogContent {
    height: 100%;
}

.blogWrap .row .col-lg-3.col-sm-6 a .blogContent .bInfo h5 {
    min-height: 50px;
}

.blogWrap .row .col-lg-3.col-sm-6 a .blogContent .bInfo div {
    min-height: 80px
}

.rating-stars div {
    display: flex;
    justify-content: center;
}


.file_up {
    display: flex;
    flex-direction: column;
    align-items: center
}

.file_up input[type="file"] {
    display: none;
}

.custom-file-upload {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.file_up p {
    color: #734d9d;
    font-size: 20px;
    margin-bottom: 5px;
}

.file_up span {
    /* color: #000; */
    font-size: 14px;
    margin-bottom: 20px;
}


.modal-item-title {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.modal-item input[type="text"] {
    border: 1px solid #dedede;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    height: 45px;
    flex: 1 1 auto;
}

.modal-item select {
    background-position: 98% 18px
}

.BCDView table td {
    display: flex
}

.BCDView table td a {
    margin-left: auto;
    margin-top: 0 !important
}

.BCDView table th,
.BCDView table td {
    vertical-align: middle !important
}

.packageInfo .packageInfo {
    flex: 0 0 100%;
    width : 100%
}

.packageInfo .packageInfo .packagedropdownbutton {
    width: 120px
}

.packageInfo .packageInfo .packagedropdownbutton .dropdown h6 {
    color: #0e0e0e
}

.rating-stars div {
    display: flex
}

.rating-stars div span {
    flex: 0 0 75px;
    color: gray;
    text-align: center
}

.rating-stars div.label span {
    font-size: 14px;
    color: #ccc
}

.coaching_area {
    justify-content: center
}

.coaching_area .custom-checkbox {
    flex: 0 0 auto;
    min-width: 30%;
    margin-bottom: 10px
}

/* .tui-full-calendar-weekday-grid-line {box-shadow: 0 0 15px rgba(135, 78, 160, .16) inset} */
.schduleCalendar .tui-full-calendar-weekday-grid-header span .tui-full-calendar-weekday-grid-date {
    color: #2a3839 !important;
    font-weight: 500;
    font-size: 18px
}

.schduleCalendar .tui-full-calendar-holiday-sun .tui-full-calendar-weekday-grid-header span .tui-full-calendar-weekday-grid-date {
    color: #ff0000 !important;
}

.schduleCalendar .tui-full-calendar-month-week-item .tui-full-calendar-today .tui-full-calendar-weekday-grid-date-decorator {
    color: #fff !important;
}
.schduleCalendar .tui-full-calendar-month-week-item .tui-full-calendar-today .tui-full-calendar-weekday-grid-date-decorator {
    background: #00e1ba;
}
.schduleCalendar .schduleCalendar .tui-full-calendar-layout {
    border: 1px solid #ccc;
    box-shadow: 0 5px 15px #d4d4d4
}

.schduleCalendar .schduleCalendar .dropdown-toggle {
    margin-bottom: 15px
}

.schduleCalendar .schduleCalendar .dropdown-menu {
    box-shadow: 0 3px 5px rgba(135, 78, 160, 0.459);
    padding: 15px 5px;
}

.schduleCalendar .schduleCalendar .dropdown-menu .dropdown-divider {
    display: none;
}

.schduleCalendar .schduleCalendar .dropdown-menu li {
    padding: 5px 0;
}

.schduleCalendar .schduleCalendar .dropdown-menu li a i {
    vertical-align: top;
    margin: 5px 10px 5px 0;
    color: #874ea0
}

.booking__calendar .move-day {
    text-decoration: none !important;
    background: #00e1ba;
    display: inline-block;
    padding: 5px 10px;
    text-transform: uppercase;
    border-radius: 5px;
    color: #fff !important;
    border: none;
    transition: 0.3s;
    box-shadow: 0px 0px 6px rgb(119 119 119 / 34%);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    margin-bottom: 12px;
    margin-right: 5px
}
.disablebutton button {
    opacity: .5;
    display: inline-block;
    padding: 10px 30px;
    color: #fff !important;
    border: none;
    background-color: #6c757d;
    border-radius: 0.25rem;
}

.disablebutton button:hover {
    background-color: #5a6268;
}

.booking__calendar .move-day i {
    vertical-align: top;
    margin: 5px 0;
}

.booking__calendar .render-range {
    margin-left: 15px;
}

.freeTrialDays button {
    line-height: 14px;
}

.BCDView table td {
    justify-content: flex-end;
}

.bookingCalendar .react-calendar .react-calendar__tile--active.inactiveClass {
    background: #fff;
    color: #848484 !important;
}

.bookingCalendar .react-calendar .react-calendar__tile--now.inactiveClass {
    background: #fff;
    color: #848484 !important;
}

.bookingCalendar .react-calendar .react-calendar__tile--now {
    background: none;
    color: #848484 !important;

}

.bookingCalendar .react-calendar .react-calendar__tile--active.tilesBlock {
    background: #f0f0f0;
    color: #848484 !important;
}

.bookingCalendar .react-calendar .react-calendar__tile--active {
    background: #7ac041 !important;
    color: #fff !important;
}


.bookingCalendar .react-calendar .react-calendar__tile--now.tilesBlock {
    background: #f0f0f0;
    color: #848484 !important;
}

.bookingCalendar .react-calendar .react-calendar__tile--now.tilesToday {
    background: #7ac041;
    color: #fff !important;
}

.bioDetails span.addTag {
    position: absolute;
    top: -50px;
    right: 0px;
    visibility: visible;
    /* visibility: hidden; */
    /* opacity: 0;
    transition: 0.3s all; */
}

/* .bioDetails:hover span.addTag {
    visibility: visible;
    opacity: 1;
    transition: 0.3s all;
} */

.thankYouWrap {
    padding: 100px 0px 60px;
    background: #f5f5f5;
}
.dropdown_action button,
.dropdown_action.show>.btn-success.dropdown-toggle {
    background: transparent;
    border: none;
    padding: 0;
}

.dropdown_action button:hover,
.dropdown_action button:focus,
.dropdown_action button:active {
    background: transparent !important;
    box-shadow: none !important;
    border: none;
}

.dropdown_action.show>.btn-success.dropdown-toggle:focus {
    box-shadow: none;
}


.registrationForm .form-group .selectBox .custom-select {
    border-left: none;
    height: 45px;
    max-width: 72px;
    padding-right: 15px;
    padding-left: 2px;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    background-image: none;
    background-color: #fff
}

.registrationForm .form-group .selectBox .custom-select:focus {
    box-shadow: none;
    border-color: #e0e0e0
}

.registrationForm .selectBox {
    position: relative;
    border: none;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}

.registrationForm .selectBox>span {
    position: absolute;
    top: 9px;
    right: 4px;
}


.logreg_or {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    background: #fff;
    font-size: 18px;
    font-weight: 600;
    position: absolute;
    left: -25px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    background: #fff;
    z-index: 9;
    color: #00e1ba;
    box-shadow: 0 0 15px rgb(18 76 66 / 12%);
    text-align: center;
    line-height: 50px;
}

.freetrial-badge {
    background: #00e1ba;
    border-radius: 50px !important;
    -webkit-border-radius: 50px !important;
    -moz-border-radius: 50px !important;
    -ms-border-radius: 50px !important;
    -o-border-radius: 50px !important;
}


.freetrial-badge {
    background: #00e1ba;
    border-radius: 50px !important;
    -webkit-border-radius: 50px !important;
    -moz-border-radius: 50px !important;
    -ms-border-radius: 50px !important;
    -o-border-radius: 50px !important;
}

.ant-drawer-content-wrapper {
    height: 145px !important;
}

.ant-drawer.show .ant-drawer-content-wrapper {
    box-shadow: 0 -30px 25px rgba(255, 255, 255, .16) !important
}

.ant-drawer-content-wrapper .ant-drawer-content {
    background: #000;
    font-size: 25px;
    color: #fff;
    z-index: 99;
}

.zsiq_floatmain.zsiq_theme1.siq_bL {
    z-index: 9 !important;
    right: 10px;
    left: unset;
    bottom: 4rem;
}
.zsiq-newtheme.siq_lft {
    left: auto !important;
    right: 10px !important;
}

.ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-body {
    font-size: 18px;
}

.ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-body button {
    float: right;
    text-decoration: none !important;
    background: #00e1ba;
    display: inline-block;
    padding: 8px 30px;
    text-transform: uppercase;
    border-radius: 25px;
    color: #fff !important;
    border: none;
    transition: 0.3s;
    box-shadow: 0px 0px 6px rgb(119 119 119 / 34%);
    font-size: 15px;
}


.ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-body button:hover {
    color: #00e1ba !important;
    background: #fff;
    transition: 0.3s all;
}

.ant-drawer-header {
    background: #000 !important;
    border-bottom: 1px solid #fff;
}

.ant-drawer-title {
    color: #fff !important;
    font-size: 20px !important;
    font-weight: 800 !important
}

.ant-drawer-header svg {
    fill: #00e1ba;
    width: 25px;
    height: 25px;
}

.ant-drawer-close {
    padding: 14px
}

.facebookbutton {
    width: 260px;
    background: #3b5998;
    margin-bottom: 12px;
    padding: 10px 0px;
    border-radius: 25px;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    transition: all .2s ease-in-out;
    color: #fff;
    cursor: pointer;
    border: none;
    font-size: 14px;

}

.facebookbutton img {
    width: 14px;
    height: 18px;
    fill: #fff;
    vertical-align: top;

    margin-left: 5px;
    margin-right: 10px
}

.googlebutton {

    width: 260px;
    background: #fff;
    margin-bottom: 12px;
    padding: 10px 0px;
    border-radius: 25px;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    transition: all .2s ease-in-out;
    color: rgb(36, 34, 34);
    cursor: pointer;
    border: none;
    font-size: 14px;
}

.googlebutton img {
    width: 18px;
    height: 18px;
    fill: #fff;
    vertical-align: top;
    margin-right: 7px;

}

.faq_head h2 {
    font-size: 1.2rem;
    font-weight: 500;
}


.dropdown_action button,
.dropdown_action.show>.btn-success.dropdown-toggle {
    background: transparent;
    border: none;
    padding: 0;
}

.dropdown_action button:hover,
.dropdown_action button:focus,
.dropdown_action button:active {
    background: transparent !important;
    box-shadow: none !important;
    border: none;
}

.dropdown_action.show>.btn-success.dropdown-toggle:focus {
    box-shadow: none;
}

.userBookingList.trans_his ul.bkdt-l {align-items: flex-start}
.userBookingList.trans_his ul.bkdt-l li:nth-child(1){
    flex:0 0 8%
}
.userBookingList.trans_his ul.bkdt-l li:nth-child(2){
    flex:0 0 20%;padding: 0 5px;
}
.userBookingList.trans_his ul.bkdt-l li:nth-child(3){
    flex:0 0 16%
}
.userBookingList.trans_his ul.bkdt-l li:nth-child(4){
    flex:0 0 15%;padding: 0 5px;
}
.userBookingList.trans_his ul.bkdt-l li:nth-child(5){
    flex:0 0 8%
}
.userBookingList.trans_his ul.bkdt-l li:nth-child(6){
    flex:0 0 16%
}
.userBookingList.trans_his ul.bkdt-l li:nth-child(7){
    flex:0 0 11%
}
.userBookingList.trans_his ul.bkdt-l li:nth-child(8){
    flex:0 0 100%
}



.bookingCocachDetails.booking_list ul {
    align-items: flex-start
}

.bookingCocachDetails.booking_list ul li:nth-child(1) {
    flex: 0 0 8%
}

.bookingCocachDetails.booking_list ul li:nth-child(3){
    flex:0 0 16%;
    padding: 0 5px;
}
.bookingCocachDetails.booking_list ul li:nth-child(4){
    flex:0 0 15%
}

.bookingCocachDetails.booking_list ul li:nth-child(5) {
    flex: 0 0 15%
}
.bookingCocachDetails.booking_list ul li:nth-child(6){
    flex:0 0 20%;
    padding: 0 5px;
}

.bookingCocachDetails.booking_list ul li:nth-child(7) {
    flex: 0 0 10%
}
.coachingWrap .CoachingItem {
    margin-bottom: 0;
    width: 100%;
}
.bookingCocachDetails.personalTransaction ul {
    align-items: flex-start;
    text-align: left
}

.bookingCocachDetails.personalTransaction ul li:nth-child(1) {
    flex: 0 0 6%
}

.bookingCocachDetails.personalTransaction ul li:nth-child(2) {
    flex: 0 0 10%
}

.bookingCocachDetails.personalTransaction ul li:nth-child(3) {
    flex: 0 0 13%;
    padding: 0 5px;
}
.bookingCocachDetails.personalTransaction ul li:nth-child(4){
    flex:0 0 24%;
    padding: 0 5px;
}

.bookingCocachDetails.personalTransaction ul li:nth-child(5) {
    flex: 0 0 20%
}

.bookingCocachDetails.personalTransaction ul li:nth-child(6){
    flex:0 0 11%
}

.bookingCocachDetails.personalTransaction ul li:nth-child(7) {
    flex: 0 0 11%
}

.bookingCocachDetails.personalTransaction ul li:nth-child(8) {
    flex: 0 0 5%
}


.navbar_footer {
    width: 100%;
    padding: 10px;
    position: fixed;
    top: 1200px;
    transition: top 0.6s;
  }
  
  .navbar_footer--hidden {
    top: 500px;
  }


.timer {display: inline-block !important;}




.bookingCocachDetails.paymentrecieved_coach ul {
    align-items: flex-start;
}
.bookingCocachDetails.paymentrecieved_coach ul li:nth-child(2){
    flex:0 0 16%
}
.bookingCocachDetails.paymentrecieved_coach ul li:nth-child(3){
    flex:0 0 18%;
    padding: 0 5px;
}
.bookingCocachDetails.paymentrecieved_coach ul li:nth-child(4){
    flex:0 0 20%;
    padding: 0 5px;
}

.bookingCocachDetails.paymentrecieved_coach ul li:nth-child(5) {
    flex: 0 0 15%
}

.hourlyrateclass .custom-control {
    z-index: 0;
}


/* package css */
  span.p_divider {
    display: inline-block;
    width: 242px;
    background: #00e1ba;
    height: 2px;
    margin: 20px 0px 30px;
    position: relative;
  }
  
  span.p_divider::after {
    content: "";
    left: 10px;
    top: 4px;
    width: 100%;
    background: inherit;
    height: 2px;
    position: absolute;
  }
  
  .yearly_monthly {
    font-size: 24px;
}
  
  
  .package_item {
    transition: 0.3s ease-in;
    -webkit-transition: 0.3s ease-in;
    -moz-transition: 0.3s ease-in;
    -ms-transition: 0.3s ease-in;
    -o-transition: 0.3s ease-in; 
    padding-left: 1.4rem;
    padding-right: 1.4rem;
  }
  
  
  
  .package_item .package_card{
    transition: all .2s ease-in-out; 
  }
  /* .package_item .package_card:before {
    content: "";
    width: 30px;
    height: 335px;
    left: -30px;
    bottom: -30px;
    position: absolute;
    background: #00e1ba;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-top-right-radius: 0;
    z-index: -1 !important;
} */

/* .package_item .package_card::after {
    content: "";
    width: 85%;
    height: 30px;
    left: -30px;
    bottom: -30px;
    position: absolute;
    background: #00e1ba;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-top-right-radius: 0;
    z-index: -1 !important;
} */

/* .package_item.active .package_card:before, 
.package_item.active .package_card:after {background: #6f42c1; z-index: -1;}  */

/* .package_item .package_card:hover:before, 
.package_item .package_card:hover:after {background: #6f42c1; z-index: -1;} 
  
   */
  
  
/*   
.package_item.active .package_card:before, 
.package_item.active .package_card:after {background: #6f42c1; z-index: -1;} 

.package_item .package_card:hover:before, 
.package_item .package_card:hover:after {background: #6f42c1; z-index: -1;} 
   */
  
   .pricing_container {
    padding: 50px 0 10px;
    background-color: #f7f7f7;
}
  .package_card {
    border-radius: 5px ;
    -webkit-border-radius: 5px ;
    -moz-border-radius: 5px ;
    -ms-border-radius: 5px ;
    -o-border-radius: 5px ;
    background: #fff;
    /* box-shadow: 0 0 18px rgba(0, 0, 0, .19); */
    /* width: 95%; */
    min-height: 330px;
    /* float: right; */
    position: relative;
    z-index: 9;
    margin-top: 65px;
}
  
  
  .packge_head {
    width: 80%;
    height: 105px;
    background: #00e1ba;
    position: relative;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    padding: 15px 0;
}
  
  .packge_head:after {
    position: absolute;
    left: 50%;
    top: 99%;
    content: "";
    width: 0;
    height: 0;
    border-left: 124px solid transparent;
    border-right: 124px solid transparent;
    border-top: 35px solid #00e1ba;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
  }
  
  .packge_head h3 {
    text-align: center;
    color: #fff;
    font-size: 20px;
}
  
  .packge_head h2 {
    text-align: center;
    color: #fff;
    font-weight: 600;
    letter-spacing: 1px;
    ;
  }
  
  .package_oofers {
    font-size: 16px;
    /* color: #000; */
    padding: 0;
    list-style: none;
    font-weight: 500;
    margin-top: 35px;
    margin-bottom: 35px;
    overflow: hidden;
  }
  
  .package_oofers li {
    border-bottom: 1px solid #f7f7f7;
    padding: 10px 10px 10px 40px;
    font-size: 15px;
    line-height: 1.2;
}
  
  .package_oofers li svg {
    fill: #00e1ba;
    width: 15px;
    height: 15px;
    margin-left: -30px;
    margin-right: 5px;
}
  
.btn-primary:not(:disabled):not(.disabled).active, 
.btn-primary:not(:disabled):not(.disabled):active, 
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #99f3e3;
    border-color: #99f3e3;
}
.btn-primary.packege_buynow {
    width: 80%;
    margin-bottom: 25px;
    margin-left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background-color: #00e1ba;
    border: none;
    font-size: 18px;
    color: #fff;
    border: 1px solid #00e1ba;
}
.package_item.active .btn-primary.packege_buynow {
    background-color: #ffffff;
    color: #00e1ba;
}
  .btn-primary.packege_buynow:hover {
    background-color: #fff;
    color: #00e1ba;
    border-color: #00e1ba;
}
  
  /* .package_item.active .package_card,
  .package_item .package_card:hover {
    background: #fff;
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    margin-bottom: 0px;
  }
   */
 
   .package_item:hover .package_card {
    box-shadow: 0 0 18px rgb(42 56 57 / 20%);
}
  /* .package_item:hover .package_card .packge_head {background: #6f42c1;}
  .package_item:hover .package_card .packge_head:after {border-top: 35px solid #6f42c1;}
  .package_item:hover .package_oofers li svg {fill:#6f42c1}
  .package_item:hover .package_oofers li svg.pk_cross {fill:#f44336}
  .package_item:hover .package_card .btn-primary.packege_buynow {background-color: #6f42c1} */

  .package_item.active .package_card {box-shadow: 0 0 18px rgba(0, 0, 0, .5); }
  /* .package_item.active .package_card .packge_head {background: #6f42c1;}
  .package_item.active .package_card .packge_head:after {border-top: 35px solid #6f42c1;}
  .package_item.active .package_oofers li svg {fill:#6f42c1}
  .package_item.active .package_oofers li svg.pk_cross {fill:#f44336}
  .package_item.active .package_card .btn-primary.packege_buynow {background-color: #6f42c1}
   */
  
  
  /* @media screen and (min-width:992px) {
    .package_item {
      flex: 0 0 31.3333% !important;
      max-width: 31.3333% !important;
    } 
  } */
  
  @media screen and (max-width:991px) {
    .package_list.justify-content-between {
      justify-content: center !important;
    } 
  
    .package_list .package_item {margin-bottom: 90px !important}
  
  }
  
  @media screen and (min-width:992px) and (max-width:1199px) {
    .packge_head::after {
      border-left: 9vw solid transparent;
      border-right: 9vw solid transparent;
    }
  }
  
  @media screen and (min-width:530px) and (max-width:767px) {
    .packge_head::after {
      border-left: 28vw solid transparent;
      border-right: 28vw solid transparent;
    }
  }
  
  @media screen and (min-width:768px) and (max-width:991px) {
    .packge_head::after {
      border-left: 20vw solid transparent;
      border-right: 20vw solid transparent;
    }
  }

  
  
/* package css end   */
.iframepdf{
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 195px;
    box-shadow: 0 0px 0px 0 rgb(0 0 0 / 20%), 0 0px 2px 0 rgb(0 0 0 / 19%);

}

.iframepdf a {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 22;
    top: 0;
    left: 0;
}
.defaultBtn.rc {
    background-color: #fff !important;
    color: #00e1ba !important;
}

.playVideoIcon {
    position: relative;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.playVideoIcon i {
    position:absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.playVideoIcon b {
    position: absolute;
    bottom: 5px;
    right: 5px;
    background-color: #000;
    padding: 2px;
}
/* .newcoach{
   
        top: 54px;
        position: absolute;
        left: 238px;
   
} */


.ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
  }
  .ribbon::before,
  .ribbon::after {
    position: absolute;
    z-index: 1;
    content: '';
    display: block;
    border: 5px solid #2980b9;
  }
  .ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background-color: #3498db;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #fff;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
    text-align: center;
  }
  
  .ribbon-top-left {
    top: -10px;
    left: -10px;
  }
  .ribbon-top-left::before,
  .ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
  }
  .ribbon-top-left::before {
    top: 0;
    right: 0;
  }
  .ribbon-top-left::after {
    bottom: 0;
    left: 0;
  }
  .ribbon-top-left span {
    right: -25px;
    top: 30px;
    transform: rotate(-45deg);
  }

  .fa-ellipsis-h.fa_custom {
    border-radius: 50%;
    padding: 4px;
    padding-left: 3px;
    padding-right: 3px;
    background-color:#00e1ba;
    color: white;
    width: 24px;
    height: 24px;
    display: inline-block;
  }

  .fa-ellipsis-h.fa_custom:hover {
    transition: 0.2s all;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
}
i.fa.fa-star
{
    margin-right: 5px;
}
.color-dark-grey
{
    color: #585656 !important;
   
}

.color-grey
{
    color: #878787 !important;

}

.downLoadIcon-resource {
    height: 34px;
    width: 34px;
    position: absolute;
    top: 10px;
    left: 25px;
    background: #ffffffad;
    padding: 9px;
    border-radius: 7px;
    display: flex;
    cursor: pointer;
}

.react-autosuggest__container{
    position: relative;
}

.react-autosuggest__container--open {
    position: absolute;
    left: 0;
    right: 0;
    top: 30px;
    padding: 5px;
    background-color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .1);
    z-index: 2;
}
.react-autosuggest__container--open ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

}
.react-autosuggest__container--open ul li {
    line-height: 30px;
}
.react-autosuggest__container--open ul li:hover{
    background: rgba(0, 0, 0, .1);
}
    
.reactour__helper {
    color:black !important
}
.introjs-tooltipReferenceLayer{
    color:black !important
}
.highlightedpart{
    background: red;
}
.coachProfileFormEdit textarea {resize: none;}
.coachProfileFormEdit .defaultBtn {margin-bottom: 20px;}


.photo_block, .audio_item {display: block; background: rgba(255,255,255,.3)}
.audiocontrol {width: 100%;}
.audioImg {object-fit: cover !important;}

.blogWrap .blogContent img.img-fluid {
    width: 100% !important;
    object-fit: cover !important;
}

.blogContent a img {
    width: 100%;
    object-fit: cover;
    height: 232px;
}


.intro-video-delete-icon  {
    position: absolute;
    top: 65px;
    right: 78px;
    z-index: 1;
    /* transition: 0.3s all; */
    background: orangered;
    padding: 7px;
    border-radius: 50%;
    width: 37.5px;
    height: 37.5px;
    cursor: pointer;
}

.intro-video-delete-icon:hover {
    transition: 0.2s all;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: 0.2s all;
    -moz-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -o-transition: 0.2s all;
}
    
.ant-picker.ant-picker-large {margin-bottom:1rem}
.profilepic-delete-icon {
    position: absolute;
    bottom: 42px;
    left: 25px;
    z-index: 1;
    background: orangered;
    padding: 7px;
    border-radius: 50%;
    width: 37.5px;
    height: 37.5px;
    cursor: pointer;
    color: #fff;
}
.profilepichome-delete-icon  {
    position: absolute;
    bottom: 60px;
    right: 15px;
    z-index: 1;
    /* transition: 0.3s all; */
    background: orangered;
    padding: 7px;
    border-radius: 50%;
    width: 37.5px;
    height: 37.5px;
    cursor: pointer;
}
.profilepic-delete-icon:hover {
    transition: 0.2s all;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
}

/* .tourButton{
    background: #734d9d;
    color: white !important;
    font-weight: bolder;
    border-radius: 10px;
    padding: 13px !important;
    margin-top: 5px;
} */
.tourPrevButton,.tourNextButton{
    padding: 7px 16px;
    background: none;
    border: 2px #007aff solid;
    color: #007aff;
    font-weight: bold;
    border-radius: 3px;
}
.tourPrevButton:hover,.tourNextButton:hover{
   background: #007aff;
   color: #fff;
}
.tourLastButton
{
    padding: 7px 16px;
    background: none;
    border: 2px #8ae33f solid;
    color: #8ae33f;
    font-weight: bold;
    border-radius: 3px;
}
.tourLastButton:hover{
    background: #8ae33f;
    color: #fff;
 }

 .tourButtonDisable
{
    padding: 7px 16px;
    background: none;
    border: 2px #e1e6eb solid !important;
    color: #e1e6eb !important;
    background: none !important;
    cursor: not-allowed !important;
    font-weight: bold;
    border-radius: 3px;
}
.reactour__helper{
    
    max-width: 250px !important;
    height: auto;
}


 


.bookingCocachDetails ul li:nth-child(1) { width: 10%; }
.bookingCocachDetails ul li:nth-child(2) { width: 20%; }
.bookingCocachDetails ul li:nth-child(3) { width: 12%; }
.bookingCocachDetails ul li:nth-child(4) { width: 16%; }
.bookingCocachDetails ul li:nth-child(5) { width: 12%; }
.bookingCocachDetails ul li:nth-child(6) { width: 12%; }
/* .bookingCocachDetails ul li:nth-child(7) { width: 8%; } */
.payment_details_tab ul li:nth-child(6) { width: 100%; }

@media screen and(max-width:450px) {
    .tui-full-calendar-month-dayname .tui-full-calendar-month-dayname-item {padding-left:0 !important}
}


.justJoined {
    background: #9b59b6;
    float: left;
    padding: 2px 8px;
    border-radius: 17px;
    font-size: x-small;
    font-weight: 400;
    color: #fff;
    position: absolute;
    left: 15px;
    top: 15px
}

.rating__area div .react-stars span { margin-right: 15px}

.dv-star-rating-star { font-size: 30px; margin: 0 35px; }

.rating_label span {flex:0 0 100px; color:rgba(116,77,158,1); font-size: 18px; font-weight: 600;}

.rating__input .form-control {resize: none; border-radius: 20px; box-shadow: 0 5px 5px rgba(0,0,0,.1);}

.reating_head {color:rgba(116,77,158,1); font-size: 22px; font-weight: 800; }
.reating_head span {color:#00e1ba }

@media (max-width: 575px) {
    .userBookingList.trans_his .no-records li:nth-child(1) {
        flex: 0 0 100% !important;
    }
}

.tui-full-calendar-popup-detail .tui-full-calendar-schedule-title { word-break: initial !important;}

.yourDetails{
    color: #39bfa4  !important;
    opacity: 0.8 !important;
    color: black !important
}
.cover {position: fixed; left:0; top:0; right:0; bottom:0; background:rgba(0,0,0,0.9); z-index: 9999; display: flex; align-items: center; justify-content: center;}

.top_rating span{
    flex: 0 0 150px;
}
.top_rating > div {
    width: 100%;
}
.top_rating > div > div{
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}

/* Manage subscription css */
.subscription_card {
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    min-width: 500px;
    max-width: 500px;
    padding: 15px;
    background: url(../images/card.png) center center no-repeat;
    background-size: cover;
}

.subscription_card li {
    background: none;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    box-shadow: none;
    margin: 15px 0;
    font-size: 16px;
    color: rgb(184, 182, 182);
}

.subscription_card li strong {
    width: 100%;
    display: block;
    font-weight: 800;
    font-family: sans-serif;
    color: #fff;
    font-size: 18px;
}

.savecard {
    box-shadow: 0 0 5px rgba(0, 0, 0, .4);
    background: transparent;
    padding-top: 5px;
    padding-bottom: 5px;
    
}
.my_savecard_div{
    max-height: 300px !important;
    overflow-x: hidden;
    overflow-y: scroll;


}

.savecard img {
    max-width: 30px;
}
.my_savecard .option {
    width: 100%;
    max-width: 500px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    overflow: hidden;
    color:#000;
    margin-bottom: 0;
    
}

.my_savecard .option.blur {
    filter: blur(2px);
    -webkit-filter: blur(2px);
}

.my_savecard .option .dot {
    height: 20px;
    width: 20px;
    background: #d9d9d9;
    border-radius: 50%;
    position: relative;
}

.my_savecard .option .dot::before {
    position: absolute;
    content: "";
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    background: #0069d9;
    border-radius: 50%;
    opacity: 0;
    transform: scale(1.5);
    transition: all 0.3s ease;
}

.my_savecard input[type="radio"] {
    display: none;
}

#option-1:checked:checked~.option-1,
#option-2:checked:checked~.option-2,
#option-3:checked:checked~.option-3,
#option-4:checked:checked~.option-4,
#option-5:checked:checked~.option-5,
#option-6:checked:checked~.option-6,
#option-7:checked:checked~.option-7 {
    border-color: #82bc50;
    background: #82bc50;
    color:#fff
}

#option-1:checked:checked~.option-1 .dot,
#option-2:checked:checked~.option-2 .dot,
#option-3:checked:checked~.option-3 .dot,
#option-4:checked:checked~.option-4 .dot,
#option-5:checked:checked~.option-5 .dot,
#option-6:checked:checked~.option-6 .dot,
#option-7:checked:checked~.option-7 .dot {
    background: #fff;
}

#option-1:checked:checked~.option-1 .dot::before,
#option-2:checked:checked~.option-2 .dot::before,
#option-3:checked:checked~.option-3 .dot::before,
#option-4:checked:checked~.option-4 .dot::before,
#option-5:checked:checked~.option-5 .dot::before,
#option-6:checked:checked~.option-6 .dot::before,
#option-7:checked:checked~.option-7 .dot::before {
    opacity: 1;
    transform: scale(1);
}

/* Manage subscription css END*/

/* Card Scroller */

.my_savecard_div::-webkit-scrollbar {
    width: 5px;        
  }
  
  .my_savecard_div::-webkit-scrollbar-track {
    background: grey;       
  }
  
  .my_savecard_div::-webkit-scrollbar-thumb {
    background-color: black;    
    border-radius: 20px;       
    border: 3px solid black;  
  }
  /* Card Scroller */





.subscription_row ul li{
    width: auto !important; 
}  

.ant-picker-separator {
    position: relative;
    display: inline-block;
    width: 2em !important;
    height: 40px !important;
    color: rgba(0, 0, 0, 0.25);
    font-size: 25px !important;
    vertical-align: top;
    cursor: default;
}

.start_end {
    flex:0 0 87.7%;
     /* margin-bottom: 1em; */
    }
.start_end_button {flex:0 0 11.3%}

.modal-60w {
    min-width:60%;
  }


#calendar {
    min-width: 700px;
}
  /* for hosted payment field */
.disableCardSection{pointer-events:none}
.disableinput{background: #e9ecef !important;}

.buttonSetManageSubscription{
    max-width: 500px;
    min-width: 500px;
}
.horizontalTable li
{
    display: block; 
}
.horizontalTable li b {
    display: inline;
    font-size: 14px;
    color: #734d9d;
    margin: 12px 10px;
}
.horizontalTable li span {
    font-size: 16px;
    color: #616161;
}
.horizontalTable .smallLI
{
    font-size: 11px !important;
    color: #7c58a3;
    font-weight: bold;
}

.cancelSession
{
    background: #dc3545;
    float: left;
    padding: 2px 8px;
    border-radius: 17px;
    font-size: x-small;
    font-weight: 400;
    color: #fff;
}

.freeSubscription
{
    background: #dc3545;
    float: left;
    padding: 2px 8px;
    border-radius: 17px;
    font-size: x-small;
    font-weight: 400;
    color: #fff;
}
.badge{
    white-space:normal !important;
}



/* home page coaches */
.carousel_inner_item{
    max-height:180px;
    overflow: hidden;
}

.carousel_inner_item img{
max-width:100%;
max-height:180px;
height: auto;
display: block;
margin: 0 auto;
object-fit: cover;
}
.carousell_part {
    padding: 50px 0 40px;
}
.carousel_item{
	margin: 0 20px;
	border-radius: 25px;
	overflow:hidden;
}
.car_caption{
	background: #fff;
	padding: 30px 20px 20px 20px;
    min-height: 205px;
}

.car_caption h3{
	font-weight: 300;
	text-transform: uppercase;
	font-size: 25px;
    text-align: center;
}

.car_caption p{
	font-weight: 400;
	text-align: center;
}

.car_caption .R_divider{
	background: #00e1ba;
    display: block;
    width: 100px;
    height: 1px;
    margin: 20px auto 25px;
    position: relative;
}

span.R_divider:after {
    content: "";
    left: 10px;
    top: 3px;
    width: 100%;
    background: inherit;
    height: 1px;
    position: absolute;
}

.coaching-categories {
    min-height: 58px;
}
.button-underneath-text {
    font-size: 18px;
}
.feature-coach.searchPeopleList {
    background: #f5f5f5 ;
}

.feature-coach .sImgHolder {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    position: relative;
    margin-bottom: 15px;
}

.feature-coach .sImgHolder>img.sUserImg {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
}

.feature-coach .sImgHolder img.sbi {
    position: absolute;
    bottom: -5px;
    right: 0px;
}

.feature-coach.searchPeopleList h4 {
    font-size: 19px;
    color: #151515;
    margin-bottom: 0;
    font-weight: 600;
}

.feature-coach.searchPeopleList h6 {
    font-size: 14px;
    margin: 0;
    line-height: 20px;
    font-weight: 400;
}

.feature-coach .coaching-categories {
    min-height: 50px;
}

.feature-coach.searchPeopleList p {
    font-size: 13px;
    line-height: 18px;
    min-height: 55px;
    max-height: 55px;
    overflow: hidden;
    margin-bottom: 45px;
}

.feature-coach .defaultBtn {
    font-size: 15px;
    padding: 8px 10px;
}

.feature-coach .defaultBtn img {
    vertical-align: top;
    top: 0;
}


.footerBottom.space-before-float {
    padding-bottom: 115px;
} 

::placeholder {
    font-family: Arial !important;
}

.container_pk_item .packageInfo {
    padding-top: 36px;
}
/* COOKIE DRAWER DESIGNS */
@media screen and (max-width: 767px) {
    div#titlediv {
        display: none;
    }
}

.zsiq_floatmain.zsiq_theme1.siq_bR {
    z-index: 99 !important;
}



.paymentDetailsView li{
    padding: 10px 0px;
    background: #e7e9ea;
    margin: 10px 0px;
}

.paymentDetailsView {
    padding: 0px 0px;
    border-radius: 10px
    
}
.eyeicon{
    padding-left: 6px;
    vertical-align: text-bottom !important;
}
.card_number { background: #e8ebee; padding:5px 10px; line-height:30px; font-size: 15px; border-radius:5px ; -webkit-border-radius:5px ; -moz-border-radius:5px ; -ms-border-radius:5px ; -o-border-radius:5px ; display: inline-block;}
.card_number strong {color:#000; text-transform: uppercase; font-weight: 600; margin-right:10px}
.card_number .nbox { 
    background: #fff; line-height: 22px; vertical-align: bottom; display: inline-block; letter-spacing: 2px; padding: 5px 10px; border-radius:5px ; -webkit-border-radius:5px ; -moz-border-radius:5px ; -ms-border-radius:5px ; -o-border-radius:5px ; 
    /* color: #000; */
}
.card_number .changeCard { background: #00e1ba; display: inline-block; padding: 3px; line-height: 15px; vertical-align: bottom; padding: 2px 5px; border-radius:5px ; -webkit-border-radius:5px ; -moz-border-radius:5px ; -ms-border-radius:5px ; -o-border-radius:5px ; }
.card_number .changeCard:hover {cursor: pointer;}
.card_number .changeCard img {max-width:30px; vertical-align: bottom; margin: 3px 0;}
.comming-soon-text {
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 3px;
    margin-top: 30px;
    font-weight: 500;
}

.incomplete-kyc-alert {
    background: #ffffff;
    padding: 15px;
    width: 100%;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0px 10px 10px rgb(45 46 46 / 5%);
}
.incomplete-kyc-alert a {
    color: #2a3839;
}
.incomplete-kyc-alert a:hover {
    color: #00e1ba;
}
.ScrollUpButton__Container {
    bottom: 0px !important;
    margin: 0 !important;
    right: 0 !important;
    width: 40px !important;
    height: 40px !important;
    padding: 9px !important;
    line-height: 1;
}

.siq_bR {
    bottom: 80px;
    right: 10px;
}
.user_link{
    font-size: 14px;
    color: #fff !important;
    text-align: center;
    display:block;
    background: #000;
    padding: 5px 10px;
    border-radius:50px
}

.small-device-show {
    display: none;
    font-size: 12px;
    text-align: center;
    margin: 5px 0 !important;
}

.large-device-show{
    text-align: center;
    margin: 5px 0 !important;
}

.ant-picker.ant-picker-large {margin-bottom: 0 !important}
.availabilityViewWrap .form-group {
    margin-top: 5px !important;
}

.rl_event {
    padding: 4rem 0;
    z-index: 1;
    position: relative;
}
.rl_event_text .list-unstyled li {
    margin-bottom: 1rem;
}
.btn_group {
    display: flex;
    flex-wrap: wrap;
}

.subText {
    color: blueviolet;
    font-size: 110%;
  }
  .subText:hover {
    color: blue;
  }
  .ImgPS {
    width: 140px;
    padding: 20px 0px 5px 0px;
    height: "120px";
  }
  
  .boxBorderPS:hover {
    background-color: rgb(219, 243, 215);
    cursor: pointer;
    font-size: 150%;
  }
  .multiSelect {  
    width: 100% !important;
    box-shadow: 0 0 15px #74529f;
    padding: 12px 20px;
    border: 1px solid rgb(146, 146, 146);
  }
  .deletIcon {
    height: 20px;
    width: 12px;
  }
  
  .deletIcon2 {
    height: 34px;
    width: 34px;
    position: absolute;
    top: 10px;
    left: 25px;
    background: #ffffffad;
    padding: 4px;
    border-radius: 7px;
    display: flex;
  }

.crCard .table {
    font-size: .9rem;
    color: #48515e;
}
.crCard .table td, 
.crCard .table th {
    padding: 0.7rem 0.5rem;
}
.btn_group .defaultBtn {
    margin-bottom: 0.5rem;
}

.react-tel-input .form-control {
    max-width: 100%;
    width: 100% !important;
}
.booking__calendar {
    background-color: #fff;
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0px 10px 20px rgb(20 34 32 / 6%);
}

.feature_bottom.sb_static {
    display: block;
}
.feature_bottom.sb_static > [class*="col-"] {
    float: left;
}
.feature_bottom.sb_static:after, 
.feature_bottom.sb_static:before {
    clear: both;
    display: table;
    content: "";
}
.sb_static .col-md-6 .featuresItem {
    height: 35rem;
}
.sb_static .fi_content {
    position: absolute;
    inset: auto 0 0 0;
    padding: 1rem;
}
.sb_static .col-md-3 .featuresItem {
    height: 16.6rem;
}
.sb_static .featuresItem h4 {
    font-size: 1.1rem;
    line-height: 1.4;
}
.sb_static .col-md-6 .featuresItem h4 {
    font-size: 1.6rem;
}
.sfy p {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.8;
}

.custom_carousel .card{padding-top:80px!important; max-height: none !important; border: 0!important; box-shadow: initial !important;}
.custom_carousel .card-header{height: 120px !important; background-size: contain !important; background-repeat: no-repeat !important; text-align: center!important; margin-top: 0px!important; border: 1px solid #d9d9d9!important;}
.custom_carousel .card-header img{height: 160px!important; width: 160px!important; border-radius: 50%!important; margin: -80px auto 0 auto!important}
.custom_carousel .card-body{border: 1px solid #d9d9d9!important; border-top: 0!important;}
.custom_carousel .card-body h2{font-weight: bold!important; font-size: 24px!important; margin: 0!important}
.custom_carousel .card-body .text-secondary{font-weight: 600 !important; font-size: 14px !important; line-height: 1.2 !important;}
.custom_carousel .card-body .d-grid{margin-bottom:0!important}
.custom_carousel .card-body .d-grid p{font-weight: 500 !important; font-size: 14px !important; min-height: 200px !important; margin: 0!important;}

.custom_widget .sk_branding {
    display: none !important;
    visibility: hidden;
}

.custom_landing_button button{
    margin: 5px;
    background-color: #000 !important;
    color: #00e1ba !important;
    border: none;
    font-size: 13px;
    border-radius: 20px;
}


  .custom-recaptcha-container iframe {
    width: 248px;
    height: 62px;
    border-radius: 43px;
}

.rc-anchor-center-item .rc-anchor-checkbox-label{
    font-size: 9px !important;
}


.starttoday h4{font-weight: bold!important; font-size: 25px!important; margin: 0!important;}

.starttoday input[type=email]{padding: 10px!important; font-size: 16px!important; border-radius: 0px!important; border: 0!important; height: 45px!important}
.starttoday .custom-recaptcha-container div{height: initial!important;width: auto!important; height: 45px!important; overflow: hidden!important; border-radius: 0px!important;}
.starttoday button{margin: 0 !important; font-size: 13px!important; font-weight: bold!important;}
.starttoday .custom_google_button button{height: initial!important; background: initial!important; border: 2px solid #fff!important; box-shadow: initial!important; border-radius: 0px!important; font-size: 16px!important; width: auto!important; padding: 8px 10px!important;}
.starttoday .custom-recaptcha-container iframe{position: relative !important; top: -14px !important;border-radius: 0 !important;}

.custom_widget iframe{
    width:101% !important;
}
@media(max-width:767px){
    .starttoday .custom_google_button button{margin:10px auto 0 auto!important}
    .custom-recaptcha-container iframe {
        width: 100% !important;
    }

    .registrationWrapNew.registrationWrap{
        padding:30px 0 !important;
    }
}