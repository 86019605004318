.c_banner {
    /* margin-top: 4.2rem; */
    height: 85vh;
    padding: 4rem 0;
}
.cb_text h2 {
    font-size: 3rem;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 1.5rem;
}
.cb_text p {
    font-size: 1.2rem;
    color: #3C4442;
}

.access_coaches {
    padding: 4rem 0;
    background: url(./../images/wellness_bg.jpg) 0 0 / cover no-repeat;
}
.ac_text h2 {
    font-size: 2.2rem;
    font-weight: 600;
}

.c_services {
    position: relative;
    z-index: 1;
    padding: 4rem 0;
}
.c_services:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: -1;
    background: url(./../images/services_bg.jpg) 0 0 / cover no-repeat ;
    pointer-events: none;
    background-attachment: fixed;
}
.csi_card {
    margin: 20px 0;
}
.csi_card h4 {
    font-size: 1.2rem;
    margin-top: 1.5rem;
    margin-bottom: 0;
    color: #fff;
    line-height: 1.5;
}
.csi_card img {
    border-radius: 0.8rem;
    height: 375px;
    object-fit: cover;
    object-position: center;
}
.cs_heading > .cs_title {
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    margin: 0;
}
.cs_heading > .css_title {
    font-size: .9rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.hww {
    background-color: #F6F6F6;
    padding: 4rem;
}
.list-unstyled.listTypeBox li + li {
    margin-top: 0.8rem;
}
.list-unstyled.listTypeBox li {
    position: relative;
    padding-left: 1.4rem;
}
.list-unstyled.listTypeBox li:after {
    content: "";
    position: absolute;
    left: 0.15rem;
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 0.2rem;
    top: 0.43rem;
    background: linear-gradient(129.35deg, #1C64BF 1.84%, #25FCCD 97.74%);
}
.list-unstyled.listTypeBox li:before {
    content: "";
    position: absolute;
    left: 0;
    width: 1rem;
    height: 1rem;
    border-radius: 0.3rem;
    top: 0.3rem;
    background: linear-gradient(129.35deg, #25FCCD 1.84%, #1C64BF 97.74%);
}

.programs {
    padding: 4rem 0;
    background: url(./../images/program_bg.jpg) 0 0 / cover no-repeat;
}

/* 
.llyl {
    padding: 4rem 0;
    background: url(./../images/llyljpg.jpg) 0 0 / cover no-repeat;
}
.llyl_video .react-player {
    border-radius: 0.8rem;
    overflow: hidden;
} */

.flexible_programs {
    padding: 4rem 0;
    background-color: #F6F6F6;
}
.fp_img {
    padding-left: 8rem;
}

.support_employe {
    padding: 4rem 0;
    background: url(./../images/ttt_bg.jpg) 0 0 / cover no-repeat;
}

.cpSlider .carousel-item img {
    width: 158px;
    height: 158px;
    object-fit: contain;
    object-position: center;
}
.c_partners {
    padding: 3rem 0;
}