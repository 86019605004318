.table-responsive .dropdown {
    position: static !important;
}

.ac_drop .dropdown-toggle:after,
.ac_drop .dropdown-toggle:before {
    display: none;
}

.crCard.card {
    border-radius: 10px;
    box-shadow: 0 5px 20px rgb(35 46 62 / 6%);
}
.crCard.card .card-header {
    border-radius: 0.6rem 0.6rem 0 0;
}
.crCard.card .card-footer {
    border-radius:0 0 0.6rem 0.6rem ;
}
.ac_drop .dropdown-toggle {
    width: 2rem;
    height: 2rem;
    padding: 0;
    line-height: 2rem;
    box-shadow: none !important;
    color: #6a7483;
    border-radius: .6rem;
}

.ac_drop .dropdown-toggle:hover,
.ac_drop .dropdown-toggle:focus {
    background-color: #ebeff5;
}

.ac_drop .dropdown-menu {
    box-shadow: 0px 2px 30px rgb(35 46 62 / 12%);
    border-radius: 0.6rem;
}
.dropdownButton.btn-primary {
    color: #212529 !important;
    background-color: transparent !important;
    border-color: transparent !important;
}

.bulk_upload i.fa.fa-check {
    color: #00e1ba;
}

.bulk_upload i.fa.fa-times {
    color: #e10000eb;
}
.invites_grid {
    margin-bottom: 2rem;
}
.invites_grid .card {
    background: url(./../images/bg_06.jpg) center center no-repeat;
    background-size: cover;
}
.invites_grid .card h2 {
    font-size: 2.8rem;
    margin: 0;
    font-weight: 600;
}
.invites_grid .card h5 {
    font-size: 1.2rem;
    color: #454a52;
}