.coachProfileFormEdit.cpe_sm .coachImg.mb-4 {
    width: 140px;
    margin-top: 0;
}
.coachProfileFormEdit.cpe_sm .coachImg>img {
    width: 140px;
    height: 140px;
}

@media screen and (max-width:991px) {
    .profile_wrap .coachProfileFormEdit {
        flex: 0 0 80%;
        max-width: 80%;
    }
}


@media screen and (max-width: 480px){
    .profile_wrap .coachProfileFormEdit {
        flex: 0 0 100%;
        max-width: 100%;
    }
}