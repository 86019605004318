.cwCard.card {
    border-radius: 0;
    background-color: transparent;
}
.cwCard .card-title {
    font-weight: 600;
    font-size: 1.2rem;
    color: #2a3839;
}
.cwCard .card-title:hover {
    color: #00d3ae;
}
.cwCard > a {
    overflow: hidden;
    height: 220px;
    border-radius: 0.4rem;
    position: relative;
}
.cwCard > a .img-fluid {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: transform .3s ease;
}
.cwCard > a:hover .img-fluid {
    transform: scale(1.05);
}
.cwCard .card-text {
    color: #505a5a;
}
.cw_new {
    position: absolute;
    right: -2.2rem;
    top: 0.5rem;
    background-color: #ffce2e;
    padding: 0.3rem 0.5rem 0.2rem;
    color: #000000;
    line-height: 1;
    font-size: .9rem;
    transform: rotate(45deg);
    width: 7rem;
    text-align: center;
    box-shadow: -4px 7px 8px rgb(0 0 0 / 9%);
    font-weight: 600;
}
.cwCard .btn-secondary {
    padding: 0.5rem 1.5rem;
    line-height: 1;
    text-transform: uppercase;
}
