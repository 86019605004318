.amp h2 {
    font-weight: 600;
    color: #00e1ba;
}

.saved_card_item .form-label {
    border: 1px solid #dee4eb;
    display: block;
    border-radius: 0.3rem;
    padding: 0.5rem 0.5rem 0.5rem 2rem;
    background-color: #fafcff;
    cursor: pointer;
    position: relative;
}

.saved_card_number {
    font-weight: 600;
}

.saved_card_number,
.saved_card_expiry {
    line-height: 1;
}

.saved_card_item .form-label:before {
    content: "";
    position: absolute;
    left: 0.5rem;
    top: 50%;
    width: 1rem;
    height: 1rem;
    background-color: #b3bdcd;
    border-radius: 50%;
    transform: translateY(-50%);
}

.saved_card_item .form-label:after {
    content: "";
    position: absolute;
    left: 0.5rem;
    top: 50%;
    width: 1rem;
    height: 1rem;
    background: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>") center center no-repeat;
    border-radius: 50%;
    transform: translateY(-50%);
}

.saved_card_item .form-label:hover {
    border-color: #007bff;
}

.saved_card_item input:checked~.form-label {
    border-color: #007bff;
}

.saved_card_item input:checked~.form-label:before {
    background-color: #007bff;
}

.payment_form .form-group .form-label {
    font-size: .9rem;
    color: #6d788f;
    margin: 0;
}

.wallet_card h2 {
    font-size: 2.8rem;
    font-weight: 600;
}

.wallet_card h4 {
    font-size: 1rem;
    color: #69717a;
}

.wallet_card h6 {
    color: #69717a;
}
