.webiner_start_title {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.2;
  color: #181b15;
}
.webiner_name {
  font-weight: 700;
  font-size: 1.04rem;
  line-height: 1.4;
  color: #00e1ba;
  margin-bottom: 10px;
  display: inline-block;
  position: relative;
  text-transform: capitalize;  
}
h3.webiner_name {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.webinar_description {
  text-transform: capitalize;
}
.cw_type {
  display: inline-block;
  border: 1px solid rgb(255 255 255 / 41%);
  padding: 0.35rem 1rem 0.2rem;
  line-height: 1;
  border-radius: 1.2rem;
  background-color: rgb(255 255 255 / 10%);
  text-transform: uppercase;
  font-size: .8rem;
}
.webiner_name:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #fff;
  top: 100%;
  left: 0px;
}
.countdown_number {
  flex: 0 0 4rem;
  max-width: 4rem;
  position: relative;
  text-align: center;
}
.countdown_number + .countdown_number:after {
  content: "";
  position: absolute;
  left: -1.3rem;
  top: 1.4rem;
  width: 0.8rem;
  height: 1.5rem;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3IiBoZWlnaHQ9IjIzIj48Y2lyY2xlIGN4PSIzLjUiIGN5PSIzLjUiIHI9IjMuNSIvPjxjaXJjbGUgY3g9IjMuNSIgY3k9IjE5LjUiIHI9IjMuNSIvPjwvc3ZnPg==") center center no-repeat;
  background-size: contain;
}

.countdown_number + .countdown_number {
  margin-left: 1.8rem;
}
.countdown_number > small {
  color: #384b4c;
}
.count_up {
  height: 4rem;
  width: 4rem;
  display: block;
  text-align: center;
  line-height: 4rem;
  background-color: #74ffe6;
  border-radius: 0.5rem;
  font-size: 2rem;
  font-weight: 600;
  color: #263232;
}
.inactive .count_up {
  background-color: #ccf9f1;
  color: #9baeac;
}
.inactive .countdown_number > small {
  font-weight: 600;
  color: #b5b5b5;
}
.inactive .countdown_number:after {
  opacity: 0.4;
}

.card_style_1 .card-header {
  background-color: transparent;
  border-color: #dee9e7;
  padding: 1rem 1.25rem;
}
.card_style_1.ws_started {
  background-color: #acf5e8;
}
.ws_started .defaultBtn {
  color: #142421 !important;
}

.wb_row {
  display: flex;
  flex-wrap: wrap;
}
.wb_image {
  flex: 0 0 100px;
  max-width: 100px;
}
.webiner_start {
  flex: 1 1;
  padding-left: 1rem;
}
.wb_image > img {
  width: 95px;
  height: 95px;
  border-radius: 0.8rem;
  background-color: #fff;
  padding: 0.4rem;
  border: 1px solid #bfdbd6;
  object-fit: cover;
}
.wb_c {
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.search_webiner .input-group-text {
  background-color: #fff;
  border-width: 0 0 0 1px;
  padding: 0 1.4rem;
  display: block;
  line-height: 38px;
  margin-left: 0 !important;
  cursor: pointer;
}
.filter_webiner .btn {
  white-space: nowrap;
  padding: 0.5rem 1.5rem;
}
.search_webiner.input-group {
  overflow: hidden;
}
.filter_webiner {
  padding-left: 1.2rem;
}
.starting_datepicker {
  height: 48px;
  padding: 0 15px;
  border-bottom: 1px solid #969696;
  width: 100%;
  border: 1.5px solid #dedede !important;
  border-radius: 6px;
}
.search_webiner.input-group,
.filter_webiner .btn,
.filter_webiner .css-yk16xz-control, 
.filter_webiner .css-1pahdxg-control {
  border: 1px solid #d5e0e9 !important;
  border-radius: 0.25rem;
}
.search_webiner .input-group-text:hover {
  background-color: #00e1ba;
  color: #fff;
}
.css-1wa3eu0-placeholder {
  color: #6c7593 !important;
}
.search_webiner .form-control,
.search_webiner.input-group,
.filter_webiner .btn {
  height: 42px;
}
.filter_webiner .css-yk16xz-control, 
.filter_webiner .css-1pahdxg-control {
  min-height: 42px;
}
.search_webiner .form-control {
  box-shadow: none !important;
}
.filter_webiner .btn:hover,
.filter_webiner .btn:focus {
  background-color: #00e1ba;
  color: #fff;
}
.card_style_1 .card-header .card-title {
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 1.2;
  color: #181b15;
  margin-bottom: 0;
}
.webiner_card > img {
  width: 100%;
  height: 65vh;
  object-fit: cover;
  transition: transform 0.3s ease;
}
.webiner_card:hover > img {
  transform: scale(1.08);
}
.webiner_card {
  border-radius: 0.6rem;
  overflow: hidden;
  position: relative;
  margin: 0.8rem 0;
  min-height: 370px;
}
.webiner_card_content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #0e1c1d 69.14%);
  padding: 3rem 1rem 1rem;
}
.webiner_card_content img {
  width: 60px;
  height: 60px;
}
.webiner_coach_name {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.2;
  text-transform: capitalize;
  color: #00e1ba;
}
.webiner_coach_details {
  line-height: 1.2;
  min-height: 2.5rem;
}

.pagi_web.pagination .page-link {
  width: 2.4rem;
  height: 2.4rem;
  border: 1px solid #d0eae5 !important;
  margin: 0 0 0 0.5rem;
  padding: 0;
  text-align: center;
  line-height: 2.5rem;
  border-radius: 0.6rem !important;
  box-shadow: 3px 4px 6px rgb(40 49 47 / 13%);
  font-weight: 600;
}
.pagi_web.pagination .active .page-link {
  background-color: #00e1ba;
}
.card_style_1 .card-footer {
  background-color: transparent;
  border-color: #dfe9e7;
}
.completed_title {
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.2;
  margin-top: 2rem;
}
.completed_count {
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.2;
  color: #b9b9b9;
  text-align: center;
}
.completed_count > span {
  font-size: 2rem;
}
.completed_progress .ant-progress-circle .ant-progress-text,
.completed_title,
.completed_count > span {
  font-weight: 600;
  color: #00e1ba;
}
.aw_table thead th {
  border-top: 0;
  color: #00e1ba;
}
.aw_table thead th,
.aw_table tbody td {
  padding: 0.4rem;
  text-align: center;
  font-size: 0.9rem;
  vertical-align: middle;
}
.wb_links {
  margin-top: 1rem;
}
.wb_links.row {
  margin-left: -5px;
  margin-right: -5px;
  justify-content: space-between;  
}
.wb_links .col-6 {
  padding-left: 5px;
  padding-right: 5px;
}
.wb_links .btn {
  padding: 0.6rem 1.2rem;
  font-size: 0.9rem;  
  width: 100%;
  line-height: 1;
  font-size:11px;
}
.defaultBtn.btn-bordered {
  background-color: transparent;
  color: #fff;
}
.defaultBtn.btn-bordered:hover {
  background-color: #fff;
}
.apply_filter {
  padding: 1rem;
}
.apply_filter span {
  display: inline-block;
  padding: 0.5rem 1rem;
  line-height: 1;
  border: 1px solid #ccc;
  margin-right: 0.5rem;
  border-radius: 1.5rem;
}

.apply_filter img {
  cursor: pointer;
}
.apply_filter strong {
  cursor: pointer;
  color: #2874f0;
}

.result_pagination ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.result_pagination ul li {
  list-style: none;
}
.result_pagination ul li a {
  width: 2rem;
  height: 2rem;
  margin: 0 0 0 0.5rem;
  padding: 0;
  text-align: center;
  line-height: 2rem;
  border-radius: 0.4rem;
  font-weight: 600;
  display: block;
  background-color: #fff;
  border: 1px solid #edf4f3;
}
.result_pagination ul li:not(.active):not(.disable) a:hover,
.result_pagination ul li.active a,
.result_pagination ul li.active a:hover {
  background-color: #00e1ba;
  color: #fff;
}

.result_pagination ul li.disable a,
.result_pagination ul li.active a {
  cursor: default;
}

@media screen and (max-width: 991px) {
  .countdown_wrap.d-flex {
    justify-content: flex-start !important;
    margin-top: 1rem;
  }
}
@media screen and (max-width: 767px) {
  .slick-prev {
    left: 0px !important;
  }
  .slick-next {
    right: 0px !important;
  }
}
@media screen and (max-width: 480px) {
  .count_up {
    height: 3rem;
    width: 3rem;
    line-height: 3rem;
    font-size: 1.5rem;
  }
  .countdown_number {
    flex: 0 0 3rem;
    max-width: 3rem;
  }
  .countdown_number > small {
    font-size: 0.7rem;
  }
}
