.about_banner img{
    width: 100%;
}

.about_banner{
    position: relative;
}

.about_banner:before{
    position:absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    background: rgba(0,0,0,0.70);
}

.about_inner_text{
    position: absolute;
    width: 100%;
    top:0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about_inner_text h2 {
    color: #fff;
    text-transform: uppercase;
    margin-top: 58px;
    font-size: 50px;
}
.lifeguru_banner_bottom{
    padding-top: 50px;
}
.lifeguru_banner_bottom h2 {
    font-size: 36px;
    text-align: center;
    padding-bottom: 30px;
    margin: 0;
    text-transform: uppercase;
}

.lifeguru_video{
    display: flex;
    /* align-items: center; */
}

.video_left{
    background: url(../images/JaneBlissSorrell.jpg) no-repeat center center;
    /* height: 550px; */
    width: 50%;
    position: relative;
    background-size: cover;
}
.video_left:after{
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.55);
    z-index: 1;
}
.video_right{
    background: url(../images/overlay.jpg) no-repeat;
    /* height: 550px; */
    width: 50%;
    background-size: cover;
    display: inline-flex;
    align-items: center;
}

.video_left_text p{
    /* font-family: 'Source Sans Pro', sans-serif; */
    font-size: 1em;
    color: #fff;
    line-height: 27px;
    position: relative;
    z-index: 3;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: justify;
}

.video_left_text{
    padding: 0px 90px 0 120px;
}

.video_right{
    padding-right: 50px;
    position: relative;
    padding-top: 40.25%;
}


.video_right .react-player{
    margin-left: -60px;
    /* position: relative; */
    z-index: 7;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 80%;
    transform: translateY(-50%);
}

.lifeguru_works {
    padding: 50px 0;
    background-color: #ccf9f1;
}
.work_row{
    align-items: center;
}
.work_left h2 {
    font-size: 36px;
    margin-bottom: 0px;
    padding-bottom: 20px;
}
.work_left h2 span {
    /* color: #00e1ba; */
    font-weight: 700;
}

.how_works_bottom{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.how_work_inner {
    background: #ffffff;
    min-height: 120px;
    width: 49%;
    padding: 15px 10px;
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
}

.how__icon img{
    width: 32px;
    height: 42px;
    margin-right: 10px;
}
.how_work_inner p {
    font-size: 15px;
    font-weight: 500;
    color: #384b4c;
    margin-bottom: 0px;
}

.how_banner{
    border-radius: 7px;
    overflow: hidden;
}

.how_banner .img-fluid{
    width:100%;
    max-height:634px;
    object-fit: cover;
}

.how_bottom__content{
    margin-top: 20px;
}
.how_bottom__content p{
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 25px;
}

.abfeaturesWrap {
    padding: 50px 0;
    background-color: #f7fffe;
}
.abfeaturesWrap h2 {
    font-size: 36px;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 38px;
    margin: 0;
}

.feature_bottom{
    
    display: flex;
    flex-wrap: wrap;
}



.featuresImg {
    background: #fff;
    width: 70px;
    height: 70px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
    z-index: 3;
}
.featuresImg img {
    max-width:40px; 
    max-height:40px
}
.featuresItem p {
    font-size: 15px;
    margin-bottom: 0px;
    color: #fff;
    position: relative;
    z-index: 4;
}
.featuresItem {
    height: 100%;
    background-size: cover !important;
    position: relative;
    background-repeat: no-repeat !important;
    background-position: center !important;
    border-radius: 0.5rem;
    overflow: hidden;
}
.featuresItem.featuresItem2 {
    background-position: -117px 0px !important;
}
.feature_bottom > [class*="col-"] {
    margin: 0.9rem 0;
}
.fi_content {
    padding: 7rem 1.5rem 1.5rem;
}
.featuresItem:after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgb(0 0 0 / 55%);
    width: 100%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgb(15 24 32) 80%);
}
.featuresItem h4 {
    color: #fff;
    position: relative;
    z-index: 4;
    font-size: 1.4rem;
    line-height: 1.2;
    font-weight: 500;
}
.feature_bottom > div:nth-child(1) .featuresItem {
    background: url(../images/KF1.jpg);
}
.feature_bottom > div:nth-child(2) .featuresItem {
    background: url(../images/KF2.jpg);
}
.feature_bottom > div:nth-child(3) .featuresItem {
    background: url(../images/KF3.jpg);
}





.abmissionWrap{
    padding: 50px 0;
}

.missionRow{
    align-items: center;
}
.mission_text h3 {
    font-size: 28px;
    margin-bottom: 15px;
}
.mission_text h3 span{
    color: #00e1ba;
    font-weight: 600;
}

.mission_text p{
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    line-height: 28px;
    font-size: 17px;
    margin-bottom: 28px;
}

.abblogWrap {
    padding: 50px 0;
    background-color: #f7f7f7;
}

.abblogWrap h3 {
    font-size: 28px;
    text-align: center;
    padding-bottom: 24px;
    text-transform: uppercase;
    margin: 0;
}
.article{
    font-size: 60px !important;
    font-weight: 500 !important;
    text-align: center;
    color: #000;
    padding-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 5px;
}

.art_box{
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    transition-duration: 0.3s;
    /* margin: 0 10px !important; */
}

.art_box img{
    width: 100%;
    height:100%;
    object-fit: cover;
}
.blog_slider .slider__carousel ul li{
    padding: 0 10px;
    transition-duration: 0.3s;
}

.blog_slider .slider__carousel ul li:hover{
    padding: 0px 10px;
    transition-duration: 0.3s;
    transform: scale(1.05, 1);
}
.art_box:hover{
    /* margin: 0 2px !important; */
    transition-duration: 0.3s;
}
.art_box:after{
    position: absolute;
    content: "";
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.blog_text{
    position: absolute;
    left: 20px;
    bottom: 20px;
    z-index: 7;
}
.blog_text h4{
    font-size: 26px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
}

.blog_text p {
    font-size: 18px;
    color:#fff;
}

.blog_slider .react-multiple-carousel__arrow{
    width: 64px;
    height: 64px;
    background: #fff;
    transition-duration: 0.3s;
}

.blog_slider .react-multiple-carousel__arrow--left{
    left: 0;
}
button:focus{
    outline: 0px;
}
.blog_slider .react-multiple-carousel__arrow--left:before{
    color: #767676;
    font-size: 15px;
}
.blog_slider .react-multiple-carousel__arrow--right:before{
    font-size: 15px;
    color: #767676;
}
.blog_slider .react-multiple-carousel__arrow--right{
    right: 0px;
    
}
.blog_slider .react-multiple-carousel__arrow:hover{
    background: #82bd51;
    color: #fff;
    transition-duration: 0.3s;
}

.blog_slider .react-multiple-carousel__arrow:hover:before{
    color: #fff;
}

@media screen and (min-width:2500px){
    .video_left_text p{
        font-size: 1.5em;
        line-height: 60px;
    }
}

@media screen and (max-width:1200px){
    .video_left_text {
        padding: 40px 90px 0 40px;
    }
    .blog_text h4{
        font-size: 22px;
    }
}

@media screen and (max-width:1199px){
    .lifeguru_banner_bottom {
        padding-top: 35px;
    }
    .lifeguru_banner_bottom h2{
        padding-bottom: 20px;
    }
    .video_right{
        padding-right: 20px;
    }
    .video_right .react-player{
        margin-left: 0px;
    }
    .video_left_text {
        padding: 40px 40px 0 40px;
    }
    .video_left_text p{
        font-size: 15px;
    }
    .lifeguru_works {
        padding: 35px 0;
    }
    .how_work_inner p{
        font-size: 15px;
    }
    .abfeaturesWrap{
        padding: 0px 0 35px 0;
    }
    .abfeaturesWrap h2{
        padding-bottom: 20px;
    }
    .how_bottom__content p{
        font-size: 15px;
    }
    .featuresItem p{
        font-size: 15px;
    }
    .mission_text h3{
        font-size: 49px;
    }
    .mission_text p{
        font-size: 15px;
        line-height: 25px;
    }
    .blogWrap{
        padding: 35px 0;
    }
    .blogWrap h3{
        padding-bottom: 20px;
    }
    .blog_slider .react-multiple-carousel__arrow{
        width: 50px;
        height: 50px;
    }
}

@media screen and (max-width:991px){
    .about_inner_text h2{
        font-size: 40px;
    }
    .lifeguru_banner_bottom h2{
        font-size: 35px;
    }
    .video_left_text p{
        line-height: 22px;
        font-size: 14px;
    }

    .work_left h2, .work_left h2 span{
        font-size: 35px;
    }
    .how_work_inner p {
        font-size: 14px;
    }
    .abfeaturesWrap h2{
        font-size: 35px;
    }
    .featuresItem{
        padding: 20px;
    }
    .featuresItem p{
        font-size: 14px;
        line-height: 22px;
    }
    .mission_text h3 {
        font-size: 35px;
        letter-spacing: 0px;
    }
    .mission_text p {
        font-size: 14px;
        line-height: 22px;
    }
    .blogWrap h3{
        letter-spacing: 0px;
        font-size: 35px;
    }
    .abblogWrap h3{
        font-size: 35px;
    }
    .abblogWrap{
        padding: 30px 0;
    }
}

@media screen and (max-width:767px){
    .about_banner img{
        height: 250px;
        object-fit: cover;
    }
    .lifeguru_video{
        flex-wrap: wrap;
    }
    .video_left, .video_right{
        width: 100%;
        padding: 20px;
        height: auto;
        min-height: 300px;
    }
    .video_left_text{
        padding: 20px;
    }
    .featuresItem{
        width: 50%;
    }
    .featuresItem:nth-child(2), .featuresItem:nth-child(4), .featuresItem:nth-child(6){
        width: 50%;
    }
    .blog_text h4 {
        font-size: 16px;
    }
    .how_work_inner{
        min-height: 95px;
    }
    .featuresImg{
        margin-bottom: 25px;
    }
    .featuresItem{
        min-height: 200px;
    }
    .featuresItem:nth-child(2), .featuresItem:nth-child(4), .featuresItem:nth-child(6){
        min-height: 200px;
    }
    .featuresItem:nth-child(2):hover, .featuresItem:nth-child(4):hover, .featuresItem:nth-child(6):hover,
    .featuresItem:nth-child(1):hover, .featuresItem:nth-child(3):hover, .featuresItem:nth-child(5):hover{
        transform: scale(1.03) !important;
    }
}
@media screen and (max-width:575px){
.video_right .react-player{
    height: 202px !important;
}
}
@media screen and (max-width:420px){
    .how_work_inner{
        width: 100%;
    }
    .about_inner_text h2 {
        font-size: 35px;
    }
    .lifeguru_banner_bottom h2, .work_left h2, .work_left h2 span, .abfeaturesWrap h2, .mission_text h3, .blogWrap h3, .mission_text h3, .abblogWrap h3{
        font-size: 26px;
        letter-spacing: 0px;
    }
    .featuresItem{
        width: 100%;
    }
    .featuresItem:nth-child(2), .featuresItem:nth-child(4), .featuresItem:nth-child(6){
        width: 100%;
    }
    .blog_text h4 {
        font-size: 20px;
    }

    /* .video_right .react-player{
        height: auto !important;
    } */
    .video_left_text {
        padding: 0px;
    }
}