.article_title a {
    font-size: 1.4rem;
    font-weight: 600;
    color: #1f2827;
}
.article_date {
    font-size: .8rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
}
.article_title a:hover {
    color: #00e1ba;
}