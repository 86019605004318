.webinar_partts{
    margin: 30px 0;
}
.webinar_listinggg {
  background: #fff;
  border-radius: 5px;
  display: block;
  margin: 22px 0;
  width: 100%;
  padding: 20px 15px;
  box-shadow: 0px 10px 20px rgb(20 34 32 / 6%);
}
.webinar_listing_inner_parttts{
    display: flex;
    align-items: center;
    margin: 0 -15px;
    flex-wrap: wrap;
}
.webinar_details {
  border-top: 1px solid #e3e3e3;
  padding-top: 15px;
  margin-top: 15px;
}
.webinar_inner{
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding: 0 12px;
}

.webinar_inner label{
    color: #595959;
    display: block;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0;
}
.webinar_inner span {
  color: #374240;
  display: block;
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
}
.join_button{
    height: 40px;
    line-height: 40px;
    padding: 0 30px;
    background: #00d41e;
    color: #fff;
    display: inline-block;
    border-radius: 5px;
    border: 0px;
    transition-duration: 0.3s;
}
.join_button:hover{
    background:#076514;
    transition-duration: 0.3s;
}

.result_pagination ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  