

.sw_cat {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 0.8rem;
}
.sw_title {
    font-weight: 600;
    font-size: 2.2rem;
    line-height: 1.2;
    text-transform: uppercase;
    color: #000000;
}
.sw_details p,
.sw_details ul {
    color: #3B3D39;
}

.inc_list li + li {
    margin-top: 0.5rem;
}
.inc_list_img img {
    vertical-align: baseline;
}
.pc_card {
    background-color: #fff;
    padding: 2rem;
    border-radius: 0.6rem;
    box-shadow: 4px 4px 15px rgb(6 48 37 / 12%);
}
.sw_details {
    padding: 2rem 1rem;
}
.sw_banner {
    background-color: #fff;
  }
.badge.badge_green {
    background: #f0fffc;
    border: 1px solid #7fe1c8;
    border-radius: 4px;
    color: #17B992;
    font-weight: 600;
    font-size: .8rem;
    margin-right: 0.5rem;
}
.practitioner {
    padding: 3rem 0;
    background: linear-gradient(to top, #f3f3f3 6rem, #CCF9F1 6rem);
}
.similar_wbnr {
    padding: 1.5rem;
}
.pc_name {
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 1.2rem;
}
.sw_image img {
    object-fit: cover;
    object-position: center;
    max-height: 400px;
}
.similar_wbnr .slick-prev,
.similar_wbnr .slick-next {    
    z-index: 10;
}
.similar_wbnr .slick-prev {
    left: 0;
}
.similar_wbnr .slick-next {
    right: 0;
}
.similar_wbnr .webiner_card {
    filter: none;
}
.similar_wbnr .slick-track {
    margin: 0;
}
.similar_wbnr .carousel-item  {
    padding-right: 30px;
}
.similar_wbnr .slick-prev:before, 
.similar_wbnr .slick-next:before {
    color: black;
    font-size: 1.8rem;
}

@media screen and (max-width: 991px) {
    .sw_details {
        padding: 2rem;
    }
    .sw_image img {
        height: 18rem !important;
    }
    .practitioner > .row {
        margin: 0;
    }
}

@media screen and (max-width: 767px) {
    .col-md-4.pc_image {
        margin-bottom: 1.5rem;
    }
}
@media screen and (max-width: 480px) {
    .sw_image img {
        height: 12rem !important;
    }
    .sw_title {
        font-size: 1.5rem;
    }
    .inc_list li {
        font-size: .8rem;
    }
}