.pagi_web.pagination .page-link {
    width: 2.4rem;
    height: 2.4rem;
    border: 1px solid #d0eae5 !important;
    margin: 0 0 0 0.5rem;
    padding: 0;
    text-align: center;
    line-height: 2.5rem;
    border-radius: 0.6rem !important;
    box-shadow: 3px 4px 6px rgb(40 49 47 / 13%);
    font-weight: 600;
}
.pagi_web.pagination .active .page-link {
    background-color: #00e1ba;
}
.result_pagination ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
.webinar_inner.col-3 {
    flex: 0 0 20%;
    max-width: 20%;
}
.session_item {
    background-color: rgb(255 255 255 / 60%);
    border-radius: 0.8rem;
    padding: 1rem;
    margin-bottom: 1rem;
    transition: all .3s ease;
    box-shadow: 0px 11px 20px 0px rgb(24 30 29 / 8%);
    position: relative;
}
.session_item:hover {
    box-shadow: none;
}
/* .session_item:after {
    content: "";
    position: absolute;
    right: 1.5rem;
    top: 50%;
    width: 0.6rem;
    height: 0.6rem;
    border-style: solid;
    border-color: #97ada9;
    border-width: 2px 2px 0 0;
    border-top-right-radius: 2px;
    transform: rotate(45deg) translateY(-50%);
    transform-origin: center;
} */
.page-title {
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 1.2;
    color: #181B15;
    margin-bottom: 2rem;
    position: relative;
}
.page-title:after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 80px;
    height: 2px;
    background-color: #485354;
}
.upcoming .session_item {
    background-color: #fff;
    border: 1px solid #00e1ba;
}
.session_user_image {
    flex: 0 0 3.438rem;
    max-width: 3.438rem;
}
.session_user_image img {
    width: 55px;
    height: 55px;
}
.coaches_pagination .page-item .page-link {
    border: none;
    background: transparent;
    padding: 10px 15px;
    color: #776969;
    border-bottom: 1.5px solid #cbcbcb;
    border-radius: 0;
    position: relative;
    line-height: 1;
}
.coaches_pagination .page-item.active .page-link {
    border-color: #00e1ba;
    color: #00e1ba;
}
.coaches_card h4 {
    font-size: 1.4rem;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
}
.coaching_cat {
    font-size: .9rem;
    font-weight: 600;
    margin-bottom: 1rem;
}
.row.coaches_row {
    margin: 0 -0.5rem;
}
.coaches_row .col-md-3 {
    padding: 0 0.5rem;
}
.coaches_card .rounded-circle {
    width: 4rem;
    height: 4rem;
    margin-bottom: 1rem;
    display: inline-block;
    object-fit: cover;
}
.coaches_slider .slick-slide {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.session_user {
    flex: 0 0 22%;
    max-width: 22%;
}
.session_name {
    flex: 1 1;
}
.session_type_title {
    font-size: 1.2rem;
    font-weight: 600;
}
.session_date {
    flex: 0 0 14%;
    max-width: 14%;
}
.session_time {
    flex: 0 0 14%;
    max-width: 14%;
}
.session_type {
    flex: 0 0 15%;
    max-width: 15%;
}
.session_text {
    font-size: 1rem;
    font-weight: 600;
}
.session_label {
    font-size: .9rem;
}
.session_wrap a {
    color: unset;
}
.session_user,
.session_name,
.session_date,
.session_time,
.session_type {
    padding-left: .5rem;
    padding-right: .5rem;
}




@media screen and (max-width: 991px) {
    .session_text {
        font-size: .9rem;
    }
}

@media screen and (max-width: 850px) and (min-width: 768px) {
    .session_user,
    .session_name,
    .session_date,
    .session_time,
    .session_type {
        flex: 0 0 50%;
        max-width: 50%;
        padding-top: .5rem;
        padding-bottom: .5rem;
    }
}

@media screen and (max-width: 640px) {
    .session_user,
    .session_name,
    .session_date,
    .session_time,
    .session_type {
        flex: 0 0 50%;
        max-width: 50%;
        padding-top: .5rem;
        padding-bottom: .5rem;
    }
}