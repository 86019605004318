#root {
  height: 100vh;
}

* {
  box-sizing: border-box;
}

.joinOuterContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100vh;
  align-items: center;
  background-color: #1A1A1D;
}

.joinInnerContainer {
  width: 20%;
}

.joinInput {
  border-radius: 0;
  padding: 15px 20px;
  width: 100%;
}
.button {
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  background: #2979FF;
  padding: 20px;
  border-radius: 5px;
  display: inline-block;
  border: none;
  width: 100%;
}

.mt-20 {
  margin-top: 20px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .joinOuterContainer {
    height: 100%;
  }

  .joinInnerContainer {
    width: 90%;
  }


}

button:focus {
  outline: 0;
}



div#main-list-div {
  width: 360px;
  background-color: #fff;
  z-index: 999;
  position: fixed;
  left: auto;
  right: 5px;
  height: 65%;
  top: auto;
  display: block !important;
  /* border: 1px solid rgb(66, 66, 66); */
  box-shadow: 0 0 5px rgb(42 56 57 / 29%);
  transition: all .4s ease-in;
  -webkit-transition: all .4s ease-in;
  -moz-transition: all .4s ease-in;
  -ms-transition: all .4s ease-in;
  -o-transition: all .4s ease-in;
  color: rgb(44, 44, 44);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  cursor: pointer;
}

div#main-list-div .top_bar {
  background: #2a3839;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 10px;
  color: #fff;
  font-size: 18px;
  font-weight: 800;
}

div#main-list-div .top_bar .search_form {
  display: none;
}


div#main-list-div.openclassname {
  bottom: 0;
}

div#main-list-div.closeclassname {
  bottom: calc(-65% - -60px)
}


.chatusers:hover {
  background-color: whitesmoke;
}

.image_block {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  overflow: hidden;
}

.image_block_items {
  width: 50px;
  height: 50px;
}
.image_block_items img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #c9c0c0;
  object-fit: cover;
}



.image_block img,
.image_block_items img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.item_list {
  height: calc(100% - 64px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 5px;
}

.item_list .item {
  padding: 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  /* border: 1px solid #000; */
  margin-bottom: 3px;

}

.item_list .item.active,
.item_list .item:hover {
  box-shadow: 0 0 12px #c6c6c6;
}

.drop_icon {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  fill: rgba(196, 196, 196, 0.6);
}



/* .item_list .item.active,
.item_list .item:hover {
  background: #eee;
} */

.list_item_option {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  fill: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, .25);
  text-align: center;
  visibility: hidden;
}


.item_list .item.active .list_item_option,
.item_list .item:hover .list_item_option {
  visibility: visible;
}

.item_list .item.active .list_item_option svg,
.item_list .item:hover .list_item_option svg {
  fill: #000
}



.items_online {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  background: #20b802
}

.items_offline {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  background: #ff0101
}



.chatbox {
  /* border: 1px solid rgb(105, 105, 105); */
  width: 350px;
  flex: 0 0 350px;
  margin: 0 5px;
  box-shadow: 0 0 5px rgba(65, 65, 65, 0.16);
  background: #242526;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.chatbox .top_bar {
  border-bottom: 1px solid rgb(102, 102, 102);
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  padding: 5px;
}

.chatbox .top_bar svg {
  width: 30px;
  height: 30px;
  fill: #2979FF;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  padding: 5px;
}

.chatbox .top_bar svg:hover {
  background: #363738;
}




.chat_parrent_img .image_block_items img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  overflow: hidden;
}

#inputdiv {
  border-top: 1px solid black;
}

#massagediv {
  padding: 5px;
  min-height: 300px;
  max-height: 300px;
  overflow-y: scroll;
  background: #fff;
}

/* .onestperson {display: flex; justify-content: end;  align-items: flex-end; margin-bottom: 5px} */
.onestperson {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 5px
}

.onestperson span {
  background: #2979FF;
  border-radius: 35px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  -ms-border-radius: 35px;
  -o-border-radius: 35px;
  border-bottom-right-radius: 15px;
  float: right;
  padding: 10px 15px;
  color: #fff;
  line-height: 20px;
  font-size: 14px;
  clear: both;
  margin-bottom: 2px;
  word-break: break-all;
  max-width: 250px
}

.onestperson span.time,
.thirdperson span.time {
  background: none;
  border-radius: none;
  line-height: normal;
  font-size: 10px;
  color: #000
}

.thirdperson {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 5px
}

.thirdperson span {
  background: rgb(219, 219, 219);
  border-radius: 35px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  -ms-border-radius: 35px;
  -o-border-radius: 35px;
  border-bottom-left-radius: 15px;
  float: left;
  padding: 10px 15px;
  color: #000;
  line-height: 20px;
  font-size: 14px;
  clear: both;
  margin-bottom: 2px;
  word-break: break-all;
  max-width: 250px;
}

.cImg {
  flex: 0 0 30px;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 30px;
}

.message_input {
  position: relative;
  flex: 1 1 77%
}

.message_input input {
  width: 100%;
  height: 44px;
  border: none;
  padding: .375rem .75rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, .16) inset;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background: #ededed;
  color: #000;
  padding-right: 74px;
}

.msend_button button {
  width: 100%;
  height: 35px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  line-height: 20px;
}

.msend_button .sendm { width: 40px; height: 40px; margin: 1px 1px 1px 0; background: #fff; border-radius:5px; -webkit-border-radius:5px; -moz-border-radius:5px; -ms-border-radius:5px; -o-border-radius:5px; }
.msend_button .sendm:hover {cursor: pointer;}
.msend_button .sendm img {max-width: 30px; height:34px !important; margin: 3px 5px;}

.chart_box_container {
  z-index: 999;
  position: fixed;
  bottom: 5px;
  display: flex;
  justify-content: flex-end;
  max-width: calc(100% - 365px);
  right: 365px;
}

.search_form input,
.search_form input:focus {
  background: #3A3B3C;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  border: 1px solid rgb(87, 87, 87);
  color: #fff;
}


.item_list .item span,
.item_list .item p {
  line-height: 18px;
}

.sp_info {
  color: #2979FF;
}

.sp_info span {
  color: #fff
}


.added_date input {
  flex: 0 0 88%;
  padding: 0px 13px 0 15px;
}

.added_date button {
  background: none;
  border: 2px solid #ff0101;
  border-radius: 100px;
  width: 30px;
  height: 30px
}

.add-new-date {
  background: transparent;
  border: 2px solid #1a9b00;
  border-radius: 50px;
  color: #1a9b00;

}

.add-new-date svg {
  fill: #1a9b00
}

.add-new-date:hover,
.add-new-date:focus,
.add-new-date:active {
  background: #1a9b00;
  border: 2px solid #1a9b00;
  border-radius: 50px;
  color: #fff;
  box-shadow: none;

}

.add-new-date:hover svg {
  fill: #fff
}


.sender_she {
  box-shadow: 0 0 13px #adb6f0;
  padding: 2px;
  background: #fff;
  border-radius: 3px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  max-width: 80%;
}

.sender_she .she_title {
  background: #0485ff;
  text-align: center;
  color: #fff;
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.sch_body {
  width: 100%
}

.sch_body div {
  border-radius: 5px;
  border: 1px solid #0485ff;
  color: #0485ff;
  background: 5px;
  width: 100%;
  margin: 3px 0;
  text-align: center;
  font-size: 14px;
}

.sch_body div:hover {
  background: #acacac;
  color: #000;
  cursor: pointer;
}

.sch_body div.active {
  background: #acacac;
  color: #000;
  cursor: pointer;
}



.sender_she.receiver {
  box-shadow: 0 0 13px #00be66;
  padding: 2px;
  background: #fff;
  border-radius: 3px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  max-width: 80%;
}

.sender_she.receiver .she_title {
  background: #00be66;
  text-align: center;
  color: #fff;
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.sch_body {
  width: 100%
}

.sender_she.receiver .sch_body div {
  border-radius: 5px;
  border: 1px solid #00be66;
  color: #00be66;
  background: 5px;
  width: 100%;
  margin: 3px 0;
  text-align: center;
  font-size: 14px;
}

.sender_she.receiver .sch_body div:hover {
  background: #acacac;
  color: #000;
  cursor: pointer;
}

.sender_she.receiver .sch_body div.active {
  background: #acacac;
  color: #000;
  cursor: pointer;
}

.sender_she.receiver .sch_body div.div {
  border-radius: 5px;
  border: 1px solid #00be66;
  color: #00be66;
  background: 5px;
  width: 100%;
  margin: 3px 0;
  text-align: center;
  font-size: 14px;
}



.schecalender {
  position: absolute;
  right: 11px;
  top: 8px;
}

.schecalender .mtype {
  background: #fff;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.schecalender .mtype img {padding: 0 5px;}
.schecalender .mtype img:hover {cursor: pointer;}

.chat_parrent_img {
  flex: 0 0 40px;
  max-width: 40px;
}

.chat_parrent_img img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.cImg img {
  width: 30px;
  height: 30px;
  object-fit: cover;
}



.send_image_block {
  display: flex;
  flex-direction: column;
}

.send_image_left {
  align-items: flex-start;
  color: #000;
  margin-bottom: 5px;
}

.send_image_right {
  align-items: flex-end;
  text-align: right;
  color: #000;
  margin-bottom: 5px;
}

.send_image_block .time {
  font-size: 10px;
}